import SalesRepresentativesList from './SalesRepresentativesList';
import SalesRepresentativesCreate from './SalesRepresentativesCreate';
import SalesRepresentativesEdit from './SalesRepresentativesEdit';
import SalesRepresentativesShow from './SalesRepresentativesShow';

const resourceMapping = {
  list: SalesRepresentativesList,
  create: SalesRepresentativesCreate,
  edit: SalesRepresentativesEdit,
  show: SalesRepresentativesShow,
};

export default resourceMapping;

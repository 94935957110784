import * as React from 'react';
import {
  Create,
  CreateProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { useCountryConfig, useCountryId } from '../../contexts/CountryContext';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import { FilteredStoresInputMultiple } from '../common/Form/Collection/FilteredStoresInputMultiple';
import TitleSelector from '../common/Form/Collection/TitleSelector';
import EmailInput from '../common/Form/Input/EmailInput';
import { maxArrayInput } from '../common/Validator/maxArrayInput';

export const StoreManagersCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { countryId } = useCountryId();
  const { MAX_STORES_PER_MANAGER } = useCountryConfig();

  const transform = (data: any) => ({
    ...data,
    countryId,
    created_at: Date.now(),
    status: 'Pending',
  });

  return (
    <Create {...props} transform={transform}>
      <AsurionForm
        initialValues={{
          salesPartnerId: identity?.extra.salesPartnerId,
        }}
        title={translate('resources.store-managers.new_title')}
        redirect="list"
        mutators={{
          resetStore: (_args: any, state: any, utils: any) => {
            utils.changeValue(state, 'partnerStores', () => []);
          },
          setPartner: (value: any, state: any, utils: any) => {
            utils.changeValue(state, 'salesPartnerId', () => value);
          },
        }}
      >
        <TitleSelector source="title" />

        <TextInput
          placeholder="e.g. John"
          source="firstName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          placeholder="e.g. Smith"
          source="lastName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput placeholder="e.g. John" source="nickname" fullWidth />
        <EmailInput source="userEmail" className="required" />

        <ContactNumber source="contactNumber" className="required" />

        {!identity?.extra.salesPartnerId && (
          <ReferenceInput
            label="Assign to Partner"
            source="salesPartnerId"
            reference="sales-partners"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
            filter={{
              countryId,
              activeIndStatus: 'active',
            }}
          >
            <SelectInput optionText="partnerName" />
          </ReferenceInput>
        )}

        <FilteredStoresInputMultiple
          source="partnerStores"
          validate={[required(), maxArrayInput(MAX_STORES_PER_MANAGER)]}
          className="required"
          fullWidth
          onEmptyStore={(form: any) => {
            form.mutators.resetStore();
          }}
        />
        <ContactNumber
          label="resources.store-managers.fields.altMdn"
          placeholder="08-1234-5678"
          source="salesRepInfo.altMdn"
          validate={undefined}
        />
        <TextInput
          placeholder="08-1234-5678"
          source="salesRepInfo.lineId"
          fullWidth
          variant="outlined"
        />
      </AsurionForm>
    </Create>
  );
};

export default StoreManagersCreate;

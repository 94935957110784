import { useTranslate } from 'ra-core';
import * as React from 'react';
import { PageContainer } from '../common/Scaffolding/PageContainer';

export const Bulletin = () => {
  const translate = useTranslate();
  return (
    <PageContainer
      title={translate('asurion.common.navigation.menus.bulletin')}
    >
      TBA
    </PageContainer>
  );
};

export default Bulletin;

import React from 'react';
import { Edit, EditProps, required, TextInput } from 'react-admin';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import { EmailInput } from '../common/Form/Input/EmailInput';

export const SalesManagerEdit: React.FC<EditProps> = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <AsurionForm title="Edit Asurion Sales Manager">
      <TextInput
        label="First Name"
        placeholder="e.g. John"
        source="firstName"
        fullWidth
        validate={required()}
        className="required"
      />
      <TextInput
        label="Last Name"
        placeholder="e.g. Smith"
        source="lastName"
        fullWidth
        validate={required()}
        className="required"
      />
      <TextInput
        label="Nickname"
        placeholder="e.g. John"
        source="nickname"
        fullWidth
      />
      <EmailInput source="userEmail" className="required" />
      <ContactNumber
        label="asurion.common.form.contact_number.label"
        source="contactNumber"
        className="required"
      />
    </AsurionForm>
  </Edit>
);

export default SalesManagerEdit;

import CountryManagerList from './CountryManagerList';
import CountryManagerCreate from './CountryManagerCreate';
import CountryManagerEdit from './CountryManagerEdit';

const resourceMapping = {
  list: CountryManagerList,
  create: CountryManagerCreate,
  edit: CountryManagerEdit,
};

export default resourceMapping;

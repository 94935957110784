import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ResourceComponentProps } from 'react-admin';
import { Redirect as RouterRedirect } from 'react-router';

type RedirectProps = PropsWithChildren<ResourceComponentProps>;

export const Redirect = ({ basePath = '/' }: RedirectProps) => {
  localStorage.setItem('redirect', basePath);
  return <RouterRedirect to={{ pathname: basePath }} />;
};

export default Redirect;

import React from 'react';
import {
  Create,
  CreateProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { useSuccessHandler } from '../../common/hooks';
import { useCountryId } from '../../contexts/CountryContext';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import { FilteredStoresInput } from '../common/Form/Collection/FilteredStoresInput';
import TitleSelector from '../common/Form/Collection/TitleSelector';
import EmailInput from '../common/Form/Input/EmailInput';
import AsurionStepper from '../common/Scaffolding/AsurionStepper';

export const SalesRepresentativesCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { countryId } = useCountryId();
  const { onSuccessHandler } = useSuccessHandler(
    translate('resources.sales-representatives.name', 0),
    props.basePath
  );

  const transform = (data: any) => ({
    ...data,
    countryId,
    created_at: Date.now(),
    status: 'Pending',
  });

  const steps = [
    {
      label: translate('asurion.common.form.sections.sales_rep_details'),
      content: [
        <>
          {!identity?.extra.salesPartnerId && (
            <ReferenceInput
              label="Assign to Partner"
              source="salesPartnerId"
              reference="sales-partners"
              fullWidth
              validate={required()}
              className="required"
              variant="outlined"
              filter={{
                countryId,
                activeInd: 'active',
              }}
            >
              <SelectInput optionText="partnerName" />
            </ReferenceInput>
          )}

          <FilteredStoresInput
            label="resources.sales-representatives.fields.salesPartnerStoreId_alt"
            source="salesPartnerStoreId"
            className="required"
            fullWidth
            onEmptyStore={(form: any) => {
              form.mutators.resetStore();
            }}
          />

          <SelectInput
            label="resources.sales-representatives.fields.role"
            ut
            source="role"
            choices={[
              {
                id: 'Sales Representative',
                name: 'Sales Representative',
              },
            ]}
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />

          <TitleSelector source="title" />

          <TextInput
            label="resources.sales-representatives.fields.firstName"
            placeholder="e.g. John"
            source="firstName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="resources.sales-representatives.fields.lastName"
            placeholder="e.g. Smith"
            source="lastName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="resources.sales-representatives.fields.nickname"
            placeholder="e.g. John"
            source="nickname"
            fullWidth
            variant="outlined"
          />
        </>,
      ],
    },
    {
      label: translate('asurion.common.form.sections.contact_details'),
      content: [
        <>
          <EmailInput source="userEmail" className="required" />
          <ContactNumber
            source="contactNumber"
            label="asurion.common.form.contact_number.label"
            className="required"
          />
          <ContactNumber
            label="asurion.common.form.altMdn.label"
            placeholder="08-1234-5678"
            source="salesRepInfo.altMdn"
            validate={undefined}
          />
          <TextInput
            label="asurion.common.form.lineId.label"
            placeholder="08-1234-5678"
            source="salesRepInfo.lineId"
            fullWidth
            variant="outlined"
          />
        </>,
      ],
    },
  ];

  return (
    <Create {...props} transform={transform} onSuccess={onSuccessHandler}>
      <AsurionForm
        initialValues={{
          salesPartnerId: identity?.extra.salesPartnerId,
        }}
        title={translate('resources.sales-representatives.new_title')}
        redirect="list"
        saveButtonLabel={translate('asurion.common.navigation.done_alt')}
        mutators={{
          resetStore: (_args: any, state: any, utils: any) => {
            utils.changeValue(state, 'salesPartnerStoreId', () => '');
          },
        }}
      >
        <AsurionStepper steps={steps} />
      </AsurionForm>
    </Create>
  );
};

export default SalesRepresentativesCreate;

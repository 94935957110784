import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const isFinanceUser = (identity: any) =>
  ['FINANCE_MGR'].includes(identity?.role);

export const isPartnerAdmin = (identity: any) =>
  ['PARTNER_ADMIN'].includes(identity?.role);

export const isBusinessPartner = (identity: any) =>
  ['BUSINESS_PARTNER_ADMIN'].includes(identity?.role);

export const uuidToCanonical = (uuid: string = '') =>
  uuid
    .toLowerCase()
    .replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, '$1-$2-$3-$4-$5');

export const uuidUncanonical = (uuid: string = '') =>
  uuid.toUpperCase().replace(/-/g, '');

export const enumToObject = (targetEnum: any) =>
  Object.entries(targetEnum).map(([key, value]) => ({ id: key, name: value }));

export const trimDate = (datetime: string) => datetime.split('T').shift();

export const isString = (value: any) =>
  typeof value === 'string' || value instanceof String;

export const formatDateToLocalTime = (
  date: any,
  timezone: string = getBrowserDefaultTimezone()
) => {
  // console.log('Timezone received: ', timezone);
  // console.log('Date received: ', date);
  let formattedDate = moment(date).tz(timezone).format('YYYY-MM-DD'); //timezone ? moment(date).tz(timezone).format('YYYY-MM-DD') : moment(date).local().format('YYYY-MM-DD');
  // console.log('Formatted date to local: ', formattedDate);
  return formattedDate;
};

export const convertToStartOfDay = (
  date: any,
  timezone: string = getBrowserDefaultTimezone(),
  force: boolean = true
) => moment(date).tz(timezone, force).startOf('day');

export const convertToEndOfDay = (
  date: any,
  timezone: string = getBrowserDefaultTimezone(),
  force: boolean = true
) => moment(date).tz(timezone, force).endOf('day').seconds(58);

export const getTimezoneFromCountryCode = (countryCode: string) =>
  momentTimezone.tz.zonesForCountry(countryCode)[0];

export const getBrowserDefaultTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();

export const withTestCountry = (input: Array<string | null>) => {
  // @TODO: Use localstorage to override testCountries
  const testCountries = ['phl'];
  if (['dev'].includes(process.env.REACT_APP_ENV as string) && testCountries) {
    return [...input, ...testCountries];
  }
  return input;
};

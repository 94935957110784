import * as React from 'react';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  makeStyles,
} from '@material-ui/core';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  CreateProps,
  DateInput,
  email,
  minValue,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useRefresh,
} from 'react-admin';
import AsurionForm from '../common/Form/AsurionForm';
import { AsurionStepper } from '../common/Scaffolding/AsurionStepper';
import { AsurionAddress } from '../common/Form/Collection/AsurionAddress';
import { CountrySelector } from '../common/Form/Collection/CountrySelector';
import ContactNumber from '../common/Field/ContactNumber';
import SwiftCode from '../common/Field/SwiftCode';
import { useBusinessPartnerId, useSuccessHandler } from '../../common/hooks';
import { useCountryConfig, useCountryId } from '../../contexts/CountryContext';
import { useState } from 'react';
import { Field } from 'react-final-form';
import { requiredIfChecked } from '../common/Validator/requiredIfChecked';
import { maxNumericValue } from '../common/Validator/maxNumericValue';
import { toDecimal } from '../common/Form/parsers';

const useStyles = makeStyles({
  requiredContainer: {},
});

export const SalesPartnersCreate = (props: CreateProps) => {
  const { onSuccessHandler } = useSuccessHandler(
    'Partner Account',
    props.basePath
  );
  const { countryId } = useCountryId();
  const { hasBusinessPartner } = useCountryConfig();
  const { businessPartnerId = false } = useBusinessPartnerId();
  const transform = (data: any) => ({
    ...data,
    incentivePercentage: noIncentives ? 0 : data.incentivePercentage,
    partnerIncentivePercentage: noIncentives
      ? 0
      : data.partnerIncentivePercentage,
    created_at: Date.now(),
    status: 'Pending',
  });
  const [noIncentives, setNoIncentives] = useState<boolean>(false);

  const classes = useStyles();

  const refresh = useRefresh();
  React.useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const steps = [
    {
      label: 'Partner Account Details',
      content: [
        <>
          <TextInput
            label="Company Name"
            placeholder="e.g. Public Company Limited"
            source="partnerName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="Partner Code"
            placeholder="Partner Code"
            source="partnerCode"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="Tax Registration Number"
            placeholder="1234567890987"
            source="tin"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DateInput
                label="Date of Partnership"
                placeholder="Select Date"
                source="startDate"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <NumberInput
                label="Revenue Share Percentage"
                placeholder="e.g  10%"
                source="revenueSharePct"
                fullWidth
                format={(v: number) => (v * 100).toFixed(0)}
                parse={toDecimal}
                validate={required()}
                className="required"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                label="Revenue Target"
                placeholder="e.g  1000"
                source="revenueTarget"
                fullWidth
                validate={required()}
                className="required"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Field name="noIncentives" type="checkbox" initialValue={false}>
                {(props) => (
                  <Checkbox
                    color="primary"
                    checked={noIncentives}
                    onChange={(e) => {
                      setNoIncentives(!noIncentives);
                      props.input.onChange(e);
                    }}
                  />
                )}
              </Field>
            }
            label="No incentives"
          />
          <Collapse in={!noIncentives}>
            {!noIncentives && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NumberInput
                    label="Sales Rep Incentive Percentage"
                    placeholder="e.g  10%"
                    source="incentivePercentage"
                    fullWidth
                    format={(v: number) => (v * 100).toFixed(0)}
                    parse={toDecimal}
                    validate={[
                      requiredIfChecked('noIncentives'),
                      maxNumericValue(0.3, (value) => `${value * 100}%`),
                      minValue(0),
                      noIncentives ? () => undefined : required(),
                    ]}
                    className="required"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    label="Partner Incentive Percentage"
                    placeholder="e.g  10%"
                    source="partnerIncentivePercentage"
                    fullWidth
                    format={(v: number) => (v * 100).toFixed(0)}
                    parse={toDecimal}
                    validate={[
                      requiredIfChecked('noIncentives'),
                      minValue(0),
                      noIncentives ? () => undefined : required(),
                    ]}
                    className="required"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </Collapse>

          <CountrySelector source="countryTlId" label="Country" />
          {hasBusinessPartner && (
            <ReferenceInput
              label="Business Partner"
              source="businessPartnerId"
              reference="business-partners"
              fullWidth
              validate={required()}
              className="required"
              variant="outlined"
              disabled={businessPartnerId}
              defaultValue={businessPartnerId}
              filter={{
                activeIndStatus: 'active',
                countryId,
              }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
          <ReferenceArrayInput
            label="Asurion Sales Manager"
            source="salesManagers"
            reference="sales-managers"
            fullWidth
            validate={required()}
            variant="outlined"
            filter={{
              activeIndStatus: 'active',
              countryId,
            }}
          >
            <AutocompleteArrayInput
              classes={{
                container: classes.requiredContainer,
              }}
              optionText={({
                firstName,
                lastName,
              }: {
                firstName: string;
                lastName: string;
              }) => `${firstName} ${lastName}`}
            />
          </ReferenceArrayInput>
        </>,
      ],
    },
    {
      label: 'Address',
      content: [<AsurionAddress />],
    },
    {
      label: 'Bank Details',
      content: [
        <>
          <TextInput
            label="Company Name (As appears in bank account)"
            placeholder="ABC Company"
            source="bankDetails.accountName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <ReferenceInput
            label="Bank Name"
            source="bankDetails.bankNameId"
            reference="banks"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
            filter={{
              countryId,
            }}
          >
            <AutocompleteInput optionText="name" resettable={true} />
          </ReferenceInput>
          <TextInput
            label="Bank Branch"
            placeholder="Bank Branch"
            source="bankDetails.bankBranchId"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="Bank Account Number"
            placeholder="012-3-45678-9"
            source="bankDetails.accountNumber"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <SwiftCode label="Swift Code" />
          <ReferenceInput
            label="Account Type"
            source="bankDetails.accountTypeId"
            reference="account-types"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </>,
      ],
    },
    {
      label: 'Contact Details',
      content: [
        <>
          <TextInput
            label="Director Name"
            placeholder="e.g  John Smith"
            source="contactDetails.directorName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="Primary Contact Person Name"
            placeholder="e.g John Smith"
            source="contactDetails.contactPerson1"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="Primary Contact Person Email Address"
            placeholder="e.g johnsmith@gmail.com"
            source="contactDetails.email1"
            type="email"
            fullWidth
            validate={[required(), email()]}
            className="required"
            variant="outlined"
          />
          <ContactNumber
            label="Primary Contact Number"
            placeholder="e.g 0912345678"
            source="contactDetails.contactNumber1"
            className="required"
          />
          <TextInput
            label="Secondary Contact Person Name (Optional)"
            placeholder="e.g John Smith"
            source="contactDetails.contactPerson2"
            fullWidth
            variant="outlined"
          />
          <TextInput
            label="Secondary Contact Person Email Address (Optional)"
            placeholder="e.g johnsmith@gmail.com"
            source="contactDetails.email2"
            type="email"
            fullWidth
            validate={[email()]}
            variant="outlined"
          />
          <ContactNumber
            label="Primary Contact Number (Optional)"
            placeholder="e.g 0912345678"
            source="contactDetails.contactNumber2"
            validate={undefined}
          />
        </>,
      ],
    },
  ];

  return (
    <Create {...props} transform={transform} onSuccess={onSuccessHandler}>
      <AsurionForm title="New Partner Account" redirect="list">
        <AsurionStepper steps={steps} />
      </AsurionForm>
    </Create>
  );
};

export default SalesPartnersCreate;

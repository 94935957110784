import { createContext, useContext, useState } from 'react';
import { noop } from '../common/functions';
import { DoneDialog } from '../components/common/Dialog/DoneDialog';

export interface DoneModalContextProps {
  setOpen?: Function;
  setPostCloseAction?: Function;
  setEntityName?: Function;
}

const DoneModalContext = createContext<DoneModalContextProps>({});

const DoneModalProvider = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [entityName, setEntityName] = useState<string>('');
  const [postCloseAction, setPostCloseAction] = useState(() => noop);
  const onClose = () => {
    setOpen(false);
    postCloseAction();
  };

  return (
    <DoneModalContext.Provider
      value={{ setOpen, setPostCloseAction, setEntityName }}
      {...props}
    >
      {props.children}
      <DoneDialog entityName={entityName} open={open} onClose={onClose} />
    </DoneModalContext.Provider>
  );
};

const useDoneModal = () => {
  const context = useContext(DoneModalContext);
  if (context === undefined) {
    throw new Error('useDoneModal must be used within a DoneModalProvider');
  }
  return context;
};

export { DoneModalProvider, useDoneModal };

import * as React from 'react';
import { Form } from 'react-final-form';
import { Button, makeStyles } from '@material-ui/core';
import { useListContext, useTranslate } from 'react-admin';
import { identity } from '../../../common/functions';

export interface AsurionSearchFilterProps {
  externalHandler?: boolean;
  children: React.ReactNode;
  initialValues?: object;
  onSubmit?: (values: any) => void;
  onReset?: (values: any, form: any) => void;
  valueWalkFn?: (values: object) => object;
  customFormClass?: any;
}

export const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  formRoot: {
    display: 'flex',
    // flexWrap: 'wrap',
    gap: 12,
    alignItems: 'center',

    '& .MuiFormLabel-root': {
      fontSize: 10,
      lineHeight: '12px',
      textTransform: 'uppercase',
      color: '#8E8E93',
      marginBottom: 12,
      minWidth: 150,
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 18,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D9D9D9',
    },

    '& .MuiOutlinedInput-notchedOutline:hover': {
      borderColor: 'red',
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
      height: 35,
    },
  },
});

export const AsurionSearchFilter = ({
  children,
  onSubmit,
  onReset,
  valueWalkFn = identity,
  ...props
}: AsurionSearchFilterProps) => {
  const translate = useTranslate();
  const { setFilters, displayedFilters, filterValues } = useListContext();
  const { initialValues = filterValues, customFormClass = '' } = props;

  // External handler flag is used when you want to capture the data or do something on form button click but continue the normal operation.
  // However, it causes unnecessary re-renders that causes "flickering" of values in form.
  // Removed for now and advised to use useListContext hook instead.
  const handleSubmit = (values: any) => {
    if (onSubmit) {
      return onSubmit(values);
    }
    setFilters(valueWalkFn(values), displayedFilters);
  };

  const resetFilter = (values: any, form: any) => {
    if (onReset) {
      return onReset(values, form);
    }
    setFilters({}, []);
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, form }) => (
          <form
            onSubmit={handleSubmit}
            className={`${classes.formRoot} ${customFormClass}`}
          >
            {children}
            <Button type="submit" variant="contained" color="primary">
              {translate('asurion.common.form.actions.filter')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(values) => resetFilter(values, form)}
            >
              {translate('asurion.common.form.actions.clear')}
            </Button>
          </form>
        )}
      </Form>
    </div>
  );
};

export default AsurionSearchFilter;

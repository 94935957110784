import englishMessages from './en';

export const thaiMessages: typeof englishMessages = {
  ra: {
    action: {
      add_filter: 'เพิ่มตัวกรอง',
      add: 'Add',
      back: 'Go Back',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      cancel: 'ยกเลิก',
      clear_input_value: 'Clear value',
      clone: 'Clone',
      confirm: 'ยืนยัน',
      create: 'สร้าง',
      create_item: 'Create %{item}',
      delete: 'ลบ',
      edit: 'แก้ไข',
      export: 'ส่งข้อมูลในรูปแบบ Excel',
      list: 'รายการ',
      refresh: 'รีเฟรช',
      remove_filter: 'ลบตัวกรอง',
      remove: 'Remove',
      save: 'บันทึก',
      search: 'Search',
      show: 'แสดง',
      sort: 'Sort',
      undo: 'Undo',
      unselect: 'Unselect',
      expand: 'Expand',
      close: 'ปิด',
      open_menu: 'Open menu',
      close_menu: 'Close menu',
      update: 'Update',
      move_up: 'Move up',
      move_down: 'Move down',
      select_all: 'Select All',
      select_row: 'Select Row',
    },
    boolean: {
      true: 'ใช่',
      false: 'ไม่ใช่',
      null: ' ',
    },
    page: {
      create: 'สร้าง %{name}',
      dashboard: 'แดชบอร์ด',
      edit: 'แก้ไข %{name} #%{id}',
      error: 'เกิดข้อผิดพลาดบางอย่าง',
      list: 'รายการ %{name}',
      loading: 'กำลังโหลด',
      not_found: 'ไม่พบ',
      show: 'แสดง %{name} #%{id}',
      empty: 'No %{name} yet.',
      invite: 'Do you want to add one?',
    },
    input: {
      file: {
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.',
      },
      image: {
        upload_several: 'อัปโหลดหลายไฟล์',
        upload_single: 'อัปโหลด',
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing:
          'At least one of the associated references no longer appears to be available.',
        single_missing:
          'Associated reference no longer appears to be available.',
      },
      password: {
        toggle_visible: 'Hide password',
        toggle_hidden: 'Show password',
      },
    },
    message: {
      about: 'เกี่ยวกับ',
      are_you_sure: 'คุณแน่ใจหรือไม่?',
      bulk_delete_content:
        'คุณแน่ใจใช่ไหมที่จะลบ %{name}? |||| คุณแน่ใจใช่ไหมที่จะลบ %{smart_count} รายการ?',
      bulk_delete_title: 'ลบ %{name} |||| ลบ %{name} %{smart_count} รายการ',
      bulk_update_content:
        'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?',
      bulk_update_title: 'Update %{name} |||| Update %{smart_count} %{name}',
      delete_content: 'Are you sure you want to delete this item?',
      delete_title: 'ลบ %{name} #%{id}',
      details: 'รายละเอียด',
      error: 'เกิดข้อผิดพลาดของไคลเอ็นต์ทำให้คำขอของคุณไม่สามารถดำเนินการได้',
      invalid_form: 'แบบฟอร์มไม่ถูกต้อง โปรดตรวจสอบข้อผิดพลาด',
      loading: 'กำลังโหลดข้อมูล กรุณารอสักครู่',
      no: 'ไม่ใช่',
      not_found: 'คุณพิมพ์ URL ผิดหรือลิงก์ปลายทางไม่ถูกต้อง',
      yes: 'ใช่',
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?",
      invalid_otp: 'Incorrect OTP',
    },
    navigation: {
      no_results: 'ไม่พบข้อมูล',
      no_more_results: 'ไม่พบหน้า %{page} กรุณากลับไปหน้าก่อนหน้านี้',
      page_out_of_boundaries: 'เกินจำนวนหน้าทั้งหมด',
      page_out_from_end: 'เกินจำนวนหน้าสุดท้าย',
      page_out_from_begin: 'เกินจำนวนหน้าแรก',
      page_range_info: '%{offsetBegin}-%{offsetEnd} จาก %{total}',
      page_rows_per_page: 'จำนวนแถวต่อหน้า',
      next: 'ถัดไป',
      prev: 'ย้อนกลับ',
      skip_nav: 'Skip to content',
    },
    sort: {
      sort_by: 'Sort by %{field} %{order}',
      ASC: 'ascending',
      DESC: 'descending',
    },
    auth: {
      auth_check_error: 'กรุณาเข้าสู่ระบบเพื่อดำเนินการต่อ',
      user_menu: 'Profile',
      username: 'ชื่อผู้ใช้งาน',
      password: 'รหัสผ่าน',
      sign_in: 'เข้าระบบ',
      sign_in_error: 'การเข้าระบบล้มเหลว',
      logout: 'ออกจากระบบ',
    },
    notification: {
      updated: 'รายละเอียดอัปเดตแล้ว |||| รายละเอียดอัปเดตแล้ว',
      created: 'สร้างข้อมูล',
      deleted: 'ลบข้อมูล |||| ลบ %{smart_count} ข้อมูล',
      bad_item: 'ข้อมูลไม่ถูกต้อง',
      item_doesnt_exist: 'ไม่มีรายการ',
      http_error: 'การเชื่อมต่อล้มเหลว',
      data_provider_error: 'dataProvider error. Check the console for details.',
      i18n_error: 'Cannot load the translations for the specified language',
      canceled: 'Action cancelled',
      logged_out: 'Your session has ended, please reconnect.',
      not_authorized: "You're not authorized to access this resource.",
    },
    validation: {
      required: 'จำเป็น',
      minLength: 'ต้องมีตัวอักษรอย่างน้อย %{min} ตัวอักษร',
      maxLength: 'ต้องมีตัวอักษรน้อยกว่า %{max} ตัวอักษร',
      minValue: 'ต้องมีค่าอย่างน้อย %{min}',
      maxValue: 'ต้องมีค่าน้อยกว่า %{max}',
      number: 'ต้องเป็นตัวเลขเท่านั้น',
      email: 'ต้องเป็นอีเมลที่ถูกต้อง',
      oneOf: 'ต้องเป็นหนึ่งใน: %{options}',
      regex: 'ต้องตรงกับรูปแบบเฉพาะ (regexp): %{pattern}',
    },
  },
  resources: {
    reports: {
      download: {
        complete: 'Report downloaded',
        error: 'Error downloading report',
      },
    },
    'partner-admins': {
      name: 'พนักงานขายใหม่ |||| พนักงานขายใหม่',
      fields: {
        name: 'ชื่อ',
        userEmail: 'อีเมล',
        firstName: 'ชื่อจริง',
        lastName: 'นามสกุล',
        nickname: 'ชื่อเล่น',
        contactNumber: 'เบอร์ติดต่อ',
        createdDate: 'วันที่สร้าง',
        partnerAccount: 'Partner Account',
      },
      new_title: 'เพิ่มชื่อผู้ดูแล Partner',
      edit_title: 'แก้ไขข้อมูล',
    },
    'business-partners': {
      name: 'Business Partner |||| Business Partners',
      fields: {
        name: 'Name',
        createdDate: 'Date Created',
      },
      new_title: 'New Business Partner Admin',
      edit_title: 'Edit Business Partner Admin',
    },
    'business-partner-admins': {
      name: 'Business Partner Admin |||| Business Partner Admins',
      fields: {
        name: 'Name',
        userEmail: 'Email Address',
        firstName: 'First name',
        lastName: 'Last name',
        nickname: 'Nickname',
        contactNumber: 'Contact Number',
        createdDate: 'Date Created',
      },
      new_title: 'New Business Partner Admin',
      edit_title: 'Edit Business Partner Admin',
    },
    'partner-stores': {
      name: 'ร้านค้าใหม่ |||| ร้านค้าใหม่',
      fields: {
        title: 'Title',
        userEmail: 'อีเมล',
        partnerStoreName: 'ชื่อร้านค้า',
        partnerStoreName_alt: 'ชื่อร้านค้า',
        contactNumber1: 'เบอร์ติดต่อร้านค้า',
        contactNumber1_alt: 'เบอร์ติดต่อร้านค้า',
        branchName: 'สาขา',
        address: 'ที่่อยู่',
        contactPerson1: 'เบอร์ผู้ติดต่อ',
        contactPerson1_alt: 'ชื่อผู้ติดต่อ(หลัก)',
        email1: 'อีเมล',
        email1_alt: 'อีเมลของผู้ติดต่อ(หลัก)',
        contactNumber1_alt2: 'เบอร์ติดต่อ(หลัก)',
        contactPerson2: 'ชื่อผู้ติดต่อสำรอง',
        email2: 'อีเมลผู้ติดต่อสำรอง',
        contactNumber2: 'เบอร์ผู้ติดต่อสำรอง ',
        createdDate: 'วันที่สร้าง',
      },
      contactPerson1_search: 'ค้นหาชื่อ',
      partnerStoreName_search: 'ค้นหาชื่อร้านค้า',
      branchName_search: 'ค้นหาสาขา',
      branchName_placeholder: 'ชื่อสาขา',
      new_title: 'ร้านค้าใหม่',
      edit_title: 'แก้ไขรายละเอียดร้านค้า',
    },
    'store-managers': {
      name: 'ผู้จัดการร้านค้าใหม่ |||| ผู้จัดการร้านค้าใหม่',
      fields: {
        name: 'ชื่อ',
        userEmail: 'อีเมล',
        title: 'คำนำหน้า',
        firstName: 'ชื่อจริง',
        lastName: 'นามสกุล',
        nickname: 'ชื่อเล่น',
        contactNumber: 'เบอร์ติดต่อ',
        partnerStores: 'ชื่อร้านค้า',
        partnerStores_alt: 'Store Name',
        salesPartnerId: '',
        altMdn: 'เบอร์ติดต่อสำรอง',
        createdDate: 'วันที่สร้าง',
        salesRepInfo: {
          lineId: 'Line ID (ถ้ามี)',
        },
      },
      new_title: 'ผู้จัดการร้านค้าใหม่',
      edit_title: 'แก้ไขข้อมูล',
    },
    'sales-representatives': {
      name: 'พนักงานขายใหม่ |||| พนักงานขายใหม่',
      fields: {
        name: 'ชื่อ',
        userEmail: 'อีเมล',
        title: 'คำนำหน้า',
        contactNumber: 'เบอร์ติดต่อ',
        salesPartnerStoreId: 'ร้านค้า',
        salesPartnerStoreId_alt: 'ชื่อร้านค้า',
        store: 'ร้านค้า',
        role: 'ตำแหน่ง',
        firstName: 'ชื่อจริง',
        lastName: 'นามสกุล',
        nickname: 'ชื่อเล่น',
        altMdn: 'เบอร์ติดต่อสำรอง',
        createdDate: 'วันที่สร้าง',

        bankDetails: {
          firstName: 'ชื่อจริง (ที่ปรากฏในสมุดบัญชีธนาคาร)',
          lastName: 'นามสกุล (ที่ปรากฏในสมุดบัญชีธนาคาร)',
          bankNameId: 'ชื่อธนาคาร',
          bankBranchId: 'สาขาธนาคาร',
          accountNumber: 'เลขที่บัญชีธนาคาร',
          swiftCode: 'Swift Code',
          accountTypeId: 'ประเภทของบัญชี',
        },

        salesRepInfo: {
          lineId: 'Line ID (ถ้ามี)',
        },
      },
      new_title: 'พนักงานขายใหม่',
      edit_title: 'แก้ไขข้อมูล',
      store_search: 'ค้นหาร้านค้า',
    },
    incentives: {
      name: 'ผลตอบแทน |||| ผลตอบแทน',
      fields: {
        info: 'ข้อมูล',
        enrollmentDate: 'วันที่ลงทะเบียน',
        paymentDate: 'วันที่ชำระเงิน',
        salesPerson: 'พนักงานขาย',
        store: 'ร้านค้า',
        partnerCode: 'รหัส Partner',
        tier: 'กลุ่มราคา',
        plan: 'โปรแกรม',
        paymentStatus: 'สถานะการชำระเงิน',
        incentiveStatus: 'สถานะผลตอบแทน',
        subscriptionFee: 'ค่าบริการ',
        discount: 'ส่วนลด',
        discountedSubscriptionFee: 'ค่าบริการ (หลังหักส่วนลด)',
        incentive: 'ผลตอบแทน',
        agreementRequestId: 'เลขที่อ้างอิง',
      },
    },
    'sales-partners': {
      fields: {
        partnerName: 'Company Name',
        partnerCode: 'Partner Code',
        salesManagers: 'Asurion Sales Manager',
      },
      fieldsets: {
        partnerAccountDetails: 'Partner Account Details',
        address: 'Address',
        contactDetails: 'Contact Details',
      },
      placeholders: {
        partnerName: 'Company Name',
        salesManagers: 'Search Name',
      },
      new_title: 'New Partner Account',
      edit_title: '',
    },
  },
  asurion: {
    login: {
      title: 'เข้าสู่ระบบบัญชี',
      forgot_password_helper: 'ต้องการความช่วยเหลือเกี่ยวกับรหัสผ่านของคุณ',
      login_transition: 'เข้าสู่ระบบ...',
    },
    profile: {
      activation: {
        success: {
          0: 'Your account has been activated. Click',
          1: 'here',
          2: 'to login',
        },
        error: 'There was an error activating your account.',
      },
      set_password: {
        title: 'Create Password',
        set_password_helper:
          'You must create a password to access your account.',
        form: {
          fields: {
            password: 'Password',
            confirm_password: 'Confirm Password',
            set_password: 'Set Password',
          },
          placeholder: {
            password: 'Enter Password',
            confirm_password: 'Confirm Enter Password',
          },
        },
      },
      change_password: {
        title: 'เปลี่ยนรหัสผ่าน',
        form: {
          change_password: {
            label: 'รหัสผ่านปัจจุบัน',
            placeholder: 'ใส่รหัสผ่านปัจจุบัน',
          },
          new_password: {
            label: 'รหัสผ่านใหม่',
            placeholder: 'ใส่รหัสผ่านใหม่',
          },
          confirm_new_password: {
            label: 'ยืนยันรหัสผ่านใหม่',
            placeholder: 'ยืนยันรหัสผ่านใหม่',
          },
        },
      },
      view: {
        title: 'ประวัติของฉัน',
        partnerCode: 'รหัส Partner',
      },
      edit: {
        title: 'แก้ไขประวัติของฉัน',
      },
    },
    reset_password_funnel: {
      0: {
        title: 'รีเซ็ตรหัสผ่านของคุณ',
        description: `ใส่อีเมลที่เชื่อมโยงกับบัญชีของคุณ และระบบจะทำการส่งอีเมลพร้อม OTP เพื่อทำการรีเซ็ตรหัสผ่านของคุณ`,
        form: {
          send_otp: {
            label: 'ส่ง OTP เพื่อตั้งรหัสผ่านใหม่',
          },
        },
      },
      1: {
        title: 'ใส่รหัสยืนยัน',
        description: `รหัสยืนยันได้ถูกส่งไปยังอีเมลที่ลงทะเบียนของคุณ`,
        otp_expiration_helper: 'เพื่อความปลอดภัย รหัสของคุณจะหมดอายุใน',
        otp_expiration: '%{minutes} นาที.',
        resend_helper: 'ฉันยังไม่ได้รับรหัส',
        resend_label: 'กรุณาส่งรหัสใหม่อีกครั้ง',
      },
      2: {
        title: 'เปลี่ยนรหัสผ่าน',
      },
    },
    common: {
      action: {
        add_resource: 'เพิ่ม%{resource}',
        verify: 'ตรวจสอบ',
        submit: 'Submit',
        validating: 'กำลังตรวจสอบความถูกต้อง',
      },
      error: {
        generic: 'เกิดข้อผิดพลาดในการติดต่อเซิร์ฟเวอร์',
        must_be_similar_to: 'รหัสผ่านไม่ตรงกัน',
      },
      table: {
        fields: {
          action: 'ใช้งาน',
          status: 'สถานะ',
        },
      },
      navigation: {
        back: 'ยกเลิก',
        done: 'ยืนยัน',
        done_alt: 'ถัดไป',
        menus: {
          management: 'การจัดการ',
          partner_admin: 'ผู้ดูแล Partner',
          partner_account: 'Partner Account',
          store_account: 'บัญชีของร้าน',
          store_manager: 'ผู้จัดการร้าน',
          sales_representative: 'พนักงานขาย',
          sales_performance: 'ยอดขาย',
          bulletin: 'ข่าวสารน่ารู้',
          incentives: 'ผลตอบแทน',
          partner_sales: 'ยอดขาย',
          programs: 'โปรแกรม',
          phone_make: 'ยี่ห้อ',
          business_partner: 'Business Partner',
          business_partner_admin: 'Business Partner Admin',
        },
      },
      dialog: {
        active: {
          title: 'ยืนยัน',
          message:
            'เมื่อกดปุ่ม "ยืนยัน" ผู้ใช้จะมีสิทธิ์เข้าถึงบัญชีตามการได้รับอนุญาต',
          confirm: 'ต้องการดำเนินการต่อหรือไม่',
        },
        deactivate: {
          title: `ยืนยันยกเลิกการใช้งานเจ้าของบัญชี`,
          message:
            'เมื่อกดปุ่ม "ยืนยัน" เจ้าของบัญชีจะไม่สามารถเข้าถึงบัญชีได้อีก',
          confirm: 'คุณต้องการดำเนินการต่อหรือไม่',
        },
        generic_confirm: {
          message:
            // 'By clicking Confirm, System will send an email to the email address provided to notify new account created.',
            'เมื่อกดปุ่ม"ยืนยัน" แสดงว่าคุณได้ยืนยันรายละเอียดทั้งหมดถูกต้อง',
          confirm: 'คุณต้องการดำเนินการต่อหรือไม่',
        },
        done: {
          title: 'สำเร็จ!',
          message: 'ระบบได้เพิ่ม%{entityName}',
        },
      },
      form: {
        active: 'ใช้งาน',
        inactive: 'ไม่ใช้งาน',
        pending: 'รอดำเนินการ',
        activation_link: 'ส่งลิงก์การตั้งค่าบัญชีอีกครั้ง',
        status: 'สถานะ',
        new_title: 'New %{resource}',
        cancel: 'ยกเลิก',
        sections: {
          store_details: 'รายละเอียดร้านค้า',
          address: 'ที่อยู่',
          contact_details: 'ผู้ติดต่อ',
          sales_rep_details: 'รายละเอียดพนักงานขาย',
          bank_details: 'รายละเอียดบัญชีธนาคาร',
        },
        actions: {
          clear: 'ล้างข้อมูล',
          filter: 'ตัวกรอง',
          download: 'Download',
        },
        email: {
          label: 'อีเมล',
          placeholder: 'ใส่อีเมล',
          search_placeholder: 'ค้นหาอีเมล',
        },
        password: {
          label: 'รหัสผ่าน',
          placeholder: 'ใส่รหัสผ่าน',
          change_password: 'เปลี่ยนรหัสผ่าน',
        },
        login: {
          label: 'เข้าสู่ระบบ',
        },
        full_name: {
          label: 'ชื่อ',
          search_placeholder: 'ค้นหาชื่อ',
        },
        first_name: {
          label: 'ชื่อ',
        },
        last_name: {
          label: 'นามสกุล',
        },
        store: {
          label: 'ร้านค้า',
          placeholder: 'e.g. Public Company Limited',
          search_placeholder: 'ค้นหาร้านค้า',
        },
        contact_number: {
          label: 'เบอร์ติดต่อ',
          validation: {
            already_exists: 'Contact Number already used',
          },
        },
        partner_account: {
          label: 'Partner Account Name',
          placeholder: 'Search Partner Account',
        },
        altMdn: {
          label: 'เบอร์ติดต่อสำรอง (ถ้ามี)',
        },
        lineId: {
          label: 'Line ID (ถ้ามี)',
        },
        enrollment_date: {
          label: 'วันที่ลงทะเบียน',
        },
        payment_date: {
          label: 'วันที่ชำระเงิน',
        },
        sales_person: {
          label: 'พนักงานขาย',
        },
        store_alt: {
          label: 'ร้านค้า',
        },
        incentive_status: {
          label: 'สถานะผลตอบแทน',
        },
        plan: {
          label: 'โปรแกรม',
        },
        collection: {
          title: {
            mister: 'นาย',
            miss: 'นางสาว',
            missus: 'นาง',
          },
          address: {
            country: {
              label: 'ประเทศ',
            },
            businessName: {
              label: 'ชื่อบริษัท (ถ้ามี)',
              placeholder: 'e.g. ABC Company',
            },
            building: {
              label: 'ชื่ออาคาร / หมู่บ้าน',
              placeholder: 'e.g. 5, Soi Ta-iat',
            },
            roomNo: {
              label: 'เลขที่ห้อง',
            },
            houseNo: {
              label: 'เลขที่บ้าน.',
            },
            village: {
              label: 'หมู่ที่',
              placeholder: 'หมู่ที่',
            },
            alley: {
              label: 'ซอย',
              placeholder: 'ซอย',
            },
            street: {
              label: 'ถนน',
            },
            district: {
              label: 'แขวง / ตำบล',
              placeholder: 'แขวง / ตำบล',
            },
            subDistrict: {
              label: 'แขวง / ตำบล',
              placeholder: 'แขวง / ตำบล',
            },
            province: {
              label: 'จังหวัด',
            },
            postalCode: {
              label: 'รหัสไปรษณีย์',
            },
          },
          user_status: {
            all: 'ทั้งหมด',
            active: 'ใช้งานอยู่',
            inactive: 'ปิดการใช้งาน',
            pending: 'รอดำเนินการ',
          },
          plans: {
            all: 'ทั้งหมด',
            monthly: 'รายเดือน',
            '12monthly': 'เหมาจ่าย (12เดือน)',
            '24monthly': 'เหมาจ่าย (24เดือน)',
          },
          payment_status: {
            all: 'ทั้งหมด',
            pending: 'รอดำเนินการ',
            failed: 'ชำระไม่สำเร็จ',
            success: 'ชำระแล้ว',
          },
          incentive_status: {
            all: 'ทั้งหมด',
            unpaid: 'ไม่มีการชำระ',
            pending: 'รอการชำระ',
            processing: 'กำลังดำเนินการ',
            paid: 'ชำระแล้ว',
          },
          contact_details: {
            directorName: 'Director Name',
            contactPerson1: 'Primary Contact Person Name',
            email1: 'Primary Contact Person Email Address',
            contactNumber1: 'Primary Contact Number',
          },
        },
      },
    },
  },
};

export default thaiMessages;

import { ROLES } from './types';

export const roleLabelMapping: { [key in ROLES]?: string } = {
  SYSTEM_ADMIN: 'System Administrator',
  COUNTRY_MGR: 'Asurion Country Manager',
  SALES_MGR: 'Asurion Sales Manager',
  SALES_REP: 'Sales Representative',
  STORE_MGR: 'Store Manager',
  PARTNER_ADMIN: 'Partner Admin',
  CUSTOMER: 'Customer',
  FINANCE_MGR: 'Asurion Finance Manager',
  BUSINESS_PARTNER_ADMIN: 'Business Partner Admin',
};

export const COUNTRY_KEY = 'user_country';
export const SALES_PARTNER_ID = 'sales_partner_id';
export const THEME_CONFIG_KEY = 'theme';
export const NAVIGATION_MENU_KEY = 'user_menu';

export const PAYOUT_BATCH_ID = 'incentive_calculation_batch_id';

export const AVAILABLE_COUNTRIES = [
  'tha',
  'phl',
  'twn',
  'kor',
  'jpn',
  'mys',
] as const;

export const DEFAULT_COUNTRY_LIST: Array<{
  id: typeof AVAILABLE_COUNTRIES[number];
  name: string;
  defaultLanguage: string;
  maxStoresPerManager?: number;
}> = [
  {
    id: 'tha',
    name: 'Thailand',
    defaultLanguage: 'th',
    maxStoresPerManager: 15,
  },
  { id: 'phl', name: 'Philippines', defaultLanguage: 'en' },
  { id: 'twn', name: 'Taiwan', defaultLanguage: 'en', maxStoresPerManager: 20 },
  { id: 'mys', name: 'Malaysia', defaultLanguage: 'en' },
  { id: 'kor', name: 'South Korea', defaultLanguage: 'en' },
  { id: 'jpn', name: 'Japan', defaultLanguage: 'en' },
];

export const reportsMapping = [
  {
    id: '6f9928c7-c0d3-4644-a700-3eb0de2fde7e',
    title: 'make',
  },
  {
    id: 'a71fd9bd-62ea-4a70-a682-4be9dd738d39',
    title: 'program',
  },
  {
    id: '0cf00c1c-185b-4dae-908f-6afab641ef93',
    title: 'store',
  },
  {
    id: '1678b0df-fc34-446a-a4c4-564da536a9ee',
    workspaceId: '0e5226c0-a05d-44e6-bb87-08bf788dc29c',
    title: 'tw-all',
  },
  {
    id: '1678b0df-fc34-446a-a4c4-564da536a9ee',
    workspaceId: '0e5226c0-a05d-44e6-bb87-08bf788dc29c',
    title: 'tw-retailer',
  },
];

export const REPORT_ROLES = {
  ALL: 'All',
  SALES_MANAGER: 'SalesManagerRole',
  PARTNER: 'PartnerRole',
  STORE_MANAGER: 'StoreManagerRole',
  SALES_REP: 'SalesRepRole',
  RETAILER: 'Retailer',
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useGetIdentity, usePermissions } from 'react-admin';
import { Redirect } from 'react-router-dom';
import { ROLES } from '../../common/types';
import { ROUTES } from '../../routes';

export const SalesPerformance = () => {
  const { permissions = [] } = usePermissions();
  const { identity, loaded, loading } = useGetIdentity();
  const [to, setTo] = useState<string | false>(false);

  useEffect(() => {
    let to;
    const role: ROLES = identity?.role || 'ANONYMOUS';
    switch (role) {
      case 'COUNTRY_MGR':
      case 'FINANCE_MGR':
        to = ROUTES.PARTNER_SALES.path;
        break;
      case 'SALES_MGR':
        to = ROUTES.PARTNER_SALES_PERFORMANCE.path;
        break;
      default:
        to = ROUTES.PARTNER_SALES.path;
        break;
    }

    if (permissions.includes('view_enrollment_report')) {
      to = ROUTES.ENROLLMENT_REPORT.path;
    }

    if (!loading && loaded) {
      setTo(to);
    }
  }, [loaded, loading, identity, permissions]);
  return to ? <Redirect to={to} /> : null;
};

export default SalesPerformance;

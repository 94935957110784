import * as React from 'react';
import { makeStyles, Tabs } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { LinkTabProps } from '../components/common/LinkTab';
import { resolveActivePath } from '../routes';
import { appBarHeight } from './DefaultAppBar';
import { useGetIdentity, usePermissions } from 'react-admin';
import { colors } from '../themes/default';
import { ArrayElement, ROLES } from '../common/types';
import { tabsMapping } from '../components/common/Tab';
import { useEffect, useState } from 'react';
import { useNavigationContext } from '../contexts/NavigationContext';
import { useCountryId } from '../contexts/CountryContext';
import { withTestCountry } from '../common/util';

const useStyles = makeStyles({
  secondaryNavigation: {
    marginTop: appBarHeight,
    marginBottom: appBarHeight * -1 + 26,
  },
  secondaryNavigationRoot: {
    marginTop: 8,
    '& .MuiTab-textColorInherit': {
      color: colors.brand.black,
    },
  },
});

type TabType = Array<keyof typeof tabsMapping>;

const SecondaryNavigation = () => {
  const classes = useStyles();

  const { pathname }: any = useLocation();
  const { permissions = [] } = usePermissions();
  const { countryId } = useCountryId();
  const { identity } = useGetIdentity();
  const { navigationTree } = useNavigationContext();
  const activePath = resolveActivePath(pathname, 1, navigationTree).substring(
    1
  );
  const [tabs, setTabs] = useState<TabType>([]);

  const isSiblingOfActivePath: LinkTabProps['resolver'] = (to) => {
    if (!activePath) {
      return false;
    }
    const currentParentNode = navigationTree[0].getNodeById(activePath);
    if (!currentParentNode) {
      return false;
    }
    const toNode = navigationTree[0].getNodeById((to as string).substring(1));
    return toNode?.isDescendantOf(currentParentNode);
  };

  useEffect(() => {
    let tabs = [];
    const renderTabs = (): TabType => {
      const role: ROLES = identity?.role || 'ANONYMOUS';
      switch (role) {
        case 'SYSTEM_ADMIN':
          return [
            'CountryManagerTab',
            'SalesManagerTab',
            'PartnerAdminTab',
            'BusinessPartnerTab',
            'BusinessPartnerAdminTab',
            'StoreManagerTab',
            'SalesRepresentativeTab',

            'SalesPartnerTab',
            'StoreAccountTab',

            'PartnerSalesTab',
            'ProgramsTab',
            'PhoneMakeTab',
          ];
        case 'COUNTRY_MGR':
        case 'FINANCE_MGR':
          return ['PartnerSalesTab', 'ProgramsTab', 'PhoneMakeTab'];
        case 'SALES_MGR':
          return [
            'BusinessPartnerTab',
            'BusinessPartnerAdminTab',

            'StoreAccountManagementTab',
            'StoreManagerManagementTab',
            'SalesRepresentativeManagementTab',

            'PartnerSalesPerformanceTab',
            'ProgramsPerformanceTab',
            'PhoneMakePerformanceTab',
          ];
        case 'PARTNER_ADMIN':
          return [
            'PartnerAdminTab',
            'StoreAccountTab',
            'StoreManagerTab',
            'SalesRepresentativeTab',

            'PartnerSalesPerformanceTab',
            'ProgramsPerformanceTab',
            'PhoneMakePerformanceTab',
          ];
        case 'BUSINESS_PARTNER_ADMIN':
          return [
            'PartnerAdminTab',
            'SalesPartnerTab',

            'PartnerSalesPerformanceTab',
            'ProgramsPerformanceTab',
            'PhoneMakePerformanceTab',
          ];
        default:
          return [];
      }
    };

    tabs = renderTabs();

    // Hackish
    if (withTestCountry(['twn']).includes(countryId as string)) {
      tabs = tabs.filter((tab) => tab !== 'SalesRepresentativeManagementTab');

      const reportsTab: TabType = [
        'PartnerSalesTab',
        'ProgramsTab',
        'PhoneMakeTab',

        'PartnerSalesPerformanceTab',
        'ProgramsPerformanceTab',
        'PhoneMakePerformanceTab',
      ];

      tabs = tabs.filter((tab) => !reportsTab.includes(tab));
      tabs.push('WeeklyEnrollmentReportTab');

      if (identity?.role === 'PARTNER_ADMIN') {
        tabs = tabs.filter((tab) => tab !== 'SalesRepresentativeTab');
      }
    }
    setTabs(tabs);
  }, [identity, countryId]);

  return (
    <div className={classes.secondaryNavigation}>
      {permissions.length > 0 && activePath && (
        <Tabs
          value={false}
          classes={{
            root: classes.secondaryNavigationRoot,
          }}
        >
          {tabs.map((tab: ArrayElement<TabType>, i: number) => {
            return React.cloneElement(tabsMapping[tab] || <></>, {
              key: `secondary-${i}`,
              resolver: isSiblingOfActivePath,
            });
          })}
        </Tabs>
      )}
    </div>
  );
};

export default SecondaryNavigation;

import * as React from 'react';
import { LinkTab, LinkTabProps } from '../LinkTab';
import { Can, CanProps } from '../../authentication/Can';

export type BaseTabProps = LinkTabProps & CanProps;

export const BaseTab = ({
  do: perform,
  all,
  except,
  value,
  ...restProps
}: BaseTabProps) => {
  return (
    <Can do={perform} all={all} except={except} value={value}>
      <LinkTab value={value} {...restProps} />
    </Can>
  );
};

export default BaseTab;

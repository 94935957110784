import BusinessPartnersCreate from './BusinessPartnersCreate';
import BusinessPartnersEdit from './BusinessPartnersEdit';
import BusinessPartnersList from './BusinessPartnersList';

const resourceMapping = {
  list: BusinessPartnersList,
  create: BusinessPartnersCreate,
  edit: BusinessPartnersEdit,
};

export default resourceMapping;

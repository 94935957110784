export const mustBeSimilarTo = (
  referenceField: string,
  tplVars: { targetField: string }
) => (value: string, allValues: any) => {
  return value !== allValues[referenceField]
    ? {
        message: 'asurion.common.error.must_be_similar_to',
        args: {
          targetField: tplVars.targetField,
        },
      }
    : undefined;
};

export default mustBeSimilarTo;

import React, { useState } from 'react';
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';

export interface SingleStep {
  label: string;
  content: Array<React.ReactNode>;
}

export interface Props {
  steps: Array<SingleStep>;
}

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    gap: 10,
  },
  button: {},
});

export const AsurionStepper: React.FC<Props> = ({ steps }) => {
  const translate = useTranslate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const classes = useStyles();
  const formState = useFormState();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map(({ label, content }, index) => (
        <Step>
          <StepLabel>{label}</StepLabel>
          <StepContent>
            {content}
            <div className={classes.actionsContainer}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                {translate('asurion.common.navigation.back')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                disabled={formState.hasValidationErrors || formState.validating}
              >
                {activeStep === steps.length - 1
                  ? translate('asurion.common.navigation.done')
                  : translate('ra.navigation.next')}
              </Button>
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default AsurionStepper;

import { useState, SetStateAction } from 'react';
import { Card, IconButton, Popover } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  infoButton: {
    margin: '0 0 0 0 !important',
    padding: '5px',
  },
  infoRoot: {
    minWidth: 275,
    padding: '15px 15px 15px 15px !important',
    '& > div': {
      paddingBottom: '0 !important',
    },
  },
});

// TODO: Create Context for reusable components
export const InfoPopover = (props: any) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);

  const { children, ...rest } = props;

  const handleClick = (event: { currentTarget: SetStateAction<null> }) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);
  const id = open ? 'info-popover' : undefined;

  return (
    <div>
      <IconButton
        className={classes.infoButton}
        onClick={handleClick}
        {...rest}
      >
        <Info style={{ fontSize: 12 }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/* <ArrowDropUp color="action" style={{ fontSize: 30 }} /> */}
        <Card className={classes.infoRoot} variant="outlined">
          {children}
        </Card>
      </Popover>
    </div>
  );
};

export default InfoPopover;

import * as React from 'react';
import {
  Button,
  Container,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useAuthenticated, useGetIdentity, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import Field from '../common/Scaffolding/Field';
import EditIcon from '../common/Branding/Icons/EditIcon';
import { useSalesPartner } from '../../common/hooks';

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    marginTop: 120,
    gap: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 32,
    lineHeight: '120%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 0,
    color: theme.palette.common.black,
  },
  buttonRoot: {
    padding: `0 24px`,
  },
  editIconButton: {
    width: 24,
    height: 24,
    padding: 4,
  },
}));

export const ProfileView = () => {
  useAuthenticated({ allowed: 'view_own_profile' });
  const classes = useStyles();
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { partner } = useSalesPartner();

  return (
    <Container maxWidth="sm" classes={{ root: classes.containerRoot }}>
      <Typography
        className={classes.title}
        variant="h3"
        component="h1"
        align="center"
      >
        {translate('asurion.profile.view.title')}
        <IconButton
          className={classes.editIconButton}
          color="primary"
          component={Link}
          to={ROUTES.PROFILE_EDIT.path}
        >
          <EditIcon className={classes.editIconButton} />
        </IconButton>
      </Typography>

      <Field label={translate('asurion.common.form.first_name.label')}>
        {identity?.firstName}
      </Field>

      <Field label={translate('asurion.common.form.last_name.label')}>
        {identity?.lastName}
      </Field>

      <Field label={translate('asurion.common.form.email.label')}>
        {identity?.userEmail}
      </Field>

      <Field label={translate('asurion.profile.view.partnerCode')}>
        {partner?.partnerCode}
      </Field>

      <Field label={translate('asurion.common.form.password.label')}>
        <Button
          classes={{ root: classes.buttonRoot }}
          color="primary"
          component={Link}
          to={ROUTES.CHANGE_PASSWORD.path}
        >
          {translate('asurion.common.form.password.change_password')}
        </Button>
      </Field>
    </Container>
  );
};

export default ProfileView;

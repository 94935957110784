import * as React from 'react';
import {
  Create,
  CreateProps,
  email,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import AsurionForm from '../common/Form/AsurionForm';
import { AsurionStepper } from '../common/Scaffolding/AsurionStepper';
import { AsurionAddress } from '../common/Form/Collection/AsurionAddress';
import ContactNumber from '../common/Field/ContactNumber';
import { decorateWithRole } from '../../common/functions';
import { PartnerStores } from '../../common/types';
import { ROUTES } from '../../routes';
import { useCountryId } from '../../contexts/CountryContext';
import { useSuccessHandler } from '../../common/hooks';

export const SalesPartnersCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const { identity, loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  const { onSuccessHandler } = useSuccessHandler(
    translate('resources.partner-stores.name', 0),
    props.basePath
  );
  const transform = (data: any) => {
    return {
      ...data,
      ...decorateWithRole<PartnerStores>(
        identity,
        'PARTNER_ADMIN',
        'salesPartnerId'
      ),
      countryId,
      created_at: Date.now(),
      status: 'INACTIVE',
    };
  };

  const steps = [
    {
      label: translate('asurion.common.form.sections.store_details'),
      content: [
        <>
          <TextInput
            label="resources.partner-stores.fields.partnerStoreName"
            placeholder={translate('asurion.common.form.store.placeholder')}
            source="partnerStoreName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <ContactNumber
            label={translate(
              'resources.partner-stores.fields.contactNumber1_alt'
            )}
            source="contactNumber"
            placeholder="08-1234-5678"
            className="required"
          />

          {identity?.role !== 'PARTNER_ADMIN' && (
            <ReferenceInput
              label="Assign to Partner"
              source="salesPartnerId"
              reference="sales-partners"
              fullWidth
              validate={required()}
              className="required"
              variant="outlined"
              filter={{
                activeIndStatus: 'active',
                countryId,
              }}
            >
              <SelectInput optionText="partnerName" />
            </ReferenceInput>
          )}

          <TextInput
            label="resources.partner-stores.fields.branchName"
            placeholder={translate(
              'resources.partner-stores.branchName_placeholder'
            )}
            source="branchName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
        </>,
      ],
    },
    {
      label: translate('asurion.common.form.sections.address'),
      content: [<AsurionAddress />],
    },
    {
      label: translate('asurion.common.form.sections.contact_details'),
      content: [
        <>
          <TextInput
            label="resources.partner-stores.fields.contactPerson1_alt"
            placeholder="e.g John Smith"
            source="contactPerson1"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="resources.partner-stores.fields.email1_alt"
            placeholder="e.g johnsmith@gmail.com"
            source="email1"
            fullWidth
            validate={[required(), email()]}
            className="required"
            variant="outlined"
          />
          <ContactNumber
            label="resources.partner-stores.fields.contactNumber1_alt2"
            source="contactNumber1"
            className="required"
          />
          <TextInput
            label="resources.partner-stores.fields.contactPerson2"
            placeholder="e.g John Smith"
            source="contactPerson2"
            fullWidth
            variant="outlined"
          />
          <TextInput
            label="resources.partner-stores.fields.email2"
            placeholder="e.g johnsmith@gmail.com"
            source="email2"
            fullWidth
            variant="outlined"
          />
          <ContactNumber
            label="resources.partner-stores.fields.contactNumber2"
            source="contactNumber2"
            validate={undefined}
          />
        </>,
      ],
    },
  ];

  return loaded ? (
    <Create {...props} transform={transform} onSuccess={onSuccessHandler}>
      <AsurionForm
        title={translate('resources.partner-stores.new_title')}
        redirect="list"
        saveButtonLabel={translate('asurion.common.navigation.done')}
        BackLinkProps={{
          to: {
            pathname: ROUTES.PARTNER_STORES.path,
          },
        }}
      >
        <AsurionStepper steps={steps} />
      </AsurionForm>
    </Create>
  ) : null;
};

export default SalesPartnersCreate;

import '@calvear/rsuite-styles';
import { makeStyles } from '@material-ui/styles';
import countries from 'i18n-iso-countries';
import moment from 'moment';

import { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  Empty,
  FunctionField,
  List,
  ListProps,
  Pagination,
  PaginationProps,
  TextField,
  useGetIdentity,
  useNotify,
  useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router';

import {
  getTimezoneFromCountryCode,
  getBrowserDefaultTimezone,
  isFinanceUser,
  isPartnerAdmin,
} from '../../common/util';
import { useSalesPartnerId } from '../../contexts/SalesPartnerContext';
import { ROUTES } from '../../routes';
import InfoPopover from '../common/Dialog/InfoPopover';
import ActionableInfoField from '../common/Field/ActionableInfoField';

import {
  AdminDefaultIncentiveExporter,
  FinanceUserIncentiveExporter,
  IncentiveActions,
  PartnerAdminIncentiveExporter,
} from './IncentiveActions';
import {
  ConfirmCalculateDialog,
  WarningPendingDialog,
  DoneCalculationDialog,
  WarningNoneViableDialog,
} from './IncentiveDialogs';
import IncentiveFilter from './IncentiveFilter';

const useStyles = makeStyles({
  '@global': {
    '.page-number': {
      minWidth: '50px',
      padding: '0',
      margin: '0 3px',
      backgroundColor: 'transparent',
      '&:last-of-type': {
        marginRight: '10px',
      },
    },
    'button[class*="currentPageButton"]': {
      backgroundColor: '#d5d5d5',
    },
    '.next-page': {
      marginRight: '10px',
      padding: '0 5px 0 15px',
      backgroundColor: 'transparent',
    },
    '.previous-page': {
      marginLeft: '10px',
      padding: '0 15px 0 5px',
      backgroundColor: 'transparent',
    },
  },
  rootContainer: {
    padding: '60px 20px 0 20px',
  },
});

const IncentivePagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const IncentiveList = (props: ListProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();
  const notify = useNotify();

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showWarningExistingDialog, setShowWarningExistingDialog] =
    useState<boolean>(false);
  const [showNoneViableDialog, setShowNoneViableDialog] =
    useState<boolean>(false);
  const [showDoneCalculationDialog, setShowDoneCalculationDialog] =
    useState<boolean>(false);

  const [incentiveFilters, setIncentiveFilters] = useState<Object>({});
  const [incentiveData] = useState<Object>({});

  const { loaded, identity } = useGetIdentity();

  let { salesPartnerId } = useSalesPartnerId();
  salesPartnerId = salesPartnerId || identity?.extra.salesPartnerId;

  let countryCode = identity?.extra.countryId || 'tha';
  if (countryCode.length > 2) {
    let iso2 = countries.alpha3ToAlpha2(countryCode.toUpperCase());
    countryCode = iso2.toUpperCase();
  }

  const userDefaultTimezone =
    getTimezoneFromCountryCode(countryCode) || getBrowserDefaultTimezone();
  // console.log('User timezone is: ', userDefaultTimezone);

  const isPartnerIdSet =
    loaded && isPartnerAdmin(identity) ? !!salesPartnerId : true;

  const handleCalculate = (data: any, filters: any = {}) => {
    // console.log('Current page data:', data);
    // setIncentiveData((d) => Object.assign(d, data));

    // if (!_.isEmpty(filters)) {
    // console.log('Current filters: ', filters);
    setIncentiveFilters(filters);
    // }

    setShowConfirmDialog(true);
  };

  const handleConfirmCalculate = (isSuccess: boolean, otherData?: any) => {
    setShowConfirmDialog(false);
    if (isSuccess) {
      setShowDoneCalculationDialog(true);
    } else {
      if (otherData) {
        if (otherData === 403) {
          setShowWarningExistingDialog(true);
        } else if (otherData === 404) {
          setShowNoneViableDialog(true);
        } else {
          notify('An unknown error occured.', 'error', {}, false, 5000);
        }
      } else {
        notify('An unknown error occured.', 'error', {}, false, 5000);
      }
    }
  };

  const handleSuccessCalculate = () => {
    setShowDoneCalculationDialog(false);
    history.push(ROUTES.INCENTIVES_CALCULATION.path);
  };

  let today = new Date(); // moment().endOf('day');
  let startDate = new Date(); //moment().subtract(7,'d').startOf('day');
  startDate.setDate(today.getDate() - 6);

  let defaultStartDate = moment(startDate)
    .utc()
    .tz(getBrowserDefaultTimezone(), true)
    .startOf('day');
  let defaultEndDate = moment(today)
    .utc()
    .tz(getBrowserDefaultTimezone())
    .endOf('day')
    .seconds(58);
  // const defaultEnrollmentDate = [convertToStartOfDay(startDate), convertToEndOfDay(today)];
  const defaultEnrollmentDate = [defaultStartDate, defaultEndDate]; // [convertToStartOfDay(defaultStartDate), convertToEndOfDay(defaultEndDate)];
  // console.log(defaultEnrollmentDate);

  let filter = {};
  if (salesPartnerId) {
    filter = {
      ...filter,
      partnerId: salesPartnerId,
    };
  }

  const defaultFilters = {
    enrollmentDate: defaultEnrollmentDate,
    paymentDate: [],
    plan: 'all',
    paymentStatus: 'all',
    incentiveStatus: 'all',
  };

  const statusLabel = [
    'BUSINESS_PARTNER_ADMIN',
    'PARTNER_ADMIN',
    'COUNTRY_MGR',
  ].includes(identity?.role)
    ? translate('asurion.common.table.fields.status')
    : translate('resources.incentives.fields.paymentStatus');

  let exporter = AdminDefaultIncentiveExporter;
  if (isPartnerAdmin(identity)) {
    exporter = PartnerAdminIncentiveExporter;
  } else if (isFinanceUser(identity)) {
    exporter = FinanceUserIncentiveExporter;
  }

  const dateOptions = {
    timeZone: userDefaultTimezone,
  };

  useEffect(() => {
    if (!isPartnerIdSet) {
      const errorMessage = 'No Partner ID set for current Partner Admin.';
      console.error(errorMessage);
      notify(errorMessage, 'error', {}, false, 5000);
    }
  }, [isPartnerIdSet, notify]);

  useEffect(() => {
    setIncentiveFilters((f) => ({ ...f, ...defaultFilters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded && isPartnerIdSet ? (
    <div className={classes.rootContainer}>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={exporter}
        actions={<IncentiveActions onSubmit={handleCalculate} />}
        filter={filter}
        filterDefaultValues={defaultFilters}
        // filters={<IncentiveFilter defaultValues={{ defaultEnrollmentDate }} />}
        filters={
          <IncentiveFilter
            timezone={userDefaultTimezone}
            defaultValues={{
              ...defaultFilters,
              enrollmentDate: defaultEnrollmentDate,
            }}
          />
        }
        pagination={<IncentivePagination />}
        perPage={10}
        sort={{ field: 'enrollmentDate', order: 'desc' }}
      >
        <Datagrid size="medium">
          <InfoPopover label={translate('resources.incentives.fields.info')}>
            <ActionableInfoField
              label={translate(
                'resources.incentives.fields.agreementRequestId'
              )}
              source="agreementRequestId"
              copy={true}
            />
            {isFinanceUser(identity) && (
              <ActionableInfoField
                label="Billing Order Number"
                source="billingOrderNumber"
                copy={true}
              />
            )}
            {isFinanceUser(identity) && (
              <ActionableInfoField
                label="Policy Number"
                source="policyNumber"
                copy={true}
              />
            )}
            {isFinanceUser(identity) && (
              <ActionableInfoField
                label="Payout Batch ID"
                source="payoutBatchId"
                copy={true}
                // redirect={isFinanceUser(identity) ? ROUTES.INCENTIVES_CALCULATION.path : ''}
              />
            )}
            {isFinanceUser(identity) && (
              <ActionableInfoField
                label="Incentive ID"
                source="incentiveCalculationId"
                copy={true}
              />
            )}
            {isFinanceUser(identity) && (
              <ActionableInfoField
                label="Invoice ID"
                source="invoiceId"
                copy={true}
              />
            )}
          </InfoPopover>
          <DateField
            source="enrollmentDate"
            locales="sv-se"
            options={dateOptions}
          />
          <DateField
            source="paymentDate"
            locales="sv-se"
            options={dateOptions}
          />
          <TextField source="salesPerson" />
          <TextField source="store" />
          {!isPartnerAdmin(identity) && <TextField source="partnerCode" />}
          <TextField source="tier" />
          <TextField source="plan" />
          <TextField label={statusLabel} source="paymentStatus" />
          {!isPartnerAdmin(identity) && (
            // <TextField source="incentiveStatus" />
            <FunctionField
              label={translate('resources.incentives.fields.incentiveStatus')}
              render={(res: any) =>
                res.incentivePercentage > 0 ? res.incentiveStatus : '-'
              }
              sortable={false}
            />
          )}
          <FunctionField
            textAlign="right"
            label={translate('resources.incentives.fields.subscriptionFee')}
            render={(res: any) => `THB ${res.subscriptionFee}`}
            sortable={false}
          />
          <FunctionField
            textAlign="right"
            label={translate('resources.incentives.fields.discount')}
            render={(res: any) => `THB ${res.discount}`}
            sortable={false}
          />
          <FunctionField
            textAlign="right"
            label={translate(
              'resources.incentives.fields.discountedSubscriptionFee'
            )}
            render={(res: any) => `THB ${res.discountedSubscriptionFee}`}
            sortable={false}
          />
          {!isPartnerAdmin(identity) && (
            <FunctionField
              textAlign="right"
              label={translate('resources.incentives.fields.incentive')}
              render={(res: any) =>
                res.incentivePercentage > 0 ? `THB ${res.incentive}` : '-'
              }
              sortable={false}
            />
          )}
        </Datagrid>
      </List>
      <ConfirmCalculateDialog
        isOpen={showConfirmDialog}
        onOk={handleConfirmCalculate}
        onCancel={() => setShowConfirmDialog(false)}
        identity={identity}
        data={incentiveData}
        filters={incentiveFilters}
        timezone={userDefaultTimezone}
      />
      <WarningPendingDialog
        isOpen={showWarningExistingDialog}
        onOk={() => setShowWarningExistingDialog(false)}
      />
      <WarningNoneViableDialog
        isOpen={showNoneViableDialog}
        onOk={() => setShowNoneViableDialog(false)}
      />
      <DoneCalculationDialog
        isOpen={showDoneCalculationDialog}
        onOk={handleSuccessCalculate}
      />
    </div>
  ) : (
    <Empty />
  );
};

export default IncentiveList;

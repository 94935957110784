import * as React from 'react';
import {
  Button,
  Container,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { useCallback, useState } from 'react';
import { colors } from '../../themes/default';
import axios, { AxiosError } from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { PasswordLock } from '../../components/common/Branding/Icons/PasswordLock';
import { email, required, TextInput, useTranslate } from 'react-admin';
import { useSetTheme } from '../../contexts/ThemeContext';
import { useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ROUTES } from '../../routes';
import mustBeSimilarTo from '../common/Validator/mustBeSimilarTo';
import { createHash } from 'crypto';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      background: colors.extra.page.background,
    },
    body: {
      background: colors.extra.page.background,
    },
  },
  header: {
    fontSize: 32,
    color: theme.palette.common.black,
    width: 'max-content',
  },
  headerDescription: {
    fontSize: 16,
  },
  containerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
    width: 430,
    marginTop: 120,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 430,
    textAlign: 'center',
  },
  buttonRoot: {
    padding: '0 25px',
  },
  otpLabel: {
    textTransform: 'none',
  },
  inputAdornmentRoot: {
    background: 'transparent',
    margin: 0,
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  collapseRoot: {
    width: '100%',
    textAlign: 'center',
    marginTop: 30,
  },
  samlLink: {
    textAlign: 'center',
  },
  alertBox: {
    marginBottom: 30,
    width: '-webkit-fill-available',
  },
  otpInputContainer: {
    display: 'flex',
    gap: 10,
  },
  otpTextField: {},
  otpInput: {
    fontSize: 16,
    padding: 0,
    width: 40,
    height: 40,
    textAlign: 'center',
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 10,
    width: '100%',
  },
  resetField: {
    textAlign: 'left',
  },
}));

const OTP_MAX_LENGTH = 6;
const OTP_FIELD_PREFIX = 'otp';

export const ExternalLoginPage = () => {
  const translate = useTranslate();
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [userEmail, setUserEmail] = useState<string>('');
  const [, setOtp] = useState<string>('');
  const [jwtToken, setJwtToken] = useState<string>('');

  const [error, setError] = useState<string>('');
  const history = useHistory();
  const classes = useStyles();
  const { setNeutralBackground } = useSetTheme();
  const { setHasMenu } = useSetTheme();

  const [showPassword, setShowPassword] = useState<{ [k: string]: boolean }>(
    {}
  );

  const handleClickShowPassword = (key: string) => () =>
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });
  const handleMouseDownPassword = (key: string) => () =>
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });

  const setUp = useCallback(() => {
    setHasMenu(false);
    setNeutralBackground(true);
  }, [setHasMenu, setNeutralBackground]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  const handleOtpRequest = async ({ userEmail: em }: { userEmail: any }) => {
    setLoading(true);
    setError('');
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SECURITY_API_URL}/security/otp`,
        {
          params: {
            em,
          },
        }
      );
      if (result.status === 200) {
        setJwtToken(result.data);
        setUserEmail(em);
        return;
      }
      throw Error('There was an error with your request.');
    } catch (e) {
      const errorCode = (e as AxiosError).response?.status ?? 500;
      if (500 < errorCode || errorCode >= 600) {
        setError(translate('asurion.common.error.generic'));
      }
    } finally {
      setStep(1);
      setLoading(false);
    }
  };

  const handleVerifyOtpRequest = async (values: any) => {
    setLoading(true);
    setError('');
    try {
      const otpSequence = Object.entries(values)
        .filter(([key]) => {
          const [fieldName] = key.split('-');
          return fieldName === OTP_FIELD_PREFIX;
        })
        .map(([, value]) => value)
        .join('');

      const result = await axios.post(
        `${process.env.REACT_APP_SECURITY_API_URL}/security/otp`,
        {
          otp: otpSequence,
          simple: 1,
        },
        {
          params: {
            em: userEmail,
          },
        }
      );
      if (result.status === 200) {
        setOtp(otpSequence);
        setStep(2);
        return;
      }
      throw Error('There was an error verifying your OTP');
    } catch (error) {
      if ((error as any).response.status === 401) {
        setError(translate('ra.message.invalid_otp'));
        return;
      }
      setError(translate('asurion.common.error.generic'));
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (values: any) => {
    setLoading(true);
    setError('');
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_SECURITY_API_URL}/security/password`,
        {
          em: userEmail,
          sp: createHash('sha256').update(values.newPassword).digest('hex'),
        },
        {
          params: {
            token: jwtToken,
          },
        }
      );
      if (result.status === 200) {
        history.push(ROUTES.SIGN_IN.path);
      }
    } catch (e) {
      console.log('Error', e);
      setError(translate('asurion.common.error.generic'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fixed classes={{ root: classes.containerRoot }}>
      {step === 0 && (
        <>
          <PasswordLock />
          <Typography
            className={classes.header}
            variant="h3"
            component="h1"
            align="center"
          >
            {translate('asurion.reset_password_funnel.0.title')}
            <p className={classes.headerDescription}>
              {translate('asurion.reset_password_funnel.0.description')}
            </p>
          </Typography>
          <Form
            onSubmit={handleOtpRequest}
            render={({ handleSubmit }) => (
              <form
                className={classes.formRoot}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {error && (
                  <Alert severity="error" className={classes.alertBox}>
                    {error}
                  </Alert>
                )}
                <TextInput
                  label="asurion.common.form.email.label"
                  placeholder={translate(
                    'asurion.common.form.email.placeholder'
                  )}
                  source="userEmail"
                  type="email"
                  fullWidth
                  disabled={loading}
                  validate={[required(), email()]}
                  variant="outlined"
                />
                <Button
                  classes={{
                    root: classes.buttonRoot,
                    label: classes.otpLabel,
                  }}
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {translate(
                    'asurion.reset_password_funnel.0.form.send_otp.label'
                  )}
                </Button>
              </form>
            )}
          />
        </>
      )}

      {step === 1 && (
        <>
          <PasswordLock />
          <Typography
            className={classes.header}
            variant="h3"
            component="h1"
            align="center"
          >
            {translate('asurion.reset_password_funnel.1.title')}
            <p className={classes.headerDescription}>
              {translate('asurion.reset_password_funnel.1.description')}
            </p>
          </Typography>
          <Form
            onSubmit={handleVerifyOtpRequest}
            render={({ handleSubmit, values }) => {
              const handleChange = (e: any) => {
                const { maxLength, value, name } = e.target;
                const [, fieldIndex] = name.split('-');

                if (value.length >= maxLength) {
                  if (fieldIndex < OTP_MAX_LENGTH) {
                    const nextSibling = document.querySelector(
                      `[name="${OTP_FIELD_PREFIX}-${Number(fieldIndex) + 1}"]`
                    );
                    if (nextSibling !== null) {
                      (nextSibling as HTMLElement)?.focus();
                    }
                  }
                }
              };
              return (
                <form
                  className={classes.formRoot}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  {error && (
                    <Alert severity="error" className={classes.alertBox}>
                      {error}
                    </Alert>
                  )}
                  <div className={classes.otpInputContainer}>
                    {[...Array(OTP_MAX_LENGTH)].map((_, i) => {
                      return (
                        <TextInput
                          key={`${OTP_FIELD_PREFIX}-${i}`}
                          label=""
                          name={`${OTP_FIELD_PREFIX}-${i}`}
                          source={`otp[${i}]`}
                          variant="outlined"
                          className={classes.otpTextField}
                          inputProps={{
                            maxLength: 1,
                            className: classes.otpInput,
                          }}
                          onChange={handleChange}
                        />
                      );
                    })}
                  </div>

                  <p>
                    {translate(
                      'asurion.reset_password_funnel.1.otp_expiration_helper'
                    )}
                    <br />
                    <strong>
                      {translate(
                        'asurion.reset_password_funnel.1.otp_expiration',
                        { minutes: 5 }
                      )}
                    </strong>
                  </p>

                  <p>
                    {translate('asurion.reset_password_funnel.1.resend_helper')}
                    <br />
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOtpRequest({ userEmail });
                      }}
                      variant="text"
                      disableRipple
                    >
                      {translate(
                        'asurion.reset_password_funnel.1.resend_label'
                      )}
                    </Button>
                  </p>

                  <Button
                    classes={{ root: classes.buttonRoot }}
                    type="submit"
                    color="primary"
                    disabled={loading}
                  >
                    {translate('asurion.common.action.verify')}
                  </Button>
                </form>
              );
            }}
          />
        </>
      )}

      {step === 2 && (
        <>
          <Typography
            className={classes.header}
            variant="h3"
            component="h1"
            align="center"
          >
            {translate('asurion.reset_password_funnel.2.title')}
          </Typography>
          <Form
            onSubmit={handleResetPassword}
            render={({ handleSubmit }) => (
              <form
                className={classes.formRoot}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {error && (
                  <Alert severity="error" className={classes.alertBox}>
                    {error}
                  </Alert>
                )}

                <TextInput
                  source="newPassword"
                  label="asurion.profile.change_password.form.new_password.label"
                  placeholder={translate(
                    'asurion.profile.change_password.form.new_password.placeholder'
                  )}
                  type={showPassword?.newPassword ? 'text' : 'password'}
                  classes={{ root: classes.resetField }}
                  variant="outlined"
                  validate={[required()]}
                  fullWidth
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          classes={{
                            root: classes.inputAdornmentRoot,
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword('newPassword')}
                          onMouseDown={handleMouseDownPassword('newPassword')}
                          disableRipple
                        >
                          {showPassword?.newPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextInput
                  source="confirmNewPassword"
                  label="asurion.profile.change_password.form.confirm_new_password.label"
                  placeholder={translate(
                    'asurion.profile.change_password.form.confirm_new_password.placeholder'
                  )}
                  type={showPassword?.confirmNewPassword ? 'text' : 'password'}
                  classes={{ root: classes.resetField }}
                  variant="outlined"
                  validate={[
                    required(),
                    mustBeSimilarTo('newPassword', {
                      targetField: 'New Password',
                    }),
                  ]}
                  fullWidth
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          classes={{
                            root: classes.inputAdornmentRoot,
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword(
                            'confirmNewPassword'
                          )}
                          onMouseDown={handleMouseDownPassword(
                            'confirmNewPassword'
                          )}
                          disableRipple
                        >
                          {showPassword?.confirmNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className={classes.controls}>
                  <Button
                    classes={{ root: classes.buttonRoot }}
                    disabled={loading}
                    component={Link}
                    to={ROUTES.SIGN_IN.path}
                  >
                    {translate('ra.action.cancel')}
                  </Button>
                  <Button
                    classes={{ root: classes.buttonRoot }}
                    type="submit"
                    color="primary"
                    disabled={loading}
                  >
                    {translate('ra.action.save')}
                  </Button>
                </div>
              </form>
            )}
          />
        </>
      )}
    </Container>
  );
};

export default ExternalLoginPage;

import * as React from 'react';
import { Button, Container, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Form } from 'react-final-form';
import {
  required,
  TextInput,
  useAuthenticated,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { useProfile } from '../../contexts/ProfileContext';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes';
import axios from 'axios';
import Field from '../common/Scaffolding/Field';
import { useSalesPartner } from '../../common/hooks';

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    marginTop: 120,
  },
  title: {
    fontSize: 20,
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: 20,
    color: theme.palette.common.black,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 10,
    width: '100%',
  },
  extraControls: {
    width: '100%',
    margin: '25px 0',
  },
  extraControlsButton: {
    padding: '0 25px',
  },
  buttonRoot: {
    width: 90,
  },
}));

export const ProfileEdit = () => {
  useAuthenticated({ allowed: 'view_own_profile' });
  const [saving, setSaving] = useState<boolean>(false);
  const { identity } = useGetIdentity();
  const { refreshProfile } = useProfile();
  const history = useHistory();
  const classes = useStyles();
  const translate = useTranslate();
  const { partner } = useSalesPartner();

  const handleSave = async (values: any) => {
    setSaving(true);
    try {
      console.log(values);
      const result = await axios.put(
        `${process.env.REACT_APP_ADMIN_API_URL}/profile`,
        { ...values }
      );
      if (result.status === 200) {
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...identity,
            firstName: values.firstName,
            lastName: values.lastName,
          })
        );
      }
      refreshProfile();
      history.push(ROUTES.PROFILE_VIEW.path);
    } catch (e) {
      // Do something
    } finally {
      setSaving(false);
    }
  };

  return (
    <Container maxWidth="sm" classes={{ root: classes.containerRoot }}>
      <Typography
        className={classes.title}
        variant="h3"
        component="h1"
        align="center"
      >
        {translate('asurion.profile.edit.title')}
      </Typography>
      <Form
        initialValues={{ ...identity }}
        onSubmit={handleSave}
        render={({ handleSubmit }) => (
          <form
            className={classes.formRoot}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextInput
              label="asurion.common.form.first_name.label"
              placeholder={translate('asurion.common.form.first_name.label')}
              source="firstName"
              fullWidth
              disabled={saving}
              validate={[required()]}
              variant="outlined"
            />
            <TextInput
              label="asurion.common.form.last_name.label"
              placeholder={translate('asurion.common.form.last_name.label')}
              source="lastName"
              fullWidth
              disabled={saving}
              validate={[required()]}
              variant="outlined"
            />
            <TextInput
              disabled
              label={translate('asurion.profile.view.partnerCode')}
              source="partnerCode"
              fullWidth
              format={() => partner?.partnerCode}
              variant="outlined"
            />
            <TextInput
              label="asurion.common.form.email.label"
              placeholder={translate('asurion.common.form.email.placeholder')}
              source="userEmail"
              type="email"
              fullWidth
              disabled={true}
              variant="outlined"
            />
            <div className={classes.controls}>
              <Button
                classes={{ root: classes.buttonRoot }}
                disabled={saving}
                component={Link}
                to={ROUTES.PROFILE_VIEW.path}
              >
                {translate('ra.action.cancel')}
              </Button>
              <Button
                classes={{ root: classes.buttonRoot }}
                type="submit"
                color="primary"
                disabled={saving}
              >
                {translate('ra.action.save')}
              </Button>
            </div>

            <div className={classes.extraControls}>
              <Field label={translate('asurion.common.form.password.label')}>
                <Button
                  classes={{ root: classes.extraControlsButton }}
                  color="primary"
                  component={Link}
                  to={ROUTES.CHANGE_PASSWORD.path}
                >
                  {translate('asurion.common.form.password.change_password')}
                </Button>
              </Field>
            </div>
          </form>
        )}
      />
    </Container>
  );
};

export default ProfileEdit;

import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useEffect, useState } from 'react';
import {
  DEFAULT_EMBED_CONFIG,
  getEmbedConfig,
} from '../../services/reportService';
import { REPORT_ROLES } from '../../common/constants';
import { useLocation } from 'react-router-dom';

export interface EmbedConfiguration {
  username: string;
  reportId: string;
  role?: string;
  noEffectiveIdentities?: 1 | 0;
  layout?: models.LayoutType;
  filterPaneEnabled?: boolean;
}

const useStyles = makeStyles({
  '@global': {
    header: {
      '&.MuiAppBar-root': {
        display: 'none',
      },
    },
  },
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    '& iframe': {
      border: 'none',
    },
  },
});

export const Report = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search.substr(1));
  const [embedConfig, setEmbedConfig] =
    useState<models.IReportEmbedConfiguration>(DEFAULT_EMBED_CONFIG);
  const [version, setVersion] = useState<number>(0);

  const getPayloadData = (key: string, defaultValue: string = '') => {
    return searchParams.get(key) || defaultValue;
  };

  useEffect(() => {
    const run = async () => {
      try {
        const data = await getEmbedConfig({
          ...embedConfig,
          username: getPayloadData('username'),
          reportId: getPayloadData('reportId'),
          role: getPayloadData('role') || REPORT_ROLES.ALL,
          extraParams: {
            ...(getPayloadData('noEffectiveIdentities')
              ? { noEffectiveIdentities: 1 }
              : {}),
            ...(getPayloadData('workspaceId')
              ? { workspaceId: getPayloadData('workspaceId') }
              : {}),
          },
        });
        if (data) {
          setEmbedConfig({
            ...data,
            settings: {
              layoutType: parseInt(
                getPayloadData(
                  'layoutType',
                  models.LayoutType.MobilePortrait.toString()
                )
              ),
              filterPaneEnabled: Boolean(getPayloadData('filterPaneEnabled')),
            },
          });
          setVersion(version + 1);
        }
      } catch (e) {
        // Do something
      }
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PowerBIEmbed
        key={version}
        embedConfig={embedConfig}
        cssClassName={classes.container}
      />
    </>
  );
};

export default Report;

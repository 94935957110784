import * as React from 'react';
import { Form } from 'react-final-form';
import { Button, Container, Typography, makeStyles } from '@material-ui/core';
import { PartnerAccountIcon } from '../common/Branding/Icons/PartnerAccountIcon';
import { useHistory } from 'react-router-dom';
import { useSalesPartnerId } from '../../contexts/SalesPartnerContext';
import {
  ReferenceInput,
  required,
  SelectInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { useCountry } from '../../contexts/CountryContext';
import { DEFAULT_COUNTRY_LIST } from '../common/Form/Collection/CountrySelector';
import { useSetTheme } from '../../contexts/ThemeContext';
import { useCallback, useEffect } from 'react';

const useStyles = makeStyles({
  header: {
    fontSize: 32,
  },
  containerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
    width: 430,
    marginTop: 120,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 430,
  },
  buttonRoot: {
    width: 120,
  },
});

export const SalesPartnerSelect = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setCountry } = useCountry();
  const { identity, loaded } = useGetIdentity();
  const { setSalesPartnerId } = useSalesPartnerId();
  const { setHasMenu } = useSetTheme();
  const translate = useTranslate();

  const setUp = useCallback(() => {
    setHasMenu(false);
  }, [setHasMenu]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  const onSubmit = (value: any) => {
    const country = DEFAULT_COUNTRY_LIST.find(
      (country) => country.id === identity?.extra.countryId
    );
    setCountry(country);
    setSalesPartnerId(value.salesPartnerId);
    setHasMenu(true);
    history.push({ pathname: '/' });
  };

  return loaded ? (
    <>
      <Container fixed classes={{ root: classes.containerRoot }}>
        <PartnerAccountIcon />
        <Typography
          className={classes.header}
          variant="h3"
          component="h1"
          align="center"
        >
          Select Partner
        </Typography>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form
              className={classes.formRoot}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <ReferenceInput
                source="salesPartnerId"
                label=""
                reference="sales-partners"
                fullWidth
                validate={required()}
                filter={{
                  countryId: identity?.extra.countryId,
                  activeIndStatus: 'active',
                }}
                variant="outlined"
              >
                <SelectInput optionText="partnerName" />
              </ReferenceInput>
              <Button
                classes={{ root: classes.buttonRoot }}
                type="submit"
                color="primary"
              >
                {translate('asurion.common.navigation.done')}
              </Button>
            </form>
          )}
        />
      </Container>
    </>
  ) : null;
};

export default SalesPartnerSelect;

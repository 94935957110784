import React from 'react';
import { Create, CreateProps, required, TextInput } from 'react-admin';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import CountrySelector from '../common/Form/Collection/CountrySelector';
import EmailInput from '../common/Form/Input/EmailInput';

export const CountryManagerCreate: React.FC<CreateProps> = (props) => {
  const transform = (data: any) => ({
    ...data,
    created_at: Date.now(),
    status: 'Pending',
  });

  return (
    <Create {...props} {...transform}>
      <AsurionForm title="New Asurion Country Manager" redirect="list">
        <TextInput
          label="First Name"
          placeholder="e.g. John"
          source="firstName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          label="Last Name"
          placeholder="e.g. Smith"
          source="lastName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          label="Nickname"
          placeholder="e.g. John"
          source="nickname"
          fullWidth
        />
        <EmailInput source="userEmail" className="required" />
        <ContactNumber source="contactNumber" className="required" />
        <CountrySelector
          source="countryId"
          label="Country"
          className="required"
        />
      </AsurionForm>
    </Create>
  );
};

export default CountryManagerCreate;

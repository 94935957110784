import * as React from 'react';
import {
  required,
  SelectInput,
  SelectInputProps,
  useGetIdentity,
} from 'react-admin';
import { useCountryId } from '../../../../contexts/CountryContext';

export const DEFAULT_SOURCE = 'country';
export const DEFAULT_COUNTRY_LIST: Array<{ id: string; name: string }> = [
  { id: 'tha', name: 'Thailand' },
  { id: 'phl', name: 'Philippines' },
  { id: 'twn', name: 'Taiwan' },
  { id: 'mys', name: 'Malaysia' },
  { id: 'kor', name: 'South Korea' },
  { id: 'jpn', name: 'Japan' },
];

export const CountrySelector = ({
  source = DEFAULT_SOURCE,
  choices = DEFAULT_COUNTRY_LIST,
  options = {
    placeholder: 'Select Country',
  },
  validate = [required()],
  variant = 'outlined',
  disabled = true,
  ...restProps
}: SelectInputProps) => {
  const { countryId: country } = useCountryId();
  const { identity } = useGetIdentity();

  if (!country && !identity?.extra.countryId) {
    disabled = false;
  }
  return (
    <SelectInput
      initialValue={country || identity?.extra.countryId}
      {...{
        source,
        choices,
        options,
        validate,
        variant,
        disabled,
      }}
      fullWidth
      variant="outlined"
      {...restProps}
    />
  );
};

export default CountrySelector;

import { createContext, useContext, useEffect, useState } from 'react';
import { COUNTRY_KEY } from '../common/constants';

export interface CountryContextProps {
  country?: {
    id: string;
    name: string;
    defaultLanguage?: string;
    maxStoresPerManager?: number;
  };
  setCountry?: any;
  children?: React.ReactNode;
}

const defaultCountry = JSON.parse(localStorage.getItem(COUNTRY_KEY) || '{}');

const CountryContext = createContext<CountryContextProps>({
  country: defaultCountry,
});

export const CountryProvider = ({
  country: inputCountry = defaultCountry,
  children,
}: CountryContextProps) => {
  const [country, setCountry] = useState(inputCountry);
  useEffect(() => {
    localStorage.setItem(
      COUNTRY_KEY,
      JSON.stringify(country || '{ "id": "" }')
    );
  }, [country]);

  return (
    <CountryContext.Provider value={{ country, setCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => useContext(CountryContext);
export const useCountryId = () => {
  const { country } = useContext(CountryContext);
  return {
    countryId: country?.id || null,
  };
};

export const useCountryConfig = () => {
  const { country } = useContext(CountryContext);
  let maxStoresPerManager = country?.maxStoresPerManager ?? 3;

  return {
    hasBusinessPartner: ['phl', 'twn'].includes(country?.id || ''),
    MAX_STORES_PER_MANAGER: maxStoresPerManager,
  };
};

import { createContext, useContext, useEffect, useState } from 'react';
import { SALES_PARTNER_ID } from '../common/constants';

export interface SalesPartnerContextProps {
  salesPartnerId?: string;
  setSalesPartnerId?: any;
  children?: React.ReactNode;
}

const defaultSalesPartnerId = localStorage.getItem(SALES_PARTNER_ID) || '';

const SalesPartnerContext = createContext<SalesPartnerContextProps>({
  salesPartnerId: defaultSalesPartnerId,
});

export const SalesPartnerProvider = ({
  salesPartnerId: inputSalesPartnerId = defaultSalesPartnerId,
  children,
}: SalesPartnerContextProps) => {
  const [salesPartnerId, setSalesPartnerId] = useState<string>(
    inputSalesPartnerId
  );
  useEffect(() => {
    localStorage.setItem(SALES_PARTNER_ID, salesPartnerId);
  }, [salesPartnerId]);

  return (
    <SalesPartnerContext.Provider value={{ salesPartnerId, setSalesPartnerId }}>
      {children}
    </SalesPartnerContext.Provider>
  );
};

export const useSalesPartnerId = () => useContext(SalesPartnerContext);

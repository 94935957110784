import { TranslationMessages } from 'react-admin';

export const englishMessages: TranslationMessages = {
  ra: {
    action: {
      add_filter: 'Add filter',
      add: 'Add',
      back: 'Go Back',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      cancel: 'Cancel',
      clear_input_value: 'Clear value',
      clone: 'Clone',
      confirm: 'Confirm',
      create: 'Add',
      create_item: 'Create %{item}',
      delete: 'Delete',
      edit: 'Edit',
      export: 'Export',
      list: 'List',
      refresh: 'Refresh',
      remove_filter: 'Remove this filter',
      remove: 'Remove',
      save: 'Save',
      search: 'Search',
      show: 'Show',
      sort: 'Sort',
      undo: 'Undo',
      unselect: 'Unselect',
      expand: 'Expand',
      close: 'Close',
      open_menu: 'Open menu',
      close_menu: 'Close menu',
      update: 'Update',
      move_up: 'Move up',
      move_down: 'Move down',
      select_all: 'Select All',
      select_row: 'Select Row',
    },
    boolean: {
      true: 'Yes',
      false: 'No',
      null: ' ',
    },
    page: {
      create: 'Create %{name}',
      dashboard: 'Dashboard',
      edit: '%{name} #%{id}',
      error: 'Something went wrong',
      list: '%{name}',
      loading: 'Loading',
      not_found: 'Not Found',
      show: '%{name} #%{id}',
      empty: 'No %{name} yet.',
      invite: 'Do you want to add one?',
    },
    input: {
      file: {
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.',
      },
      image: {
        upload_several: 'Drop some pictures to upload, or click to select one.',
        upload_single: 'Drop a picture to upload, or click to select it.',
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing:
          'At least one of the associated references no longer appears to be available.',
        single_missing:
          'Associated reference no longer appears to be available.',
      },
      password: {
        toggle_visible: 'Hide password',
        toggle_hidden: 'Show password',
      },
    },
    message: {
      about: 'About',
      are_you_sure: 'Are you sure?',
      bulk_delete_content:
        'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
      bulk_delete_title: 'Delete %{name} |||| Delete %{smart_count} %{name}',
      bulk_update_content:
        'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?',
      bulk_update_title: 'Update %{name} |||| Update %{smart_count} %{name}',
      delete_content: 'Are you sure you want to delete this item?',
      delete_title: 'Delete %{name} #%{id}',
      details: 'Details',
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: 'The form is not valid. Please check for errors',
      loading: 'The page is loading, just a moment please',
      no: 'No',
      not_found: 'Either you typed a wrong URL, or you followed a bad link.',
      yes: 'Yes',
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?",
      invalid_otp: 'Incorrect OTP',
    },
    navigation: {
      no_results: 'No results found',
      no_more_results:
        'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
      page_rows_per_page: 'Rows per page:',
      next: 'Next',
      prev: 'Prev',
      skip_nav: 'Skip to content',
    },
    sort: {
      sort_by: 'Sort by %{field} %{order}',
      ASC: 'ascending',
      DESC: 'descending',
    },
    auth: {
      auth_check_error: 'Please login to continue',
      user_menu: 'Profile',
      username: 'Username',
      password: 'Password',
      sign_in: 'Sign in',
      sign_in_error: 'Authentication failed, please retry',
      logout: 'Logout',
    },
    notification: {
      updated: 'Element updated |||| %{smart_count} elements updated',
      created: 'Element created',
      deleted: 'Element deleted |||| %{smart_count} elements deleted',
      bad_item: 'Incorrect element',
      item_doesnt_exist: 'Element does not exist',
      http_error: 'Server communication error',
      data_provider_error: 'dataProvider error. Check the console for details.',
      i18n_error: 'Cannot load the translations for the specified language',
      canceled: 'Action cancelled',
      logged_out: 'Your session has ended, please reconnect.',
      not_authorized: "You're not authorized to access this resource.",
    },
    validation: {
      required: 'Required',
      minLength: 'Must be %{min} characters at least',
      maxLength: 'Must be %{max} characters or less',
      minValue: 'Must be at least %{min}',
      maxValue: 'Must be %{max} or less',
      number: 'Must be a number',
      email: 'Must be a valid email',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}',
    },
  },
  resources: {
    reports: {
      download: {
        complete: 'Report downloaded',
        error: 'Error downloading report',
      },
    },
    'partner-admins': {
      name: 'Partner Admin |||| Partner Admins',
      fields: {
        name: 'Name',
        userEmail: 'Email Address',
        firstName: 'First name',
        lastName: 'Last name',
        nickname: 'Nickname',
        contactNumber: 'Contact Number',
        createdDate: 'Date Created',
        partnerAccount: 'Partner Account',
      },
      new_title: 'New Partner Admin',
      edit_title: 'Edit Partner Admin',
    },
    'business-partners': {
      name: 'Business Partner |||| Business Partners',
      fields: {
        name: 'Name',
        createdDate: 'Date Created',
      },
      new_title: 'New Business Partner Admin',
      edit_title: 'Edit Business Partner Admin',
    },
    'business-partner-admins': {
      name: 'Business Partner Admin |||| Business Partner Admins',
      fields: {
        name: 'Name',
        userEmail: 'Email Address',
        firstName: 'First name',
        lastName: 'Last name',
        nickname: 'Nickname',
        contactNumber: 'Contact Number',
        createdDate: 'Date Created',
      },
      new_title: 'New Business Partner Admin',
      edit_title: 'Edit Business Partner Admin',
    },
    'partner-stores': {
      name: 'Store |||| Stores',
      fields: {
        title: '',
        userEmail: 'Email Address',
        partnerStoreName: 'Store Name',
        partnerStoreName_alt: 'Store',
        contactNumber1: 'Contact Number',
        contactNumber1_alt: 'Store Contact Number',
        branchName: 'Branch',
        address: 'Address',
        contactPerson1: 'Contact Person',
        contactPerson1_alt: 'Primary Contact Person Name',
        email1: 'Email Address',
        email1_alt: 'Primary Contact Person Email Address',
        contactNumber1_alt2: 'Primary Contact Number',
        contactPerson2: 'Secondary Contact Person Name',
        email2: 'Secondary Contact Person Email Address',
        contactNumber2: 'Secondary Contact Number',
        createdDate: 'Date Created',
      },
      contactPerson1_search: 'Search Contact Name',
      partnerStoreName_search: 'Search Store Name',
      branchName_search: 'Search Branch',
      branchName_placeholder: 'Branch Name',
      new_title: 'New Store',
      edit_title: 'Edit Store Details',
    },
    'store-managers': {
      name: 'Store Manager |||| Store Managers',
      fields: {
        name: 'Name',
        userEmail: 'Email Address',
        title: 'Title',
        firstName: 'First name',
        lastName: 'Last name',
        nickname: 'Nickname',
        contactNumber: 'Mobile Number',
        partnerStores: 'Assign to Store',
        partnerStores_alt: 'Store Name',
        salesPartnerId: 'Partner Account',
        altMdn: 'Alternative Mobile Number',
        createdDate: 'Date Created',
        salesRepInfo: {
          lineId: 'Line ID (Optional)',
        },
      },
      new_title: 'New Asurion Store Manager',
      edit_title: 'Edit User',
    },
    'sales-representatives': {
      name: 'Sales Representative |||| Sales Representatives',
      fields: {
        name: 'Name',
        userEmail: 'Email Address',
        title: 'Title',
        contactNumber: 'Mobile Number',
        salesPartnerStoreId: 'Store',
        salesPartnerStoreId_alt: 'Assign to Store',
        store: 'Store',
        role: 'Role',
        firstName: 'First name',
        lastName: 'Last name',
        nickname: 'Nickname',
        altMdn: 'Alternative Contact Number',
        createdDate: 'Date Created',

        bankDetails: {
          firstName: 'First Name (As appears in bank account)',
          lastName: 'Last Name (As appears in bank account)',
          bankNameId: 'Bank Name',
          bankBranchId: 'Bank Branch',
          accountNumber: 'Bank Account Number',
          swiftCode: 'Swift Code',
          accountTypeId: 'Account Type',
        },

        salesRepInfo: {
          lineId: 'Line ID (Optional)',
        },
      },
      new_title: 'New Asurion Sales Representative',
      edit_title: 'Edit Sales Representatives',
      store_search: 'Search Store Name',
    },
    incentives: {
      name: 'Incentive |||| Incentives',
      fields: {
        info: 'Info',
        enrollmentDate: 'Enrollment Date',
        paymentDate: 'Payment Date',
        salesPerson: 'Sales Person',
        store: 'Store',
        partnerCode: 'Partner Code',
        tier: 'Tier',
        plan: 'Plan',
        paymentStatus: 'Payment Status',
        incentiveStatus: 'Incentive Status',
        subscriptionFee: 'Subscription Fee',
        discount: 'Discount',
        discountedSubscriptionFee: 'Discounted Subscription Fee',
        incentive: 'Incentive',
        agreementRequestId: 'Agreement Request ID',
      },
    },
    'sales-partners': {
      fields: {
        partnerName: 'Company Name',
        partnerCode: 'Partner Code',
        salesManagers: 'Asurion Sales Manager',
      },
      fieldsets: {
        partnerAccountDetails: 'Partner Account Details',
        address: 'Address',
        contactDetails: 'Contact Details',
      },
      placeholders: {
        partnerName: 'Company Name',
        salesManagers: 'Search Name',
      },
      new_title: 'New Partner Account',
      edit_title: 'Edit Partner Account',
    },
  },
  asurion: {
    login: {
      title: 'Account Login',
      forgot_password_helper: 'Need help with your password?',
      login_transition: 'Logging In...',
    },
    profile: {
      activation: {
        success: {
          0: 'Your account has been activated. Click',
          1: 'here',
          2: 'to login',
        },
        error: 'There was an error activating your account.',
      },
      set_password: {
        title: 'Create Password',
        set_password_helper:
          'You must create a password to access your account.',
        form: {
          fields: {
            password: 'Password',
            confirm_password: 'Confirm Password',
            set_password: 'Set Password',
          },
          placeholder: {
            password: 'Enter Password',
            confirm_password: 'Confirm Enter Password',
          },
        },
      },
      change_password: {
        title: 'Change Password',
        form: {
          change_password: {
            label: 'Current Password',
            placeholder: 'Enter Current Password',
          },
          new_password: {
            label: 'Confirm Password',
            placeholder: 'Enter New Password',
          },
          confirm_new_password: {
            label: 'Confirm New Password',
            placeholder: 'Confirm New Password',
          },
        },
      },
      view: {
        title: 'My Profile',
        partnerCode: 'Partner Code',
      },
      edit: {
        title: 'Edit My Profile',
      },
    },
    reset_password_funnel: {
      0: {
        title: 'Reset your password',
        description: `Enter the email associated with your account and we'll send an email with an OTP to reset your password.`,
        form: {
          send_otp: {
            label: 'Send OTP to setup new password',
          },
        },
      },
      1: {
        title: 'Enter Verification Code',
        description: `We have sent a verification code to your registered email ID`,
        otp_expiration_helper: 'For your protection, your code will expire in',
        otp_expiration: '%{minutes} minutes.',
        resend_helper: 'I didn’t receive a code',
        resend_label: 'Resend Codes',
      },
      2: {
        title: 'Change Password',
      },
    },
    common: {
      action: {
        add_resource: 'Add %{resource}',
        verify: 'Submit',
        submit: 'Submit',
        validating: 'Validating',
      },
      error: {
        generic: 'Error contacting the server.',
        must_be_similar_to: 'Field must match %{targetField}',
      },
      table: {
        fields: {
          action: 'Action',
          status: 'Status',
        },
      },
      navigation: {
        back: 'Back',
        done: 'Finish',
        done_alt: 'Done',
        menus: {
          management: 'Management',
          partner_admin: 'Partner Admin',
          partner_account: 'Partner Account',
          store_account: 'Store Account',
          store_manager: 'Store Manager',
          sales_representative: 'Sales Representative',
          sales_performance: 'Sales Performance',
          bulletin: 'Bulletin',
          incentives: 'Incentives',
          partner_sales: 'Partner Sales',
          programs: 'Programs',
          phone_make: 'Phone Make',
          business_partner: 'Business Partner',
          business_partner_admin: 'Business Partner Admin',
        },
      },
      dialog: {
        active: {
          title: 'Confirm user ACTIVATION',
          message:
            'By clicking Confirm, user will have access to its role permissions.',
          confirm: 'Would you like to continue?',
        },
        deactivate: {
          title: 'Confirm User Deactivation',
          message:
            'By clicking "Confirm", user will no longer have access to its role permissions.',
          confirm: 'Would you like to continue?',
        },
        generic_confirm: {
          message:
            'By clicking Confirm, you are confirming that all the details entered are correct',
          confirm: 'Would you like to continue?',
        },
        done: {
          title: 'All Done!',
          message: '%{entityName} has been added',
        },
      },
      form: {
        active: 'Active',
        inactive: 'Inactive',
        pending: 'Pending',
        activation_link: 'Resend Account Setup Link',
        status: 'Status',
        new_title: 'New %{resource}',
        cancel: 'Cancel',
        sections: {
          store_details: 'Store Details',
          address: 'Address',
          contact_details: 'Contact Details',
          sales_rep_details: 'Sales Rep Details',
          bank_details: 'Bank Details',
        },
        actions: {
          clear: 'Clear',
          filter: 'Filter',
          download: 'Download',
        },
        email: {
          label: 'Email Address',
          placeholder: 'Enter Email Address',
          search_placeholder: 'Search Email Address',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter Password',
          change_password: 'Change Password',
        },
        login: {
          label: 'Login',
        },
        full_name: {
          label: 'Name',
          search_placeholder: 'Search Name',
        },
        first_name: {
          label: 'First Name',
        },
        last_name: {
          label: 'Last Name',
        },
        store: {
          label: 'Store Name',
          placeholder: 'e.g. Public Company Limited',
          search_placeholder: 'Search Store Name',
        },
        contact_number: {
          label: 'Mobile number',
          validation: {
            already_exists: 'Mobile Number already used',
          },
        },
        partner_account: {
          label: 'Partner Account Name',
          placeholder: 'Search Partner Account',
        },
        altMdn: {
          label: 'Alternative Mobile Number (Optional)',
        },
        lineId: {
          label: 'Line ID (Optional)',
        },
        enrollment_date: {
          label: 'Enrollment Date',
        },
        payment_date: {
          label: 'Payment Date',
        },
        sales_person: {
          label: 'Sales Person',
        },
        store_alt: {
          label: 'Store',
        },
        incentive_status: {
          label: 'Incentive Status',
        },
        plan: {
          label: 'Plan',
        },
        collection: {
          title: {
            mister: 'Mr.',
            miss: 'Miss',
            missus: 'Mrs.',
          },
          address: {
            country: {
              label: 'Country',
            },
            businessName: {
              label: 'Company Name (Optional)',
              placeholder: 'e.g. ABC Company',
            },
            building: {
              label: 'Name of Building',
              placeholder: 'Name of Building',
            },
            roomNo: {
              label: 'Room No.',
            },
            houseNo: {
              label: 'House No.',
            },
            village: {
              label: 'Village',
              placeholder: 'Village',
            },
            alley: {
              label: 'Alley / alley',
              placeholder: 'Alley / alley',
            },
            street: {
              label: 'Street',
            },
            district: {
              label: 'District / District',
              placeholder: 'District / District',
            },
            subDistrict: {
              label: 'District / Sub-District',
              placeholder: 'District / Sub-District',
            },
            province: {
              label: 'Province',
            },
            postalCode: {
              label: 'Postal Code',
            },
          },
          user_status: {
            all: 'All',
            active: 'Active',
            inactive: 'Inactive',
            pending: 'Pending',
          },
          plans: {
            all: 'All',
            monthly: 'Monthly',
            '12monthly': 'Term (12)',
            '24monthly': 'Term (24)',
          },
          payment_status: {
            all: 'All',
            pending: 'Pending',
            failed: 'Failed',
            success: 'Completed',
          },
          incentive_status: {
            all: 'All',
            unpaid: 'Unpaid',
            pending: 'Pending',
            processing: 'Processing',
            paid: 'Paid',
          },
          contact_details: {
            directorName: 'Director Name',
            contactPerson1: 'Primary Contact Person Name',
            email1: 'Primary Contact Person Email Address',
            contactNumber1: 'Primary Contact Number',
          },
        },
      },
    },
  },
};

export default englishMessages;

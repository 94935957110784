import BusinessPartnerAdminsList from './BusinessPartnerAdminsList';
import BusinessPartnerAdminsCreate from './BusinessPartnerAdminsCreate';
import BusinessPartnerAdminsEdit from './BusinessPartnerAdminsEdit';

const resourceMapping = {
  list: BusinessPartnerAdminsList,
  create: BusinessPartnerAdminsCreate,
  edit: BusinessPartnerAdminsEdit,
};

export default resourceMapping;

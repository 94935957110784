import { createContext, useContext, useEffect, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import { ROLES } from '../common/types';
import {
  ANONYMOUS_NAV_TREE,
  BUSINESS_PARTNER_ADMIN_NAV_TREE,
  COUNTRY_MGR_NAV_TREE,
  DEFAULT_NAV_TREE,
  FINANCE_MGR_NAV_TREE,
  PARTNER_ADMIN_NAV_TREE,
  SALES_MGR_NAV_TREE,
} from '../routes';

export interface NavigationContextProps {
  navigationTree?: any;
  setNavigationTree?: any;
  children?: React.ReactNode;
}

const defaultNavigationTree = DEFAULT_NAV_TREE;

const NavigationContext = createContext<NavigationContextProps>({
  navigationTree: defaultNavigationTree,
});

export const NavigationProvider = ({
  navigationTree: inputNavigationTree = defaultNavigationTree,
  children,
}: NavigationContextProps) => {
  const [navigationTree, setNavigationTree] = useState(inputNavigationTree);
  const { identity } = useGetIdentity();

  useEffect(() => {
    const generateNavigationTree = () => {
      let currentNavTree;
      const role: ROLES = identity?.role || 'ANONYMOUS';

      switch (role) {
        case 'SYSTEM_ADMIN':
          currentNavTree = DEFAULT_NAV_TREE;
          break;
        case 'COUNTRY_MGR':
          currentNavTree = COUNTRY_MGR_NAV_TREE;
          break;
        case 'FINANCE_MGR':
          currentNavTree = FINANCE_MGR_NAV_TREE;
          break;
        case 'SALES_MGR':
          currentNavTree = SALES_MGR_NAV_TREE;
          break;
        case 'PARTNER_ADMIN':
          currentNavTree = PARTNER_ADMIN_NAV_TREE;
          break;
        case 'BUSINESS_PARTNER_ADMIN':
          currentNavTree = BUSINESS_PARTNER_ADMIN_NAV_TREE;
          break;
        default:
          currentNavTree = ANONYMOUS_NAV_TREE;
          break;
      }
      setNavigationTree(currentNavTree);
    };
    generateNavigationTree();
  }, [identity]);

  return (
    <NavigationContext.Provider value={{ navigationTree, setNavigationTree }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => useContext(NavigationContext);

import * as React from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { useTranslate } from 'ra-core';

export type DoneDialogProps = {
  open: boolean;
  entityName: string;
  onClose?: () => void;
};

const useStyles = makeStyles({
  actions: {
    justifyContent: 'flex-start',
    padding: '0 0 28px 0',
  },
  dialogTitleRoot: {
    marginBottom: 20,
    fontWeight: 'bold',
    justifyContent: 'unset',
    alignItems: 'center',
    fontSize: 20,
    lineHeight: '150%',
    gap: 4,
  },
  dialogContentRoot: {
    padding: 0,
    '& p': {
      marginBottom: '1em',
    },
  },
  dialogPaper: {
    maxWidth: 360,
  },
});

export const DoneDialog = ({
  entityName,
  open = false,
  onClose: handleClose = () => {},
  ...restProps
}: DoneDialogProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      {...restProps}
    >
      <DialogTitle
        disableTypography
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        <DoneIcon />
        {translate('asurion.common.dialog.done.title')}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <Typography>
          {translate('asurion.common.dialog.done.message', { entityName })}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} color="primary">
          {translate('ra.action.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DoneDialog;

import englishMessages from './en';

export const chineseMessages: typeof englishMessages = {
  ra: {
    action: {
      add_filter: '新增篩選',
      add: '新增',
      back: '返回',
      bulk_actions: '已被選取',
      cancel: '取消',
      clear_input_value: '清除',
      clone: '複製',
      confirm: '確認',
      create: '新增',
      create_item: '建立',
      delete: '刪除',
      edit: '編輯',
      export: '匯出',
      list: '分類',
      refresh: '更新',
      remove_filter: '移除篩選',
      remove: '移除',
      save: '儲存',
      search: '搜尋',
      show: '顯示',
      sort: '分類',
      undo: '取消',
      unselect: '取消選取',
      expand: '擴展',
      close: '關閉',
      open_menu: '打開主選單',
      close_menu: '關閉主選單',
      update: '更新',
      move_up: '升級',
      move_down: '降級',
      select_all: 'Select All',
      select_row: 'Select Row',
    },
    boolean: {
      true: '是',
      false: '否',
      null: '',
    },
    page: {
      create: '建立',
      dashboard: '儀錶板',
      edit: '姓名',
      error: '發生錯誤',
      list: '姓名',
      loading: '載入中',
      not_found: '查無資料',
      show: '姓名',
      empty: '姓名',
      invite: '您想要再增添一個新項目嗎',
    },
    input: {
      file: {
        upload_several: '拖曳或點擊要新增的檔案',
        upload_single: '拖曳或點擊要新增的檔案',
      },
      image: {
        upload_several: '拖曳或點擊要新增的圖片',
        upload_single: '拖曳或點擊要新增的圖片',
      },
      references: {
        all_missing: '無法找到參考數據',
        many_missing: '至少有一個相關參考無法使用',
        single_missing: '現無相關參考',
      },
      password: {
        toggle_visible: '隱藏密碼',
        toggle_hidden: '顯示密碼',
      },
    },
    message: {
      about: '關於',
      are_you_sure: '你確定嗎?',
      bulk_delete_content: '您確定您想要刪除此選項?',
      bulk_delete_title: '刪除',
      bulk_update_content: '您確定您想要更新此選項?',
      bulk_update_title: '更新',
      delete_content: '您確定您想要刪除此選項?',
      delete_title: '刪除',
      details: '資訊',
      error: '發生客戶端錯誤，您的請求尚無法完成',
      invalid_form: '表格驗證失效，請重新檢查',
      loading: '網頁載入中，請稍後',
      no: '否',
      not_found: '您輸入或點擊錯誤的連結',
      yes: '是',
      unsaved_changes: '您的變更尚未儲存，您確定要退出嗎?',
      invalid_otp: 'Incorrect OTP',
    },
    navigation: {
      no_results: '查無資料',
      no_more_results: '頁面超出邊界，請嘗試返回上一頁',
      page_out_of_boundaries: '頁面超出邊界',
      page_out_from_end: '無法前往下一頁',
      page_out_from_begin: '無法前往上一頁',
      page_range_info: '',
      page_rows_per_page: '每頁行數',
      next: '下一步',
      prev: '上一步',
      skip_nav: '跳轉至內容',
    },
    sort: {
      sort_by: '依照…分類',
      ASC: '上升',
      DESC: '下降',
    },
    auth: {
      auth_check_error: '請登入並繼續',
      user_menu: '主頁',
      username: '使用者名稱',
      password: '密碼',
      sign_in: '登入',
      sign_in_error: '驗證失敗，請重新嘗試',
      logout: '登出',
    },
    notification: {
      updated: '更新元素',
      created: '建立元素',
      deleted: '刪除元素',
      bad_item: '錯誤元素',
      item_doesnt_exist: '元素並不存在',
      http_error: '伺服器發生錯誤',
      data_provider_error: '數據提供錯誤，請檢查後台以獲取更多詳細資訊',
      i18n_error: '該語言無法被翻譯',
      canceled: '取消',
      logged_out: '連線已逾期，請重新連線',
      not_authorized: '您沒有造訪權限',
    },
    validation: {
      required: '必填欄位',
      minLength: '需大於',
      maxLength: '需小於',
      minValue: '至少需大於',
      maxValue: '必須大於 或 小於',
      number: '必須為數字',
      email: '必須為有效信箱',
      oneOf: '必須為',
      regex: '必須符合',
    },
  },
  resources: {
    reports: {
      download: {
        complete: 'Report downloaded',
        error: 'Error downloading report',
      },
    },
    'partner-admins': {
      name: '經銷商主管',
      fields: {
        name: '姓名',
        userEmail: '電子信箱',
        firstName: '名字',
        lastName: '姓氏',
        nickname: '綽號',
        contactNumber: '聯絡電話',
        createdDate: '建立日期',
        partnerAccount: '經銷商商業帳號',
      },
      new_title: '新增經銷商主管',
      edit_title: '編輯經銷商主管',
    },
    'business-partners': {
      name: '商業夥伴 |||| 商業夥伴',
      fields: {
        name: 'Name',
        createdDate: 'Date Created',
      },
      new_title: 'New Business Partner Admin',
      edit_title: 'Edit Business Partner Admin',
    },
    'business-partner-admins': {
      name: '商業夥伴',
      fields: {
        name: '姓名',
        userEmail: '電子信箱',
        firstName: '名字',
        lastName: '姓氏',
        nickname: '綽號',
        contactNumber: '聯絡電話',
        createdDate: '建立日期',
      },
      new_title: '新商業夥伴',
      edit_title: '編輯商業夥伴',
    },
    'partner-stores': {
      name: '分店',
      fields: {
        title: '',
        userEmail: '電子信箱',
        partnerStoreName: '分店代碼',
        partnerStoreName_alt: '分店',
        contactNumber1: '聯絡電話',
        contactNumber1_alt: '分店聯絡電話',
        branchName: '分店名稱',
        address: '地址',
        contactPerson1: '聯絡人',
        contactPerson1_alt: '主要聯絡人姓名',
        email1: '電子信箱',
        email1_alt: '主要聯絡人電子信箱',
        contactNumber1_alt2: '主要聯絡人電話',
        contactPerson2: '次要聯絡人姓名',
        email2: '次要聯絡人電子信箱',
        contactNumber2: '次要聯絡人電話',
        createdDate: '建立日期',
      },
      contactPerson1_search: '搜尋姓名',
      partnerStoreName_search: '搜尋分店名稱',
      branchName_search: '搜尋分店名稱',
      branchName_placeholder: '例如：ABC門市',
      new_title: '新增門市分店',
      edit_title: '編輯分店資訊',
    },
    'store-managers': {
      name: '店經理',
      fields: {
        name: '姓名',
        userEmail: '電子信箱',
        title: '稱謂',
        firstName: '名字',
        lastName: '姓氏',
        nickname: '綽號',
        contactNumber: '聯絡電話',
        partnerStores: '指派分店',
        partnerStores_alt: '分店名稱',
        salesPartnerId: '經銷商',
        altMdn: '手機號碼 2',
        createdDate: '建立日期',
        salesRepInfo: {
          lineId: 'Line ID',
        },
      },
      new_title: '新增門市人員',
      edit_title: '編輯使用者',
    },
    'sales-representatives': {
      name: '銷售人員',
      fields: {
        name: '姓名',
        userEmail: '電子信箱',
        title: '稱謂',
        contactNumber: '聯絡電話',
        salesPartnerStoreId: '分店',
        salesPartnerStoreId_alt: '指派分店',
        store: '分店',
        role: '職稱',
        firstName: '姓氏',
        lastName: '名字',
        nickname: '綽號',
        altMdn: '手機號碼 2',
        createdDate: '建立日期',
        bankDetails: {
          firstName: '名字',
          lastName: '姓氏',
          bankNameId: '銀行名稱',
          bankBranchId: '分行名稱',
          accountNumber: '銀行帳號',
          swiftCode: 'SWIFT 碼',
          accountTypeId: '銀行帳戶類別',
        },
        salesRepInfo: {
          lineId: 'Line ID',
        },
      },
      new_title: '新亞勝銷售人員',
      edit_title: '編輯銷售人員',
      store_search: '搜尋分店名稱',
    },
    incentives: {
      name: '獎勵',
      fields: {
        info: '資訊',
        enrollmentDate: '註冊日期',
        paymentDate: '付款日期',
        salesPerson: '銷售人員',
        store: '分店',
        partnerCode: '夥伴代碼',
        tier: '等級',
        plan: '方案',
        paymentStatus: '付款狀態',
        incentiveStatus: '獎勵狀態',
        subscriptionFee: '訂閱費用',
        discount: '折扣',
        discountedSubscriptionFee: '折扣後的訂閱費用',
        incentive: '獎勵',
        agreementRequestId: '同意請求 ID',
      },
    },
    'sales-partners': {
      fields: {
        partnerName: '經銷商名稱',
        partnerCode: '經銷商ID',
        salesManagers: '亞勝業務',
      },
      fieldsets: {
        partnerAccountDetails: '經銷商商業帳號資訊',
        address: '地址資訊',
        contactDetails: '聯絡人資訊',
      },
      placeholders: {
        partnerName: '經銷商名稱',
        salesManagers: '搜尋姓名',
      },
      new_title: '新增經銷商商業帳號',
      edit_title: 'Edit Partner Account',
    },
  },
  asurion: {
    login: {
      title: '帳戶登入',
      forgot_password_helper: '您的密碼需要協助嗎?',
      login_transition: '登入中',
    },
    profile: {
      activation: {
        success: {
          0: '您的帳號以開通完成。請點擊',
          1: '這裡',
          2: '請點擊這裡登入平台。',
        },

        error: 'There was an error activating your account.',
      },
      set_password: {
        title: '密碼建立',
        set_password_helper: '必須建立密碼以開始使用您的帳號',
        form: {
          fields: {
            password: '密碼',
            confirm_password: '確認密碼',
            set_password: '確認',
          },
          placeholder: {
            password: '輸入密碼',
            confirm_password: '輸入密碼',
          },
        },
      },
      change_password: {
        title: '更換密碼',
        form: {
          change_password: {
            label: '現在的密碼',
            placeholder: '輸入現在的密碼',
          },
          new_password: {
            label: '確認密碼',
            placeholder: '輸入新密碼',
          },
          confirm_new_password: {
            label: '確認新密碼',
            placeholder: '確認新密碼',
          },
        },
      },
      view: {
        title: '我的主頁',
        partnerCode: '夥伴代碼',
      },
      edit: {
        title: '編輯我的主頁',
      },
    },
    reset_password_funnel: {
      '0': {
        title: '重新設定密碼',
        description: '輸入您註冊的電子信箱，我們將會把OTP碼寄送至該信箱',
        form: {
          send_otp: {
            label: '發送OTP碼以重新設定密碼',
          },
        },
      },
      '1': {
        title: '輸入驗證碼',
        description: '我們已將驗證碼寄送至您註冊的信箱',
        otp_expiration_helper: '為了保護您的裝置，驗證碼將在 ... 分鐘後過期',
        otp_expiration: '分鐘',
        resend_helper: '我沒有收到驗證碼',
        resend_label: '重新發送驗證碼',
      },
      '2': {
        title: '更換密碼',
      },
    },
    common: {
      action: {
        add_resource: '新增',
        verify: '提交',
        submit: '提交',
        validating: '驗證中',
      },
      error: {
        generic: '伺服器連接錯誤',
        must_be_similar_to: '必須符合',
      },
      table: {
        fields: {
          action: '編輯',
          status: '狀態',
        },
      },
      navigation: {
        back: '返回',
        done: '完成',
        done_alt: '完成',
        menus: {
          management: '帳號管理',
          partner_admin: '經銷商主管',
          partner_account: '經銷商商業帳號',
          store_account: '門市分店',
          store_manager: '門市人員',
          sales_representative: '銷售人員',
          sales_performance: '業績表現',
          bulletin: '公告',
          incentives: '獎勵',
          partner_sales: '夥伴銷售業績',
          programs: '計畫',
          phone_make: '通話量',
          business_partner: '商業夥伴',
          business_partner_admin: 'Business Partner Admin',
        },
      },
      dialog: {
        active: {
          title: '確認帳號啟動',
          message: '點擊確認鍵後，使用者將擁有使用權限',
          confirm: '您想要繼續嗎?',
        },
        deactivate: {
          title: '確認帳號失效',
          message: '點擊確認鍵後，使用者將失去使用權限',
          confirm: '您想要繼續嗎?',
        },
        generic_confirm: {
          message: '點擊確認鍵後，代表您已確認上述資訊皆正確無誤',
          confirm: '您想要繼續嗎?',
        },
        done: {
          title: '完成！',
          message: '已被新增',
        },
      },
      form: {
        active: '使用中',
        inactive: '暫停中',
        pending: '等待中',
        activation_link: '重新寄送帳戶設定連結',
        status: '狀態',
        new_title: '新',
        cancel: '取消',
        sections: {
          store_details: '分店資訊',
          address: '地址',
          contact_details: '聯絡資訊',
          sales_rep_details: '銷售人員資訊',
          bank_details: '銀行資訊',
        },
        actions: {
          clear: '清除',
          filter: '篩選',
          download: 'Download',
        },
        email: {
          label: '電子信箱',
          placeholder: '輸入電子信箱',
          search_placeholder: '搜尋電子信箱',
        },
        password: {
          label: '密碼',
          placeholder: '輸入密碼',
          change_password: '更換密碼',
        },
        login: {
          label: '登入',
        },
        full_name: {
          label: '姓名',
          search_placeholder: '搜尋姓名',
        },
        first_name: {
          label: '名字',
        },
        last_name: {
          label: '姓氏',
        },
        store: {
          label: '分店名稱',
          placeholder: '例如： 11111',
          search_placeholder: '搜尋分店名稱',
        },
        contact_number: {
          label: '聯絡電話',
          validation: {
            already_exists: '該連絡電話以使用過',
          },
        },
        partner_account: {
          label: '經銷商商業帳號',
          placeholder: '搜尋經銷商商業帳號',
        },
        altMdn: {
          label: '備用聯絡電話 (可選填)',
        },
        lineId: {
          label: 'Line ID (可選填)',
        },
        enrollment_date: {
          label: '註冊日期',
        },
        payment_date: {
          label: '付款日期',
        },
        sales_person: {
          label: '銷售人員',
        },
        store_alt: {
          label: '分店',
        },
        incentive_status: {
          label: '獎勵狀態',
        },
        plan: {
          label: '計畫',
        },
        collection: {
          title: {
            mister: '先生',
            miss: '小姐',
            missus: '女士',
          },
          address: {
            country: {
              label: '國家',
            },
            businessName: {
              label: '公司名稱 (可選填)',
              placeholder: '例如：ABC公司',
            },
            building: {
              label: '大樓名稱',
              placeholder: '大樓名稱',
            },
            roomNo: {
              label: '巷/弄/號/樓',
            },
            houseNo: {
              label: '巷/弄/號/樓',
            },
            village: {
              label: '鄉',
              placeholder: '鄉',
            },
            alley: {
              label: '巷/弄',
              placeholder: '巷/弄',
            },
            street: {
              label: '街/路',
            },
            district: {
              label: '區',
              placeholder: '區',
            },
            subDistrict: {
              label: '區',
              placeholder: '區',
            },
            province: {
              label: '縣市',
            },
            postalCode: {
              label: '郵遞區號',
            },
          },
          user_status: {
            all: '全部',
            active: '使用中',
            inactive: '暫停中',
            pending: '等待中',
          },
          plans: {
            all: '全部',
            monthly: '月',
            '12monthly': '期',
            '24monthly': '期',
          },
          payment_status: {
            all: '全部',
            pending: '等待中',
            failed: '失敗',
            success: '完成',
          },
          incentive_status: {
            all: '全部',
            unpaid: '未付款',
            pending: '等待中',
            processing: '處理中',
            paid: '已付款',
          },
          contact_details: {
            directorName: '經銷商負責人姓名',
            contactPerson1: '主要聯絡人姓名',
            email1: '主要聯絡人電子郵件',
            contactNumber1: '主要聯絡人電話號碼',
          },
        },
      },
    },
  },
};

export default chineseMessages;

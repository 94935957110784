import * as React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import {
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  TextField,
  TextInput,
  useGetIdentity,
  usePermissions,
  useTranslate,
} from 'react-admin';
import ListActions from '../overrides/ListActions';
import { ActiveIndStatusField } from '../common/Field/ActiveIndStatusField';
import { EditIconButton } from '../common/Form/Buttons/EditIconButton';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import ActiveStatusSelectInput from '../common/Form/Collection/ActiveIndStatusSelectInput';
import Can from '../authentication/Can';
import AvatarPlaceholderIcon from '../common/Branding/Icons/AvatarPlaceholderIcon';
import { useCountryId } from '../../contexts/CountryContext';
import { useSalesPartnerId } from '../../contexts/SalesPartnerContext';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
  avatarIcon: {
    width: 32,
    height: 32,
  },
  avatarCell: {
    padding: '7px 0 7px 20px',
    justifyContent: 'center',
  },
  avatarCellElement: {
    display: 'flex',
  },
});

const SalesRepresentativesFilter = () => {
  const translate = useTranslate();
  return (
    <AsurionSearchForm>
      <TextInput
        label={translate('asurion.common.form.full_name.label')}
        placeholder={translate(
          'asurion.common.form.full_name.search_placeholder'
        )}
        source="name"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label={translate('asurion.common.form.email.label')}
        placeholder={translate('asurion.common.form.email.search_placeholder')}
        source="userEmail"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label={translate('resources.sales-representatives.fields.store')}
        placeholder={translate('resources.sales-representatives.store_search')}
        source="storeName"
        fullWidth
        variant="outlined"
      />
      <ActiveStatusSelectInput />
    </AsurionSearchForm>
  );
};

export const SalesRepresentativesList = (props: ListProps) => {
  const { permissions = [] } = usePermissions();
  const { identity, loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  const { salesPartnerId } = useSalesPartnerId();
  const classes = useStyles();

  let filter: any = { countryId };
  if (!permissions.includes('is_super_admin')) {
    if (identity?.role === 'SALES_MGR') {
      filter = {
        ...filter,
        salesManagerId: identity?.id,
      };
    }

    if (identity?.extra.salesPartnerId) {
      filter = {
        ...filter,
        salesPartnerId: identity?.extra.salesPartnerId,
      };
    }
  }

  if (salesPartnerId) {
    filter = {
      ...filter,
      salesPartnerId,
    };
  }

  return loaded ? (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={
          <Can do="create_user_sales_representative">
            <ListActions />
          </Can>
        }
        filter={filter}
        filters={<SalesRepresentativesFilter />}
        pagination={false}
        hasCreate={permissions.includes('create_user_sales_representative')}
      >
        <Datagrid rowClick="show">
          <FunctionField
            className={classes.avatarCellElement}
            cellClassName={classes.avatarCell}
            render={(record: any) => {
              if (!record.profilePic) return <AvatarPlaceholderIcon />;
              return (
                <Avatar
                  alt={`${record.firstName} ${record.lastName}`}
                  src={record.profilePic}
                  variant="circular"
                />
              );
            }}
          />
          <FunctionField
            label="resources.sales-representatives.fields.name"
            sortBy="firstName"
            render={(record: any) => {
              return `${record.firstName} ${record.lastName}`;
            }}
          />
          <EmailField source="userEmail" sortable={false} />
          <TextField source="contactNumber" sortable={false} />

          <ReferenceField
            source="salesPartnerStoreId"
            reference="partner-stores"
            link={false}
          >
            <FunctionField
              render={(record: any) => {
                if (record.activeInd.toLowerCase() === 'active') {
                  return record.partnerStoreName;
                }
                return '';
              }}
            />
          </ReferenceField>
          {/* Hack to use a locale with yyyy-mm-dd since Datefield only uses locales to format days */}
          <DateField source="createdDate" locales="sv-se" />
          {permissions.includes('update_user_sales_representative') && (
            <EditIconButton label="asurion.common.table.fields.action" />
          )}
          {permissions.includes('update_user_sales_representative') && (
            <ActiveIndStatusField
              label="asurion.common.table.fields.status"
              textAlign="center"
            />
          )}
        </Datagrid>
      </List>
    </>
  ) : null;
};

export default SalesRepresentativesList;

import { Typography } from '@material-ui/core';
import { noop } from '../../common/functions';
import { ActionDialog } from '../common/Dialog/ActionDialog';

export const ConfirmCancelDialog = (props: any) => {
  const { isOpen, onOk, onCancel, loading } = props;

  return (
    <ActionDialog
      title="Cancel Incentive Calculation"
      isOpen={isOpen}
      onCancel={onCancel}
      cancelText="No"
      onOk={onOk}
      okText="Yes"
      loading={loading}
    >
      <Typography>
        Are you sure you want to cancel this Incentive Calculation?
      </Typography>
    </ActionDialog>
  );
};

export const ConfirmPayoutDialog = (props: any) => {
  const { isOpen, onOk, onCancel, loading } = props;

  return (
    <ActionDialog
      title="Incentive Calculation Payout"
      isOpen={isOpen}
      onCancel={onCancel}
      cancelText="No"
      onOk={onOk}
      okText="Yes"
      loading={loading}
    >
      <Typography>
        Are you sure you want to submit this Incentive Calculation for payout?
      </Typography>
    </ActionDialog>
  );
};

export const DoneCancelDialog = (props: any) => {
  const { isOpen, onOk } = props;

  return (
    <ActionDialog
      title="Incentive calculation is now cancelled"
      isOpen={isOpen}
      onCancel={noop}
      showCancel={false}
      onOk={onOk}
      okText="Close"
    >
      <Typography>
        Incentives included in this calculation are now unmarked for
        calculation.
      </Typography>
      <Typography>
        You may now calculate a new batch of incentives for payout.
      </Typography>
    </ActionDialog>
  );
};

export const DonePayoutDialog = (props: any) => {
  const { isOpen, onOk } = props;

  return (
    <ActionDialog
      title="Payout Done!"
      isOpen={isOpen}
      onCancel={noop}
      showCancel={false}
      onOk={onOk}
      okText="Close"
    >
      <Typography>Incentives are now submitted for payout.</Typography>
      <Typography>
        You may now calculate a new batch of incentives for payout.
      </Typography>
    </ActionDialog>
  );
};

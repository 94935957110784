import * as React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import {
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { ShowSection } from '../common/Scaffolding/ShowSection';
import { EntityShowActions } from '../common/EntityShowActions';
import { AvatarPlaceholderIcon } from '../common/Branding/Icons/AvatarPlaceholderIcon';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
  avatarIcon: {
    width: 32,
    height: 32,
  },
  avatarCell: {
    padding: '7px 0 7px 20px',
    justifyContent: 'center',
  },
  avatarCellElement: {
    display: 'flex',
  },
});

export const SalesRepresentativesShow = (props: ShowProps) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const classes = useStyles();

  return (
    <Show
      {...props}
      actions={
        <EntityShowActions>
          <>
            <FunctionField
              className={classes.avatarCellElement}
              cellClassName={classes.avatarCell}
              render={(record: any) => {
                if (!record.profilePic) return <AvatarPlaceholderIcon />;
                return (
                  <Avatar
                    alt={`${record.firstName} ${record.lastName}`}
                    src={record.profilePic}
                    variant="circular"
                  />
                );
              }}
            />
            <FunctionField
              render={(resource: any) =>
                `${resource.firstName} ${resource.lastName}`
              }
            />
          </>
        </EntityShowActions>
      }
    >
      <SimpleShowLayout>
        <ShowSection
          title={translate('asurion.common.form.sections.sales_rep_details')}
        >
          {!['PARTNER_ADMIN', 'SALES_MGR'].includes(identity?.role) && (
            <ReferenceField
              label="Partner"
              source="salesPartnerId"
              reference="sales-partners"
              link={false}
            >
              <TextField source="partnerName" />
            </ReferenceField>
          )}
          <ReferenceField
            source="salesPartnerStoreId"
            reference="partner-stores"
            link={false}
          >
            <TextField source="partnerStoreName" />
          </ReferenceField>
          <TextField source="role" fullWidth />
          <TextField source="title" fullWidth />
          <TextField source="firstName" fullWidth />
          <TextField source="lastName" fullWidth />
          <TextField source="nickname" fullWidth emptyText="N/A" />
        </ShowSection>

        <ShowSection
          title={translate('asurion.common.form.sections.contact_details')}
        >
          <TextField source="userEmail" fullWidth />
          <TextField source="contactNumber" fullWidth />
          <TextField
            label="resources.store-managers.fields.altMdn"
            source="salesRepInfo.altMdn"
            fullWidth
            emptyText="N/A"
          />
          <TextField
            label="Line ID"
            source="salesRepInfo.lineId"
            fullWidth
            emptyText="N/A"
          />
        </ShowSection>

        <ShowSection
          title={translate('asurion.common.form.sections.bank_details')}
        >
          <TextField source="bankDetails.firstName" emptyText="N/A" fullWidth />
          <TextField source="bankDetails.lastName" emptyText="N/A" fullWidth />
          <ReferenceField
            reference="banks"
            source="bankDetails.bankNameId"
            fullWidth
            link={false}
            emptyText="N/A"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField
            source="bankDetails.bankBranchId"
            emptyText="N/A"
            fullWidth
          />
          <TextField
            source="bankDetails.accountNumber"
            emptyText="N/A"
            fullWidth
          />
          <ReferenceField
            label="resources.sales-representatives.fields.bankDetails.swiftCode"
            reference="banks"
            source="bankDetails.bankNameId"
            fullWidth
            link={false}
          >
            <TextField source="swift_code" />
          </ReferenceField>
          <ReferenceField
            reference="account-types"
            source="bankDetails.accountTypeId"
            emptyText="N/A"
            fullWidth
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </ShowSection>
      </SimpleShowLayout>
    </Show>
  );
};

export default SalesRepresentativesShow;

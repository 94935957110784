import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import ListActions from '../overrides/ListActions';
import { ListProps } from 'rsuite';
import { useCountryId } from '../../contexts/CountryContext';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import EditIconButton from '../common/Form/Buttons/EditIconButton';
import { makeStyles } from '@material-ui/core';

const BusinessPartnersFilter = () => {
  const translate = useTranslate();
  const { loaded } = useGetIdentity();
  return loaded ? (
    <AsurionSearchForm>
      <TextInput
        label={translate('resources.business-partners.fields.name')}
        placeholder={translate('resources.business-partners.fields.name')}
        source="name"
        fullWidth
        variant="outlined"
      />
    </AsurionSearchForm>
  ) : null;
};

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
    width: '1%',
  },
});

export const BusinessPartnerList = (props: ListProps) => {
  const { loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  let filter: any = { countryId };

  const classes = useStyles();

  return loaded ? (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={<ListActions />}
        filter={filter}
        filters={<BusinessPartnersFilter />}
        pagination={false}
      >
        <Datagrid>
          <TextField source="name" />
          <DateField source="createdDate" locales="sv-se" />
          <EditIconButton
            label="asurion.common.table.fields.action"
            headerClassName={classes.actions}
            cellClassName={classes.actions}
          />
        </Datagrid>
      </List>
    </>
  ) : null;
};

export default BusinessPartnerList;

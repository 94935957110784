import { makeStyles } from '@material-ui/core';
import {
  TextInput,
  useGetIdentity,
  useListContext,
  useTranslate,
} from 'react-admin';
import { useState } from 'react';

import { isPartnerAdmin } from '../../common/util';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import { DateRangePickerInput } from '../common/Form/DateRangePickerInput';

import {
  IncentiveStatusSelectInput,
  PaymentStatusSelectInput,
  PlanSelectInput,
} from './IncentiveInputs';

export const useStyles = makeStyles({
  incentivesFormRoot: {
    flexWrap: 'wrap',
    alignItems: 'end',
    marginBottom: '20px',
    marginRight: '160px',
    '& .MuiFormControl-root': {
      marginBottom: '0',
      '& .MuiFormHelperText-root': {
        position: 'absolute',
        zIndex: 3,
      },
    },
  },
  textInputWidth: {
    width: '10%',
    minWidth: '135px',
  },
});

export const IncentiveFilter = (props: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { identity } = useGetIdentity();

  const [enrollmentDates, setEnrollmentDates] = useState<any>();
  const [paymentDates, setPaymentDates] = useState<any>();

  const { setFilters, displayedFilters } = useListContext();

  const { timezone, defaultValues } = props;

  const { enrollmentDate: defaultEnrollmentDate, ...restDefaultValues } =
    defaultValues;

  const handleReset = (values: any, form: any) => {
    // console.log('Own reset.');
    // console.log('Displayed filters', displayedFilters);

    form.reset();

    setFilters(restDefaultValues, displayedFilters);

    setEnrollmentDates([]);
    setPaymentDates([]);

    // setFilters(valueWalkFn(defaultValues), displayedFilters);
  };

  return (
    <AsurionSearchForm
      customFormClass={classes.incentivesFormRoot}
      onReset={handleReset}
      // externalHandler={true}
    >
      <DateRangePickerInput
        dates={enrollmentDates}
        setDates={setEnrollmentDates}
        placeholder={translate('asurion.common.form.enrollment_date.label')}
        label={translate('asurion.common.form.enrollment_date.label')}
        source="enrollmentDate"
        timezone={timezone}
        initialValue={defaultEnrollmentDate}
        // defaultValue={defaultEnrollmentDate}
      />
      <DateRangePickerInput
        dates={paymentDates}
        setDates={setPaymentDates}
        placeholder={translate('asurion.common.form.payment_date.label')}
        label={translate('asurion.common.form.payment_date.label')}
        source="paymentDate"
        timezone={timezone}
      />
      <TextInput
        className={classes.textInputWidth}
        variant="outlined"
        label={translate('asurion.common.form.sales_person.label')}
        placeholder={translate('asurion.common.form.sales_person.label')}
        source="salesPersonName"
      />
      <TextInput
        className={classes.textInputWidth}
        variant="outlined"
        label={translate('asurion.common.form.store_alt.label')}
        placeholder={translate('asurion.common.form.store_alt.label')}
        source="storeName"
      />
      {!isPartnerAdmin(identity) && (
        <TextInput
          className={classes.textInputWidth}
          variant="outlined"
          label="Partner"
          placeholder="Search Partner Code"
          source="partnerCode"
        />
      )}
      <PlanSelectInput />
      <PaymentStatusSelectInput identity={identity} />
      <IncentiveStatusSelectInput />
    </AsurionSearchForm>
  );
};

export default IncentiveFilter;

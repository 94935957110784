import { isEmpty } from '../../../common/functions';

export const requiredIfChecked = (referenceField: string) => (
  value: string,
  allValues: any
) => {
  return allValues[referenceField] === false && isEmpty(value)
    ? {
        message: 'ra.validation.required',
        args: {},
      }
    : undefined;
};

export default requiredIfChecked;

import * as React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useGetIdentity,
  usePermissions,
  useTranslate,
} from 'react-admin';
import ListActions from '../overrides/ListActions';
import ActiveIndStatusField from '../common/Field/ActiveIndStatusField';
import EditIconButton from '../common/Form/Buttons/EditIconButton';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import ActiveStatusSelectInput from '../common/Form/Collection/ActiveIndStatusSelectInput';
import Can from '../authentication/Can';
import { useCountryId } from '../../contexts/CountryContext';
import { useSalesPartnerId } from '../../contexts/SalesPartnerContext';
import { filterObjectPropsWithValue } from '../../common/functions';

const PartnerStoresFilter = () => {
  const translate = useTranslate();
  const { identity, loaded } = useGetIdentity();
  return loaded ? (
    <AsurionSearchForm
      valueWalkFn={(values) => filterObjectPropsWithValue(values, 'all')}
    >
      <TextInput
        label="resources.partner-stores.fields.partnerStoreName"
        placeholder={translate(
          'resources.partner-stores.partnerStoreName_search'
        )}
        source="storeName"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label="resources.partner-stores.fields.branchName"
        placeholder={translate('resources.partner-stores.branchName_search')}
        source="branchName"
        fullWidth
        variant="outlined"
      />
      {!['PARTNER_ADMIN', 'SALES_MGR'].includes(identity?.role) && (
        <ReferenceInput
          label="Partner"
          source="partnerId"
          reference="sales-partners"
          fullWidth
          variant="outlined"
          filter={{
            _appendEmpty: 'partnerName',
          }}
          initialValue="All"
        >
          <SelectInput optionText="partnerName" />
        </ReferenceInput>
      )}

      {!['PARTNER_ADMIN', 'SALES_MGR'].includes(identity?.role) && (
        <TextInput
          label="Store Manager"
          placeholder="Search Store Manager"
          source="storeManagerName"
          fullWidth
          variant="outlined"
        />
      )}
      <TextInput
        label="resources.partner-stores.fields.contactPerson1"
        placeholder={translate(
          'resources.partner-stores.contactPerson1_search'
        )}
        source="contactPersonName"
        fullWidth
        variant="outlined"
      />
      <ActiveStatusSelectInput />
    </AsurionSearchForm>
  ) : null;
};

export const PartnerStoresList = (props: ListProps) => {
  const { permissions = [] } = usePermissions();
  const { identity, loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  const { salesPartnerId } = useSalesPartnerId();

  let filter: any = { countryId };
  if (!permissions.includes('is_super_admin')) {
    if (identity?.role === 'SALES_MGR') {
      filter = {
        ...filter,
        salesManagerId: identity?.id,
      };
    }

    if (identity?.extra.salesPartnerId) {
      filter = {
        ...filter,
        salesPartnerId: identity?.extra.salesPartnerId,
      };
    }
  }

  if (salesPartnerId) {
    filter = {
      ...filter,
      salesPartnerId,
    };
  }

  return loaded ? (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={
          <Can do="create_store">
            <ListActions verboseName="New Store" />
          </Can>
        }
        filter={filter}
        filters={<PartnerStoresFilter />}
        pagination={false}
        hasCreate={permissions.includes('create_store')}
      >
        <Datagrid rowClick="show">
          <TextField source="partnerStoreName" />
          <TextField source="contactNumber1" sortable={false} />
          <TextField source="branchName" />

          <FunctionField
            label="resources.partner-stores.fields.address"
            render={(record: any) => {
              const address = record.address;
              return [
                address.building,
                address.roomNo,
                address.houseNo,
                address.village,
                address.alley,
                address.street,
                address.district,
                `${address.province} ${address.postalCode}`,
              ]
                .filter(Boolean)
                .join(', ');
            }}
          />
          {permissions.includes('is_super_admin') && (
            <ReferenceField
              label="Store Manager"
              source="partnerStoreManagerId"
              reference="store-managers"
              link={false}
            >
              <FunctionField
                label="Name"
                render={(record: any) =>
                  `${record.firstName} ${record.lastName}`
                }
              />
            </ReferenceField>
          )}
          <TextField source="contactPerson1" />
          <DateField source="createdDate" locales="sv-se" />
          {permissions.includes('update_store') && (
            <EditIconButton label="asurion.common.table.fields.action" />
          )}
          {permissions.includes('update_store') && (
            <ActiveIndStatusField
              label="asurion.common.table.fields.status"
              textAlign="center"
            />
          )}
        </Datagrid>
      </List>
    </>
  ) : null;
};

export default PartnerStoresList;

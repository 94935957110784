import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import {
  AutocompleteArrayInput,
  Edit,
  email,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import AsurionForm from '../../common/Form/AsurionForm';
import CountrySelector from '../../common/Form/Collection/CountrySelector';
import { maxArrayInput } from '../../common/Validator/maxArrayInput';
import ContactNumber from '../../common/Field/ContactNumber';
import {
  useCountryConfig,
  useCountryId,
} from '../../../contexts/CountryContext';
import AsurionAddress from '../../overrides/tw/common/Form/Collection/AsurionAddress';
import { useBusinessPartnerId } from '../../../common/hooks';

const useStyles = makeStyles({
  requiredContainer: {},
});

export const NoBankDetailsEdit = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { countryId } = useCountryId();
  const { hasBusinessPartner } = useCountryConfig();
  const { businessPartnerId = false } = useBusinessPartnerId();

  const transform = (data: any) => ({
    ...data,
  });

  return (
    <Edit
      {...props}
      actions={false}
      mutationMode="pessimistic"
      transform={transform}
    >
      <AsurionForm title={translate('resources.sales-partners.edit_title')}>
        <Typography variant="h2">
          {translate(
            'resources.sales-partners.fieldsets.partnerAccountDetails'
          )}
        </Typography>

        <TextInput
          label="resources.sales-partners.fields.partnerName"
          placeholder="e.g. Public Company Limited"
          source="partnerName"
          fullWidth
          validate={required()}
          className="required"
        />

        <TextInput
          label="resources.sales-partners.fields.partnerCode"
          placeholder="Partner Code"
          source="partnerCode"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />

        <CountrySelector
          source="countryTlId"
          label="asurion.common.form.collection.address.country.label"
          disabled
        />
        {hasBusinessPartner && (
          <ReferenceInput
            label={translate('resources.business-partners.name', {
              smart_count: 1,
            })}
            source="businessPartnerId"
            reference="business-partners"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
            disabled={businessPartnerId}
            defaultValue={businessPartnerId}
            filter={{
              activeIndStatus: 'active',
              countryId,
            }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <ReferenceArrayInput
          label="resources.sales-partners.fields.salesManagers"
          source="salesManagers"
          reference="sales-managers"
          fullWidth
          validate={[required(), maxArrayInput<string>(3)]}
          variant="outlined"
          filter={{
            activeIndStatus: 'active',
            countryId,
          }}
        >
          <AutocompleteArrayInput
            allowDuplicates={false}
            classes={{
              container: classes.requiredContainer,
            }}
            optionText={({
              firstName,
              lastName,
            }: {
              firstName: string;
              lastName: string;
            }) => `${firstName} ${lastName}`}
          />
        </ReferenceArrayInput>

        <Typography variant="h2">
          {translate('resources.sales-partners.fieldsets.address')}
        </Typography>
        <AsurionAddress readonlyFields={['address.countryCode']} />

        <Typography variant="h2">
          {translate('resources.sales-partners.fieldsets.contactDetails')}
        </Typography>
        <TextInput
          label="asurion.common.form.collection.contact_details.directorName"
          placeholder="e.g  John Smith"
          source="contactDetails.directorName"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <TextInput
          label="asurion.common.form.collection.contact_details.contactPerson1"
          placeholder="e.g John Smith"
          source="contactDetails.contactPerson1"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <TextInput
          label="asurion.common.form.collection.contact_details.email1"
          placeholder="e.g johnsmith@gmail.com"
          source="contactDetails.email1"
          type="email"
          fullWidth
          validate={[required(), email()]}
          className="required"
          variant="outlined"
        />
        <ContactNumber
          label="asurion.common.form.collection.contact_details.contactNumber1"
          placeholder="e.g 0912345678"
          source="contactDetails.contactNumber1"
          className="required"
        />
      </AsurionForm>
    </Edit>
  );
};

export default NoBankDetailsEdit;

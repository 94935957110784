import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useState, useEffect, useCallback } from 'react';
import {
  required,
  useDataProvider,
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

interface StoreChoice {
  id: string;
  name: string;
}

interface FilteredStoresInputMultipleProps {
  salesPartnerId: string;
  onEmptyStore?: (form: ReturnType<typeof useForm>) => void;
  [key: string]: any;
}

const useStyles = makeStyles({
  requiredContainer: {},
});

export const FilteredStoresInputMultiple = ({
  source,
  validate = [required()],
  onEmptyStore,
  emptyText = 'Select Store',
  className = '',
  ...restProps
}: AutocompleteArrayInputProps) => {
  const form = useForm();
  const { values } = useFormState<FilteredStoresInputMultipleProps>();
  const dataProvider = useDataProvider();
  const [choices, setChoices] = useState<Array<StoreChoice>>([]);
  const classes = useStyles();

  const fetchStores = useCallback(async () => {
    if (values.salesPartnerId) {
      const { data = [] } = await dataProvider.getManyReference(
        'partner-stores',
        {
          target: 'salesPartnerId',
          id: values.salesPartnerId,
          pagination: {
            page: 1,
            perPage: 100,
          },
          filter: {
            activeIndStatus: 'active',
          },
          sort: { field: 'partnerName', order: 'ASC' },
        }
      );
      if (data.length < 1 && onEmptyStore) {
        onEmptyStore(form);
      }
      setChoices(
        data.map(({ id, partnerStoreName: name }) => {
          return {
            id: id as string,
            name,
          };
        })
      );
    }
  }, [values.salesPartnerId, dataProvider, form, onEmptyStore]);

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);
  return (
    <AutocompleteArrayInput
      source={source}
      classes={{
        container: className.includes('required')
          ? classes.requiredContainer
          : undefined,
      }}
      choices={choices}
      allowEmpty={false}
      validate={validate}
      emptyText={emptyText}
      variant="outlined"
      disabled={choices.length < 1}
      {...restProps}
    />
  );
};

import * as React from 'react';
import { getReportIdByTitle } from '../../../common/functions';
import { OwnEmbeddedReport } from '../../common/Reports/OwnEmbeddedReport';

export const Programs = () => {
  const reportId = getReportIdByTitle('program');
  return <OwnEmbeddedReport reportId={reportId} />;
};

export default Programs;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  NumberField,
  ReferenceArrayField,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import ListActions from '../overrides/ListActions';
import ActiveIndStatusField from '../common/Field/ActiveIndStatusField';
import EditIconButton from '../common/Form/Buttons/EditIconButton';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import ActiveStatusSelectInput from '../common/Form/Collection/ActiveIndStatusSelectInput';
import { DEFAULT_COUNTRY_LIST } from '../common/Form/Collection/CountrySelector';
import { useCountryId } from '../../contexts/CountryContext';
import { useBusinessPartnerId } from '../../common/hooks';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
  cellHeader: {
    textAlign: 'unset',
  },
});

const SalesPartnersFilter = () => {
  return (
    <AsurionSearchForm>
      <TextInput
        label="Company Name"
        placeholder="Search Company Name"
        source="name"
        fullWidth
        variant="outlined"
      />
      <SelectInput
        label="Country"
        choices={[{ id: 'all', name: 'All' }, ...DEFAULT_COUNTRY_LIST]}
        source="country"
        variant="outlined"
        initialValue="all"
      />
      <TextInput
        label="Asurion Sales Manager"
        placeholder="Search Name"
        source="salesManagerName"
        fullWidth
        variant="outlined"
      />
      <ActiveStatusSelectInput />
    </AsurionSearchForm>
  );
};

export const SalesPartnersList = (props: ListProps) => {
  const { countryId } = useCountryId();
  const { businessPartnerId } = useBusinessPartnerId();
  const classes = useStyles();
  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={<ListActions verboseName="New Partner Account" />}
        filter={{
          countryId,
          businessPartnerId,
        }}
        filterDefaultValues={{}}
        filters={<SalesPartnersFilter />}
        pagination={false}
      >
        <Datagrid rowClick="show">
          <TextField source="partnerName" label="Company Name" />
          <TextField
            source="tin"
            label="Tax Registration Number"
            sortable={false}
          />
          <FunctionField
            label="Address"
            render={(record: any) => {
              const address = record.address;
              return [
                address.building,
                address.roomNo,
                address.houseNo,
                address.village,
                address.alley,
                address.street,
                address.district,
                `${address.province} ${address.postalCode}`,
              ]
                .filter(Boolean)
                .join(', ');
            }}
          />
          <FunctionField
            label="Country"
            render={(record: any) => {
              const country = DEFAULT_COUNTRY_LIST.find(
                (country) => country.id === record.countryTlId
              );
              return country?.name || '';
            }}
          />
          <DateField
            source="createdDate"
            label="Date of Partnership"
            locales="sv-se"
          />
          <NumberField
            source="revenueSharePct"
            label="Revenue Share Percentage"
            options={{ style: 'percent' }}
            sortable={false}
            textAlign="left"
            headerClassName={classes.cellHeader}
          />
          <NumberField
            source="revenueTarget"
            label="Revenue Target"
            sortable={false}
            textAlign="right"
            headerClassName={classes.cellHeader}
          />
          <ReferenceArrayField
            label="Asurion Sales Manager"
            reference="sales-managers"
            source="salesManagers"
            filter={{
              activeInd: 'active',
            }}
            className={classes.arrayReferenceList}
            sortBy="salesManagers"
          >
            <SingleFieldList linkType={false}>
              <FunctionField
                label="Address"
                render={(record: any) =>
                  `${record.firstName} ${record.lastName}`
                }
              />
            </SingleFieldList>
          </ReferenceArrayField>
          <EditIconButton label="asurion.common.table.fields.action" />
          <ActiveIndStatusField textAlign="center" />
        </Datagrid>
      </List>
    </>
  );
};

export default SalesPartnersList;

import * as React from 'react';
import { getReportIdByTitle } from '../../../../common/functions';
import { OwnEmbeddedReport } from '../../../common/Reports/OwnEmbeddedReport';

export const PhoneMakePerformance = () => {
  const reportId = getReportIdByTitle('make');
  return <OwnEmbeddedReport reportId={reportId} />;
};

export default PhoneMakePerformance;

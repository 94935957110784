import * as React from 'react';
import { getReportIdByTitle } from '../../../common/functions';
import { OwnEmbeddedReport } from '../../common/Reports/OwnEmbeddedReport';

export const GrossAddsByPartner = () => {
  const reportId = getReportIdByTitle('store');
  return <OwnEmbeddedReport reportId={reportId} />;
};

export default GrossAddsByPartner;

import { createContext, useContext, useMemo, useState } from 'react';

export interface ProfileContextProps {
  profileVersion?: number;
  refreshProfile?: any;
  children?: React.ReactNode;
}

const ProfileContext = createContext<ProfileContextProps>({});

export const ProfileProvider = ({ children }: ProfileContextProps) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1),
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

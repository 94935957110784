import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  AutocompleteArrayInput,
  Create,
  CreateProps,
  email,
  LinearProgress,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  useRefresh,
  useTranslate,
} from 'react-admin';
import AsurionForm from '../../common/Form/AsurionForm';
import { AsurionStepper } from '../../common/Scaffolding/AsurionStepper';
import { CountrySelector } from '../../common/Form/Collection/CountrySelector';
import ContactNumber from '../../common/Field/ContactNumber';
import { useBusinessPartnerId, useSuccessHandler } from '../../../common/hooks';
import {
  useCountryConfig,
  useCountryId,
} from '../../../contexts/CountryContext';
import { useState } from 'react';
import PageContainer from '../../common/Scaffolding/PageContainer';
import moment from 'moment';
import AsurionAddress from '../../overrides/tw/common/Form/Collection/AsurionAddress';

const useStyles = makeStyles({
  requiredContainer: {},
});

export const NoBankDetailsCreate = (props: CreateProps) => {
  const [bankId, setBankId] = useState<string>();
  const [accountTypeId, setAccountTypeId] = useState<string>();
  const { onSuccessHandler } = useSuccessHandler(
    'Partner Account',
    props.basePath
  );
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState<boolean>(true);

  const { countryId } = useCountryId();
  const { hasBusinessPartner } = useCountryConfig();
  const { businessPartnerId = false } = useBusinessPartnerId();
  const translate = useTranslate();

  const transform = (data: any) => ({
    ...data,
    tin: 'XXX-XXXXX-XXXX',
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    revenueTarget: 0,
    revenueSharePct: 0,
    noIncentives: true,
    incentivePercentage: 0,
    partnerIncentivePercentage: 0,
    address: {
      ...data.address,
      subDistrict: 'XXXXXX',
    },
    bankDetails: {
      accountName: 'XXXXXX',
      bankNameId: bankId,
      bankBranchId: 'XXXXXX',
      accountNumber: 'XXX-XXXXX-XXXX',
      accountTypeId,
    },
    created_at: Date.now(),
    status: 'Pending',
  });

  const classes = useStyles();

  const refresh = useRefresh();
  React.useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  React.useEffect(() => {
    const load = async () => {
      try {
        const { data: bankData } = await dataProvider.getList('banks', {
          pagination: {
            perPage: 1,
            page: 1,
          },
          sort: {
            field: '',
            order: '',
          },
          filter: { countryId },
        });

        const { data: accountTypes } = await dataProvider.getList(
          'account-types',
          {
            pagination: {
              perPage: 1,
              page: 1,
            },
            sort: {
              field: '',
              order: '',
            },
            filter: {},
          }
        );

        setBankId(bankData[0].id as string);
        setAccountTypeId(accountTypes[0].id as string);
      } catch (e) {
        console.log('Error:', e);
      } finally {
        setLoading(false);
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = [
    {
      label: translate(
        'resources.sales-partners.fieldsets.partnerAccountDetails'
      ),
      content: [
        <>
          <TextInput
            label="resources.sales-partners.fields.partnerName"
            placeholder="e.g. Public Company Limited"
            source="partnerName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="resources.sales-partners.fields.partnerCode"
            placeholder={translate(
              'resources.sales-partners.fields.partnerCode'
            )}
            source="partnerCode"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />

          <CountrySelector
            source="countryTlId"
            label="asurion.common.form.collection.address.country.label"
          />
          {hasBusinessPartner && (
            <ReferenceInput
              label={translate('resources.business-partners.name', {
                smart_count: 1,
              })}
              source="businessPartnerId"
              reference="business-partners"
              fullWidth
              validate={required()}
              className="required"
              variant="outlined"
              disabled={businessPartnerId}
              defaultValue={businessPartnerId}
              filter={{
                activeIndStatus: 'active',
                countryId,
              }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
          <ReferenceArrayInput
            label="resources.sales-partners.fields.salesManagers"
            source="salesManagers"
            reference="sales-managers"
            fullWidth
            validate={required()}
            variant="outlined"
            filter={{
              activeIndStatus: 'active',
              countryId,
            }}
          >
            <AutocompleteArrayInput
              classes={{
                container: classes.requiredContainer,
              }}
              optionText={({
                firstName,
                lastName,
              }: {
                firstName: string;
                lastName: string;
              }) => `${firstName} ${lastName}`}
            />
          </ReferenceArrayInput>
        </>,
      ],
    },
    {
      label: translate('resources.sales-partners.fieldsets.address'),
      content: [<AsurionAddress />],
    },
    {
      label: translate('resources.sales-partners.fieldsets.contactDetails'),
      content: [
        <>
          <TextInput
            label="asurion.common.form.collection.contact_details.directorName"
            placeholder="e.g  John Smith"
            source="contactDetails.directorName"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="asurion.common.form.collection.contact_details.contactPerson1"
            placeholder="e.g John Smith"
            source="contactDetails.contactPerson1"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
          />
          <TextInput
            label="asurion.common.form.collection.contact_details.email1"
            placeholder="e.g johnsmith@gmail.com"
            source="contactDetails.email1"
            type="email"
            fullWidth
            validate={[required(), email()]}
            className="required"
            variant="outlined"
          />
          <ContactNumber
            label="asurion.common.form.collection.contact_details.contactNumber1"
            placeholder="e.g 0912345678"
            source="contactDetails.contactNumber1"
            className="required"
          />
        </>,
      ],
    },
  ];

  if (loading) {
    return (
      <PageContainer>
        <LinearProgress />
      </PageContainer>
    );
  }

  if (!bankId || !accountTypeId) return null;

  return (
    <Create {...props} transform={transform} onSuccess={onSuccessHandler}>
      <AsurionForm
        title={translate('resources.sales-partners.new_title')}
        redirect="list"
      >
        <AsurionStepper steps={steps} />
      </AsurionForm>
    </Create>
  );
};

export default NoBankDetailsCreate;

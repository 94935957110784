import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { FunctionField, SimpleShowLayout, useGetOne } from 'react-admin';
import { colors } from '../../../themes/default';

const useStyles = makeStyles({
  field: {
    marginBottom: 19,
    display: 'block',
  },
  fieldHeader: {
    '& .MuiInputLabel-formControl': {
      fontSize: 18,
      lineHeight: '21px',
      color: colors.brand.black,
    },
  },
});

const SwiftCodeContainer = (props: any) => {
  const { children, ...restProps } = props;
  const { values } = useFormState();
  if (!values.bankDetails?.bankNameId) {
    return null;
  }
  return <>{React.cloneElement(children, { ...restProps })}</>;
};

export const SwiftCode = (props: any) => {
  const { values } = useFormState();
  const { data, refetch } = useGetOne('banks', values.bankDetails?.bankNameId);
  const classes = useStyles();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bankDetails?.bankNameId]);

  return (
    <SwiftCodeContainer>
      <SimpleShowLayout className={classes.fieldHeader}>
        <FunctionField
          addLabel="true"
          label="Swift Code"
          render={() => (
            <span className={classes.field}>{data?.swift_code}</span>
          )}
          {...props}
        />
      </SimpleShowLayout>
    </SwiftCodeContainer>
  );
};

export default SwiftCode;

import {
  minLength,
  required,
  TextInput,
  TextInputProps,
  useTranslate,
} from 'react-admin';
import {
  CircularProgress,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { contactNumberUnicity } from '../../common/Validator/contactNumberUnicity';
import { useFormState } from 'react-final-form';

const useStyles = makeStyles({
  inputAdornmentRoot: {
    marginRight: 4,
    '& > *': {
      fontSize: 12,
    },
  },
  endInputAdornmentRoot: {
    display: 'flex',
    gap: 10,
    textTransform: 'lowercase',
  },
});

export const MAX_LENGTH = 9;

const ContactNumber = ({ source, ...restProps }: TextInputProps) => {
  const classes = useStyles();
  const { values, validating, active } = useFormState();
  const translate = useTranslate();

  const isValidating = () =>
    (values[source] || '').length > MAX_LENGTH &&
    active === source &&
    validating;

  return (
    <>
      <TextInput
        InputProps={{
          startAdornment: (
            <InputAdornment
              classes={{
                root: classes.inputAdornmentRoot,
              }}
              position="start"
            >
              0
            </InputAdornment>
          ),
          endAdornment: isValidating() && (
            <InputAdornment
              classes={{
                root: classes.endInputAdornmentRoot,
              }}
              position="end"
            >
              <CircularProgress size={20} />
              {translate('asurion.common.action.validating')}
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: MAX_LENGTH,
        }}
        label="asurion.common.form.contact_number.label"
        placeholder="e.g. 0912345678"
        source={source}
        fullWidth
        validate={[required(), minLength(MAX_LENGTH), contactNumberUnicity]}
        format={(value: string = '') => value.replace(/\D/g, '').substring(1)}
        parse={(input: string = '') => `0${input}`}
        variant="outlined"
        disabled={isValidating()}
        {...restProps}
      />
    </>
  );
};

export default ContactNumber;

import * as React from 'react';
import { SelectInput, SelectInputProps, useTranslate } from 'react-admin';

const ActiveStatusSelectInput = ({ ...restProps }: SelectInputProps) => {
  const translate = useTranslate();
  return (
    <SelectInput
      label={translate('asurion.common.form.status')}
      source="activeIndStatus"
      choices={[
        {
          id: 'all',
          name: translate('asurion.common.form.collection.user_status.all'),
        },
        {
          id: 'active',
          name: translate('asurion.common.form.collection.user_status.active'),
        },
        {
          id: 'inactive',
          name: translate(
            'asurion.common.form.collection.user_status.inactive'
          ),
        },
        {
          id: 'pending',
          name: translate('asurion.common.form.collection.user_status.pending'),
        },
      ]}
      fullWidth
      variant="outlined"
      initialValue="all"
      {...restProps}
    />
  );
};

export default ActiveStatusSelectInput;

import * as React from 'react';
import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { Form } from 'react-final-form';
import {
  required,
  TextInput,
  useAuthenticated,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import mustBeSimilarTo from '../common/Validator/mustBeSimilarTo';
import { Alert } from '@material-ui/lab';
import { createHash } from 'crypto';

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    marginTop: 120,
  },
  title: {
    fontSize: 20,
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: 20,
    color: theme.palette.common.black,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 10,
    width: '100%',
  },
  buttonRoot: {
    width: 90,
  },
  inputAdornmentRoot: {
    background: 'transparent',
    margin: 0,
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  alertBox: {
    marginBottom: 30,
    width: '-webkit-fill-available',
  },
}));

export const ChangePassword = () => {
  useAuthenticated({ allowed: 'view_own_profile' });
  const [loading, setLoading] = useState<boolean>(false);
  const { identity } = useGetIdentity();
  const [showPassword, setShowPassword] = useState<{ [k: string]: boolean }>(
    {}
  );
  const [error, setError] = useState<string>('');

  const history = useHistory();
  const classes = useStyles();
  const translate = useTranslate();

  const handleClickShowPassword = (key: string) => () =>
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });
  const handleMouseDownPassword = (key: string) => () =>
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });

  const handleSave = async (values: any) => {
    setLoading(true);
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_SECURITY_API_URL}/security/password`,
        {
          id: values.id,
          op: createHash('md5').update(values.oldPassword).digest('hex'),
          np: createHash('md5').update(values.newPassword).digest('hex'),
        }
      );
      if (result.status === 200) {
        history.push(ROUTES.PROFILE_VIEW.path);
      }
    } catch (e) {
      console.log('Error', e);
      setError('There was an error changing your password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" classes={{ root: classes.containerRoot }}>
      <Typography
        className={classes.title}
        variant="h3"
        component="h1"
        align="center"
      >
        {translate('asurion.profile.change_password.title')}
      </Typography>
      <Form
        initialValues={{ id: identity?.id }}
        onSubmit={handleSave}
        render={({ handleSubmit }) => (
          <form
            className={classes.formRoot}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            {error && (
              <Alert severity="error" className={classes.alertBox}>
                {error}
              </Alert>
            )}
            <TextInput
              source="oldPassword"
              label="asurion.profile.change_password.form.change_password.label"
              placeholder={translate(
                'asurion.profile.change_password.form.change_password.placeholder'
              )}
              type={showPassword?.changePassword ? 'text' : 'password'}
              variant="outlined"
              validate={[required()]}
              fullWidth
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      classes={{
                        root: classes.inputAdornmentRoot,
                      }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('changePassword')}
                      onMouseDown={handleMouseDownPassword('changePassword')}
                      disableRipple
                    >
                      {showPassword?.changePassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextInput
              source="newPassword"
              label="asurion.profile.change_password.form.new_password.label"
              placeholder={translate(
                'asurion.profile.change_password.form.new_password.placeholder'
              )}
              type={showPassword?.newPassword ? 'text' : 'password'}
              variant="outlined"
              validate={[required()]}
              fullWidth
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      classes={{
                        root: classes.inputAdornmentRoot,
                      }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('newPassword')}
                      onMouseDown={handleMouseDownPassword('newPassword')}
                      disableRipple
                    >
                      {showPassword?.newPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextInput
              source="confirmNewPassword"
              label="asurion.profile.change_password.form.confirm_new_password.label"
              placeholder={translate(
                'asurion.profile.change_password.form.confirm_new_password.placeholder'
              )}
              type={showPassword?.confirmNewPassword ? 'text' : 'password'}
              variant="outlined"
              validate={[
                required(),
                mustBeSimilarTo('newPassword', { targetField: 'New Password' }),
              ]}
              fullWidth
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      classes={{
                        root: classes.inputAdornmentRoot,
                      }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('confirmNewPassword')}
                      onMouseDown={handleMouseDownPassword(
                        'confirmNewPassword'
                      )}
                      disableRipple
                    >
                      {showPassword?.confirmNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <div className={classes.controls}>
              <Button
                classes={{ root: classes.buttonRoot }}
                disabled={loading}
                component={Link}
                to={ROUTES.PROFILE_VIEW.path}
              >
                {translate('ra.action.cancel')}
              </Button>
              <Button
                classes={{ root: classes.buttonRoot }}
                type="submit"
                color="primary"
                disabled={loading}
              >
                {translate('ra.action.save')}
              </Button>
            </div>
          </form>
        )}
      />
    </Container>
  );
};

export default ChangePassword;

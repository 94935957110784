import * as React from 'react';
import { Grid } from '@material-ui/core';
import {
  FunctionField,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';
import { DEFAULT_COUNTRY_LIST } from '../Form/Collection/CountrySelector';

export const AsurionAddress = () => {
  const translate = useTranslate();
  return (
    <SimpleShowLayout>
      <TextField
        label={translate(
          'asurion.common.form.collection.address.businessName.label'
        )}
        source="address.businessName"
        fullWidth
      />
      <FunctionField
        label={translate(
          'asurion.common.form.collection.address.country.label'
        )}
        render={(record: any) => {
          const country = DEFAULT_COUNTRY_LIST.find(
            (country) => country.id === record.address.countryCode
          );
          return country?.name || '';
        }}
      />
      <TextField
        label={translate(
          'asurion.common.form.collection.address.building.label'
        )}
        source="address.building"
        fullWidth
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SimpleShowLayout className="inline-field">
            <TextField
              addLabel={true}
              label={translate(
                'asurion.common.form.collection.address.roomNo.label'
              )}
              source="address.roomNo"
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={6}>
          <SimpleShowLayout className="inline-field">
            <TextField
              label={translate(
                'asurion.common.form.collection.address.houseNo.label'
              )}
              source="address.houseNo"
            />
          </SimpleShowLayout>
        </Grid>
      </Grid>
      <TextField
        label={translate(
          'asurion.common.form.collection.address.village.label'
        )}
        source="address.village"
        fullWidth
      />
      <TextField
        label={translate('asurion.common.form.collection.address.alley.label')}
        source="address.alley"
        fullWidth
      />
      <TextField
        label={translate('asurion.common.form.collection.address.street.label')}
        source="address.street"
        fullWidth
      />
      <TextField
        label={translate(
          'asurion.common.form.collection.address.district.label'
        )}
        source="address.district"
        fullWidth
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SimpleShowLayout className="inline-field">
            <TextField
              label={translate(
                'asurion.common.form.collection.address.province.label'
              )}
              source="address.province"
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={6}>
          <SimpleShowLayout className="inline-field">
            <TextField
              label={translate(
                'asurion.common.form.collection.address.postalCode.label'
              )}
              source="address.postalCode"
            />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  );
};

export default AsurionAddress;

import { SyntheticEvent } from 'react';
import { HttpError, UserIdentity } from 'react-admin';
import { reportsMapping } from './constants';
import { ROLES } from './types';

export const intersect = (a: Array<any>, b: Array<any>) => {
  const setB = new Set(b);
  return Array.from(new Set(a)).filter((x) => setB.has(x));
};

export const intersects = (a: Array<any>, b: Array<any>) => {
  return intersect(a, b).length > 0;
};

export const stopPropagation = (e: any) => {
  (e as SyntheticEvent).stopPropagation();
};

export const decorateWithRole = <T>(
  identity: UserIdentity | undefined,
  role: ROLES,
  key: keyof T
) => {
  return identity?.role === role ? { [key]: identity.extra[key] } : {};
};

export const template = (strings: any, ...keys: any[]) => {
  return function (...values: any[]) {
    let dict = values[values.length - 1] || {};
    let result = [strings[0]];
    keys.forEach(function (key, i) {
      let value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
};

export const noop = () => {};

export const normalizeClassName = (input: string) => {
  return input
    .replaceAll(/[-_\s]/g, '-')
    .replaceAll(/[^-\w]/g, '')
    .toLowerCase();
};

export const identity = <T>(input: T) => input;

export const filterObjectPropsWithValue = (
  inputObject: object,
  propValue: string
) => {
  const unpacked = Object.entries(inputObject).filter(
    ([_k, value]) => value.toLowerCase() !== propValue
  );
  return Object.fromEntries(unpacked);
};

export const notifyError = (err: HttpError, notify: any) => {
  if (!err) {
    console.error('An unknown error occurred.');
  } else {
    console.error(`[${err.status}] ${err.message} - ${err.body}`);
  }

  if (notify !== noop) {
    notify(err.message, 'error', {}, false, 5000);
  }
};

export const getReportIdByTitle = (title: string) => {
  return (
    (reportsMapping.find((report) => report.title === title) || {})['id'] || ''
  );
};

export const isEmpty = (value: any) =>
  typeof value === 'undefined' ||
  value === null ||
  value === '' ||
  (Array.isArray(value) && value.length === 0);

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useNotify } from 'react-admin';
import moment from 'moment';

import { noop } from '../../common/functions';
import { formatDateToLocalTime } from '../../common/util';
import { PAYOUT_BATCH_ID } from '../../common/constants';

import { ActionDialog, ActionDialogProps } from '../common/Dialog/ActionDialog';
import { Plan, PlanType } from './IncentiveInputs';
import { calculateIncentives } from '../incentives-calculation/IncentiveService';

const useStyles = makeStyles({
  listRoot: {
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

interface ConfirmCalculateDialogProps extends ActionDialogProps {
  filters: any;
  data: any;
  identity: any;
  timezone?: any;
}

export const ConfirmCalculateDialog = (props: ConfirmCalculateDialogProps) => {
  const notify = useNotify();

  const [loading, setLoading] = useState<boolean>(false);

  const { isOpen, onOk, onCancel, timezone, identity, filters } = props;

  // console.log('Filters received:', filters);

  const handleDatesFilter = (dates: any) =>
    dates?.map((d: any) => moment(d).tz(timezone).utc());

  const handleOk = useCallback(() => {
    setLoading(true);

    // console.log('Raw filters: ', filters);
    let transformedFilters = {
      ...filters,
      enrollmentDate: handleDatesFilter(filters.enrollmentDate),
      paymentDate: handleDatesFilter(filters.paymentDate),
    };
    // console.log('Transformed filters: ', transformedFilters);

    calculateIncentives(
      identity.id,
      [], // We pass empty only because we select by filter
      transformedFilters,
      (data: any) => {
        setLoading(false);
        if (data && data.payoutBatchId) {
          localStorage.setItem(PAYOUT_BATCH_ID, data.payoutBatchId);
          onOk(true);
        } else {
          onOk(false);
        }
      },
      (err: any) => {
        setLoading(false);
        console.error(`[${err.status}] ${err.message} - ${err.body}`);
        if (err.status === 403 || err.status === 404) {
          onOk(false, err.status);
        } else {
          notify(err.body, 'error', {}, false, 5000);
        }
        onCancel();
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity, filters, notify, onCancel, onOk]);

  const {
    enrollmentDate: [enrollmentDateStart = null, enrollmentDateEnd = null] = [],
    paymentDate: [paymentDateStart = null, paymentDateEnd = null] = [],
    salesPersonName = '',
    partnerCode = '',
    storeName = '',
    plan = '',
  } = filters;

  const dateExists = (dateStart: any, dateEnd: any) => dateStart && dateEnd;

  const handleDate = (date: any) => {
    // console.log('Timezone:', timezone);
    // console.log('Raw date:', date);
    let formattedDate = formatDateToLocalTime(date, timezone);
    // console.log('Formatted date:', formattedDate);
    return formattedDate;
  };

  const handleEnums = (text: string) => {
    let planValue = Plan[text as PlanType];
    if (planValue === Plan.all) {
      let [, ...others] = Object.values(Plan);
      return (
        <Typography>
          {' '}
          All Plans <b>{others.join(', ')}</b>
        </Typography>
      );
    }
    return (
      <Typography>
        {' '}
        Plan <b>{planValue}</b>
      </Typography>
    );
  };

  return (
    <ActionDialog
      title={`Are you sure you want to Calculate Incentives for enrollments with`}
      isOpen={isOpen}
      onCancel={onCancel}
      cancelText="No"
      onOk={handleOk}
      okText="Yes"
      loading={loading}
    >
      {dateExists(enrollmentDateStart, enrollmentDateEnd) && (
        <Typography>
          Enrollment date from <b>{handleDate(enrollmentDateStart)}</b> to{' '}
          <b>{handleDate(enrollmentDateEnd)}</b>
        </Typography>
      )}
      {dateExists(paymentDateStart, paymentDateEnd) && (
        <Typography>
          Payment date from <b>{handleDate(paymentDateStart)}</b> to{' '}
          <b>{handleDate(paymentDateEnd)}</b>
        </Typography>
      )}
      {salesPersonName && (
        <Typography>
          Sales Person <b>{salesPersonName}</b>
        </Typography>
      )}
      {partnerCode && (
        <Typography>
          Partner <b>{partnerCode}</b>
        </Typography>
      )}
      {storeName && (
        <Typography>
          Partner Store <b>{storeName}</b>
        </Typography>
      )}
      {plan && handleEnums(plan)}
    </ActionDialog>
  );
};

export const WarningPendingDialog = (props: any) => {
  const { isOpen, onOk } = props;

  return (
    <ActionDialog
      title="You have a pending Incentive Calculation"
      isOpen={isOpen}
      showCancel={false}
      onCancel={noop}
      onOk={onOk}
      okText="Close"
    >
      <Typography>
        Please cancel or submit it for payout before calculating a new batch.
      </Typography>
    </ActionDialog>
  );
};

export const WarningNoneViableDialog = (props: any) => {
  const classes = useStyles();

  const { isOpen, onOk } = props;

  return (
    <ActionDialog
      title="None of the selected enrollments are viable for Incentive Calculation"
      isOpen={isOpen}
      showCancel={false}
      onCancel={noop}
      onOk={onOk}
      okText="Close"
    >
      <Typography>
        Please select a new period and ensure that all the enrollments in the
        selected filter meets the following criteria:
      </Typography>
      <List classes={{ root: classes.listRoot }}>
        <ListItem>
          <ListItemText secondary="• Payment Status is Completed."></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="• Sales person is currently active."></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="• Sales person have complete required info (eg. Title, National ID, Address)."></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="• Partner has incentives for salesperson."></ListItemText>
        </ListItem>
      </List>
    </ActionDialog>
  );
};

export const DoneCalculationDialog = (props: any) => {
  const classes = useStyles();

  const { isOpen, onOk, etc = '' } = props;

  return (
    <ActionDialog
      title="All Done!"
      isOpen={isOpen}
      onCancel={noop}
      showCancel={false}
      onOk={onOk}
      okText="Close"
    >
      {etc && (
        <Typography>{`Incentive Calculation will be ready in ${etc}.`}</Typography>
      )}
      {!etc && (
        <Typography>
          You may now view the generated Incentive Calculation.
        </Typography>
      )}
      <br />
      <Typography>
        The following are excluded from the calculation by default:
      </Typography>
      <List classes={{ root: classes.listRoot }}>
        <ListItem>
          <ListItemText secondary="• Enrollments with Payment Status that are not yet Completed."></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="• Enrollments whose sales person that are currently not active."></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="• Enrollments whose sales person have incomplete required info (eg. Title, National ID, Address)."></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="• Enrollments under a partner that has incentives for the salesperson."></ListItemText>
        </ListItem>
      </List>
    </ActionDialog>
  );
};

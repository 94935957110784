import * as React from 'react';
import {
  required,
  SelectInput,
  SelectInputProps,
  useTranslate,
} from 'react-admin';

export const DEFAULT_SOURCE = 'title';

export const TitleSelector = ({
  source = DEFAULT_SOURCE,
  options = {
    placeholder: 'Select Country',
  },
  validate = [required()],
  variant = 'outlined',
  ...restProps
}: SelectInputProps) => {
  const translate = useTranslate();
  const titleList: Array<{ id: string; name: string }> = [
    {
      id: translate('asurion.common.form.collection.title.mister'),
      name: translate('asurion.common.form.collection.title.mister'),
    },
    {
      id: translate('asurion.common.form.collection.title.miss'),
      name: translate('asurion.common.form.collection.title.miss'),
    },
    {
      id: translate('asurion.common.form.collection.title.missus'),
      name: translate('asurion.common.form.collection.title.missus'),
    },
  ];

  return (
    <SelectInput
      {...{
        source,
        options,
        validate,
        variant,
      }}
      choices={titleList}
      fullWidth
      variant="outlined"
      {...restProps}
    />
  );
};

export default TitleSelector;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import ListActions from '../../overrides/ListActions';
import ActiveIndStatusField from '../../common/Field/ActiveIndStatusField';
import EditIconButton from '../../common/Form/Buttons/EditIconButton';
import AsurionSearchForm from '../../common/Form/AsurionSearchForm';
import ActiveStatusSelectInput from '../../common/Form/Collection/ActiveIndStatusSelectInput';
import { useCountryId } from '../../../contexts/CountryContext';
import { useBusinessPartnerId } from '../../../common/hooks';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
  cellHeader: {
    textAlign: 'unset',
  },
});

const SalesPartnersFilter = () => {
  const translate = useTranslate();
  return (
    <AsurionSearchForm>
      <TextInput
        label="resources.sales-partners.fields.partnerName"
        placeholder={translate(
          'resources.sales-partners.placeholders.partnerName'
        )}
        source="name"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label="resources.sales-partners.fields.salesManagers"
        placeholder={translate(
          'resources.sales-partners.placeholders.salesManagers'
        )}
        source="salesManagerName"
        fullWidth
        variant="outlined"
      />
      <ActiveStatusSelectInput />
    </AsurionSearchForm>
  );
};

export const NoBankDetailsList = (props: ListProps) => {
  const { countryId } = useCountryId();
  const { businessPartnerId } = useBusinessPartnerId();
  const classes = useStyles();
  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={<ListActions verboseName="New Partner Account" />}
        filter={{
          countryId,
          businessPartnerId,
        }}
        filterDefaultValues={{}}
        filters={<SalesPartnersFilter />}
        pagination={false}
      >
        <Datagrid rowClick="show">
          <TextField
            source="partnerName"
            label="resources.sales-partners.fields.partnerName"
          />
          <FunctionField
            label="resources.sales-partners.fieldsets.address"
            render={(record: any) => {
              const address = record.address;
              return [
                address.building,
                address.roomNo,
                address.houseNo,
                address.village,
                address.alley,
                address.street,
                address.district,
                `${address.province} ${address.postalCode}`,
              ]
                .filter(Boolean)
                .join(', ');
            }}
          />
          <ReferenceArrayField
            label="resources.sales-partners.fields.salesManagers"
            reference="sales-managers"
            source="salesManagers"
            filter={{
              activeInd: 'active',
            }}
            className={classes.arrayReferenceList}
            sortBy="salesManagers"
          >
            <SingleFieldList linkType={false}>
              <FunctionField
                label="Address"
                render={(record: any) =>
                  `${record.firstName} ${record.lastName}`
                }
              />
            </SingleFieldList>
          </ReferenceArrayField>
          <EditIconButton label="asurion.common.table.fields.action" />
          <ActiveIndStatusField
            label="asurion.common.table.fields.status"
            textAlign="center"
          />
        </Datagrid>
      </List>
    </>
  );
};

export default NoBankDetailsList;

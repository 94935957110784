import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { TopToolbar } from 'ra-ui-materialui';
import { EditIconButton } from '../common/Form/Buttons/EditIconButton';
import { appBarHeight } from '../../layout/DefaultAppBar';
import { ListButton } from 'react-admin';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { colors } from '../../themes/default';
import Can from '../authentication/Can';

export interface EntityShowActionsProps {
  children: React.ReactNode;
  basePath?: string;
  data?: any;
  BackLinkProps?: object;
}

const useStyles = makeStyles({
  toolbarRoot: {
    marginTop: appBarHeight + 57,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    padding: 0,
  },
  editIconButton: {
    padding: 12,
    transform: 'scale(0.72)',
  },
  backButton: {
    background: 'none',
    color: colors.brand.brightBlue,
    position: 'absolute',
    left: -122,

    '&:hover': {
      background: 'none',
    },

    '& .MuiButton-label': {
      display: 'flex',
      gap: 8,
    },
  },
});

export const EntityShowActions = ({
  basePath,
  data,
  children,
  BackLinkProps = {},
}: EntityShowActionsProps) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbarRoot}>
      <>
        <ListButton
          className={classes.backButton}
          basePath={basePath}
          record={data}
          icon={<KeyboardBackspaceIcon />}
          label="asurion.common.navigation.back"
          {...BackLinkProps}
        />
        {children}
        <Can do="update_store">
          <EditIconButton
            className={classes.editIconButton}
            basePath={basePath}
            record={data}
          />
        </Can>
      </>
    </TopToolbar>
  );
};

export default EntityShowActions;

import { Link, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  List,
  ListProps,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import ListActions from '../overrides/ListActions';
import SalesManagerQuickPreviewDialog from './SalesManagerQuickPreviewDialog';
import ActiveIndStatusField from '../common/Field/ActiveIndStatusField';
import { EditIconButton } from '../common/Form/Buttons/EditIconButton';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import ActiveStatusSelectInput from '../common/Form/Collection/ActiveIndStatusSelectInput';
import { useCountryId } from '../../contexts/CountryContext';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
});

const SalesManagerFilter = () => {
  return (
    <AsurionSearchForm>
      <TextInput
        label="Name"
        placeholder="Search Name"
        source="name"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label="Partner"
        placeholder="Search Partner"
        source="partnerName"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label="Email Address"
        placeholder="Search Email Address"
        source="userEmail"
        fullWidth
        variant="outlined"
      />
      <ActiveStatusSelectInput />
    </AsurionSearchForm>
  );
};

export const SalesManagerList: React.FC<ListProps> = (props) => {
  const [openQuickPreviewDialog, setOpenQuickPreviewDialog] = useState<boolean>(
    false
  );
  const [selectedSalesManagerId, setSelectedSalesManagerId] = useState<
    string | null
  >(null);
  const { countryId } = useCountryId();
  const classes = useStyles();

  const handleQuickPreviewDialogClose = () => {
    setOpenQuickPreviewDialog(false);
  };

  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={<ListActions />}
        filter={{
          countryId,
        }}
        filters={<SalesManagerFilter />}
        pagination={false}
      >
        <Datagrid>
          <FunctionField
            label="Name"
            sortBy="firstName"
            render={(record: any) => {
              return (
                <Link
                  onClick={() => {
                    setSelectedSalesManagerId(record.salesStoreUserId);
                    setOpenQuickPreviewDialog(true);
                  }}
                >
                  {record.firstName} {record.lastName}
                </Link>
              );
            }}
          />
          <ReferenceArrayField
            label="Partner"
            reference="sales-partners"
            source="salesPartners"
            sortable={false}
            filter={{
              activeInd: 'active',
            }}
            className={classes.arrayReferenceList}
          >
            <SingleFieldList linkType={false}>
              <TextField source="partnerName" />
            </SingleFieldList>
          </ReferenceArrayField>
          <EmailField
            source="userEmail"
            label="Email Address"
            sortable={false}
          />
          <DateField
            label="Date Created"
            source="createdDate"
            locales="sv-se"
          />
          <EditIconButton label="asurion.common.table.fields.action" />
          <ActiveIndStatusField textAlign="center" inert={false} />
        </Datagrid>
      </List>
      {selectedSalesManagerId && (
        <SalesManagerQuickPreviewDialog
          id={selectedSalesManagerId}
          open={openQuickPreviewDialog}
          onClose={handleQuickPreviewDialogClose}
          editPermission="update_user_sales_manager"
        />
      )}
    </>
  );
};

export default SalesManagerList;

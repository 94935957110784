import * as React from 'react';
import {
  Edit,
  EditProps,
  required,
  TextInput,
  useTranslate,
} from 'react-admin';
import AsurionForm from '../common/Form/AsurionForm';

export const BusinessPartnersEdit = (props: EditProps) => {
  const translate = useTranslate();

  return (
    <Edit {...props} mutationMode="pessimistic">
      <AsurionForm title={translate('resources.business-partners.edit_title')}>
        <TextInput
          placeholder="e.g. John"
          source="name"
          fullWidth
          validate={required()}
          className="required"
        />
      </AsurionForm>
    </Edit>
  );
};

export default BusinessPartnersEdit;

import * as React from 'react';
import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useCallback, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { colors } from '../../themes/default';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Avatar } from '../../components/common/Branding/Icons/Avatar';
import {
  email,
  required,
  TextInput,
  useAuthState,
  useLogout,
  useTranslate,
} from 'react-admin';
import { useSetTheme } from '../../contexts/ThemeContext';
import { useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { ROUTES } from '../../routes';
import {
  postAuthentication,
  processPermissions,
} from '../../services/authService';
import { getBrowserCountry } from '../../services/apiService';
import { createHash } from 'crypto';

const useStyles = makeStyles({
  '@global': {
    html: {
      background: colors.extra.page.background,
    },
    body: {
      background: colors.extra.page.background,
    },
  },
  header: {
    fontSize: 32,
  },
  containerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
    width: 430,
    marginTop: 120,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 430,
  },
  buttonRoot: {
    width: 120,
    marginTop: 30,
  },
  inputAdornmentRoot: {
    background: 'transparent',
    margin: 0,
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  collapseRoot: {
    width: '100%',
    textAlign: 'center',
    marginTop: 30,
  },
  samlLink: {
    textAlign: 'center',
  },
  alertBox: {
    marginBottom: 30,
    width: '-webkit-fill-available',
  },
});

export const ExternalLoginPage = () => {
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const logout = useLogout();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isAsurionEmail, setIsAsurionEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const classes = useStyles();
  const { setNeutralBackground } = useSetTheme();
  const { setHasMenu } = useSetTheme();

  const setUp = useCallback(() => {
    sessionStorage.clear();
    setHasMenu(false);
    setNeutralBackground(true);
  }, [setHasMenu, setNeutralBackground]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const onSubmit = async (values: any) => {
    setLoading(true);
    setError(error);
    try {
      const countryCode = await getBrowserCountry();

      // TODO: Transfer to apiService
      const result = await axios.post(
        `${process.env.REACT_APP_SECURITY_API_URL}/security/sign-in`,
        {
          u: values.userEmail,
          sp: createHash('sha256').update(values.password).digest('hex'),
          countryCode,
        }
      );
      if (result.status === 200) {
        // const token = jwt.sign(
        //   {
        //     token: 'xyz',
        //     email: result.data.Email,
        //   },
        //   'abc'
        // );
        // sessionStorage.setItem(ACCESS_TOKEN, token);
        // setHasMenu(true);
        // history.push({
        //   pathname: '/login',
        //   hash: `access_token=xyz`,
        // });

        const response = result.data;

        // Get API Token from Login Only API
        const email = response.Email || response.details?.Email; // Adjust Email to result.data.details.email
        const api_token = response.token; // Get token from result.data.token

        const { user } = await processPermissions(email, JSON.stringify(true));

        await postAuthentication(user, api_token);

        window.location.replace('/');
      }
      setLoading(false);
    } catch (e) {
      console.log('Error', e);
      setLoading(false);
      setError('Unable to log you in.');
    }
  };

  if (authenticated) {
    logout(null, '/sign-in');
  }

  return (
    <Container fixed classes={{ root: classes.containerRoot }}>
      <Avatar />
      <Typography
        className={classes.header}
        variant="h3"
        component="h1"
        align="center"
      >
        {translate('asurion.login.title')}
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            className={classes.formRoot}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <OnChange name="userEmail">
              {(value: string) => {
                const emailFlag = /^.+@asurion\.com$/.test(value);
                setIsAsurionEmail(emailFlag);
                if (emailFlag) {
                  localStorage.setItem('email', value as string);
                } else {
                  localStorage.removeItem('email');
                }
              }}
            </OnChange>
            {error && (
              <Alert severity="error" className={classes.alertBox}>
                {error}
              </Alert>
            )}
            <TextInput
              label="asurion.common.form.email.label"
              placeholder={translate('asurion.common.form.email.placeholder')}
              source="userEmail"
              type="email"
              fullWidth
              disabled={loading}
              validate={[required(), email()]}
              variant="outlined"
            />
            <TextInput
              label="asurion.common.form.password.label"
              source="password"
              placeholder={translate(
                'asurion.common.form.password.placeholder'
              )}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              validate={[required()]}
              fullWidth
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      classes={{
                        root: classes.inputAdornmentRoot,
                      }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      disableRipple
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              classes={{ root: classes.buttonRoot }}
              type="submit"
              color="primary"
              disabled={loading}
            >
              {translate('asurion.common.form.login.label')}
            </Button>
            <Link
              className={classes.collapseRoot}
              to={ROUTES.RESET_PASSWORD.path}
            >
              {translate('asurion.login.forgot_password_helper')}
            </Link>
            <Collapse
              in={isAsurionEmail && !loading}
              className={classes.collapseRoot}
            >
              <Link className={classes.samlLink} to="/login">
                Login with SAML SSO
              </Link>
            </Collapse>
          </form>
        )}
      />
    </Container>
  );
};

export default ExternalLoginPage;

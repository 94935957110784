import React from 'react';
import { TextField } from 'react-admin';
import { BusinessPartnerAdmin } from './types';
import QuickPreviewDialog from '../common/Dialog/QuickPreviewDialog';

interface Props {
  id: BusinessPartnerAdmin['salesStoreUserId'];
  open: boolean;
  editPermission?: string | Array<string>;
  onClose?: () => any;
}

const resourceName = 'business-partners';

const BusinessPartnerAdminsQuickPreviewDialog: React.FC<Props> = ({
  id,
  open,
  editPermission = [],
  onClose = () => {},
}) => {
  const title = (data: BusinessPartnerAdmin) => {
    return `${data.firstName} ${data.lastName} `;
  };

  return (
    <QuickPreviewDialog
      id={id as string}
      open={open}
      onClose={onClose}
      resourceName={resourceName}
      title={title}
      editPermission={editPermission}
    >
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="nickname" />
      <TextField source="userEmail" />
      <TextField source="contactNumber" />
    </QuickPreviewDialog>
  );
};

export default BusinessPartnerAdminsQuickPreviewDialog;

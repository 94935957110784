import React from 'react';
import {
  Edit,
  EditProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { decorateWithRole } from '../../common/functions';
import { useCountryId } from '../../contexts/CountryContext';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import EmailInput from '../common/Form/Input/EmailInput';
import { PartnerStores } from '../partner-stores/types';

export const BusinessPartnerAdminsEdit: React.FC<EditProps> = (props) => {
  const translate = useTranslate();
  const { identity, loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  const transform = (data: any) => {
    return {
      ...data,
      ...decorateWithRole<PartnerStores>(
        identity,
        'BUSINESS_PARTNER_ADMIN',
        'salesPartnerId'
      ),
    };
  };

  return loaded ? (
    <Edit {...props} mutationMode="pessimistic" transform={transform}>
      <AsurionForm title={translate('resources.business-partners.edit_title')}>
        <TextInput
          placeholder="e.g. John"
          source="firstName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          placeholder="e.g. Smith"
          source="lastName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput placeholder="e.g. John" source="nickname" fullWidth />
        <EmailInput source="userEmail" className="required" />
        <ContactNumber source="contactNumber" className="required" />
        <ReferenceInput
          label="Business Partner"
          source="businessPartnerId"
          reference="business-partners"
          fullWidth
          validate={required()}
          className="required"
          filter={{
            activeIndStatus: 'active',
            countryId,
          }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </AsurionForm>
    </Edit>
  ) : null;
};

export default BusinessPartnerAdminsEdit;

import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import {
  FieldProps,
  FunctionField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import AsurionSwitch from '../Form/Controls/AsurionSwitch';
import PendingStatusChip from '../PendingStatusChip';
import { ActivateUser } from '../Dialog/ActivateUser';
import { ACTIVE_IND } from '../../../common/types';

export interface ActiveIndStatusFieldProps extends FieldProps {
  withPasswordSetup?: boolean;
  inert?: boolean;
  showSetupLink?: boolean;
}

export const ActiveIndStatusField = (props: ActiveIndStatusFieldProps) => {
  const translate = useTranslate();
  const { resource, withPasswordSetup, ...restProps } = props;
  const record = useRecordContext(props);
  const [showActivateUserDialog, setShowActivateUserDialog] =
    useState<boolean>(false);
  const [activeIndStatus, setActiveIndStatus] = useState<ACTIVE_IND>(
    record.activeInd.toLowerCase()
  );

  const clickPending = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setShowActivateUserDialog(true);
  };

  const handleShowActivateUserDialogClose = (result: ACTIVE_IND) => {
    setShowActivateUserDialog(false);
    setActiveIndStatus(result);
  };

  const toggleActiveIndStatus = () => {
    setShowActivateUserDialog(true);
  };

  return (
    <>
      <FunctionField
        addLabel={true}
        resource={resource}
        {...restProps}
        render={() => {
          switch (activeIndStatus) {
            case 'active':
            case 'inactive':
              const toggled = activeIndStatus === 'active';
              return (
                <div>
                  <AsurionSwitch
                    label={translate(`asurion.common.form.${activeIndStatus}`)}
                    checked={toggled}
                    onClick={(e) => e.stopPropagation()}
                    onChange={toggleActiveIndStatus}
                  />
                </div>
              );
            default:
              return (
                <PendingStatusChip
                  id={record.id as string}
                  label={translate('asurion.common.form.pending')}
                  onClick={clickPending}
                  email={record.userEmail}
                  withPasswordSetup={withPasswordSetup}
                  inert={props.inert}
                  showSetupLink={props.showSetupLink}
                />
              );
          }
        }}
      />

      {resource && record && (
        <ActivateUser
          open={showActivateUserDialog}
          handleClose={handleShowActivateUserDialogClose}
          source={resource}
          record={record}
          fromStatus={activeIndStatus}
          toStatus={
            activeIndStatus.toLowerCase() === 'active' ? 'inactive' : 'active'
          }
          dialogTitle={
            activeIndStatus?.toLowerCase() === 'active'
              ? translate('asurion.common.dialog.deactivate.title')
              : translate('asurion.common.dialog.active.title')
          }
        >
          {activeIndStatus?.toLowerCase() === 'active' ? (
            <>
              <Typography>
                {translate('asurion.common.dialog.deactivate.message')}
              </Typography>
              <Typography>
                {translate('asurion.common.dialog.deactivate.confirm')}
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                {translate('asurion.common.dialog.active.message')}
              </Typography>
              <Typography>
                {translate('asurion.common.dialog.active.confirm')}
              </Typography>
            </>
          )}
        </ActivateUser>
      )}
    </>
  );
};

ActiveIndStatusField.defaultProps = {
  label: 'Status',
  addLabel: true,
};

export default ActiveIndStatusField;

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import { Redirect } from 'react-router-dom';
import { ROLES } from '../../common/types';
import { ROUTES } from '../../routes';

export const UserManagement = () => {
  const { identity, loaded, loading } = useGetIdentity();
  const [to, setTo] = useState<string | false>(false);

  useEffect(() => {
    let to;
    const role: ROLES = identity?.role || 'ANONYMOUS';
    switch (role) {
      case 'SALES_MGR':
        to = ROUTES.PARTNER_STORES_MANAGEMENT.path;
        break;
      case 'PARTNER_ADMIN':
        to = ROUTES.PARTNER_ADMINS.path;
        break;
      default:
        to = ROUTES.COUNTRY_MANAGERS.path;
        break;
    }
    if (!loading && loaded) {
      setTo(to);
    }
  }, [loaded, loading, identity]);
  return to ? <Redirect to={to} /> : null;
};

export default UserManagement;

import { decodeJwt } from 'jose';
import { useEffect, useState } from 'react';
import { useRefresh, useRedirect, useGetIdentity } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';
import { BusinessPartner } from '../components/business-partners/types';
import { DEFAULT_COUNTRY_LIST } from '../components/common/Form/Collection/CountrySelector';
import { useCountry } from '../contexts/CountryContext';
import { useDoneModal } from '../contexts/DoneModalContext';
import { useSalesPartnerId } from '../contexts/SalesPartnerContext';
import { useSetTheme } from '../contexts/ThemeContext';
import dataProvider from '../providers/dataProvider';
import JwtUtil from '../services/JwtUtil';
import { noop } from './functions';
import { uuidToCanonical } from './util';

export const useSuccessHandler = (label: string, basePath: string = '/') => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const {
    setOpen = noop,
    setPostCloseAction = noop,
    setEntityName = noop,
  } = useDoneModal();

  const onSuccessHandler = ({ data: _data }: { data: any }) => {
    setEntityName(label);
    setOpen(true);
    setPostCloseAction(() => () => {
      redirect(basePath);
      refresh();
    });
  };

  return {
    onSuccessHandler,
  };
};

export const useSalesPartner = () => {
  const { identity } = useGetIdentity();
  const [partner, setPartner] = useState<{ [k: string]: string }>({});

  useEffect(() => {
    const run = async () => {
      if (identity?.extra.salesPartnerId) {
        const { data } = await dataProvider.getOne('sales-partners', {
          id: identity?.extra.salesPartnerId,
        });
        setPartner(data);
      }
    };
    run();
  }, [identity]);

  return {
    partner,
  };
};

export const useBusinessPartnerId = () => {
  const userInfo = localStorage.getItem('user');
  let user: any;
  if (userInfo) {
    ({ data: user } = decodeJwt(JwtUtil.reconstructToken(userInfo)));
  }
  return { businessPartnerId: uuidToCanonical(user?.extra.businessPartnerId) };
};

export const useBusinessPartner = () => {
  const { identity } = useGetIdentity();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [businessPartner, setBusinessPartner] = useState<
    Partial<BusinessPartner>
  >({});

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);
        if (identity?.extra.businessPartnerId) {
          const { data } = await dataProvider.getOne('business-partners', {
            id: identity?.extra.businessPartnerId,
          });
          setBusinessPartner(data as unknown as BusinessPartner);
        }
        setLoaded(true);
      } catch (e) {
        setLoaded(false);
      } finally {
        setLoading(false);
      }
    };
    run();
  }, [identity]);
  return {
    loaded,
    loading,
    businessPartner,
  };
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useSetUpSalesPartner = () => {
  const { identity } = useGetIdentity();
  const { setCountry } = useCountry();
  const { setSalesPartnerId } = useSalesPartnerId();
  const { setHasMenu } = useSetTheme();
  const history = useHistory();

  const setUpSalesPartner = (salesPartnerId: string) => {
    const country = DEFAULT_COUNTRY_LIST.find(
      (country) => country.id === identity?.extra.countryId
    );
    setCountry(country);
    setSalesPartnerId(salesPartnerId);
    setHasMenu(true);
    history.push({ pathname: '/' });
  };

  return {
    setUpSalesPartner,
  };
};

import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import AsurionLayout from './layout/DefaultLayout';
import defaultTheme from './themes/default';
import { createBrowserHistory as createHistory } from 'history';
import customRoutes from './routes';
import Dashboard from './components/common/Dashboard';
import CountryManagers from './components/country-managers';
import SalesManagers from './components/sales-managers';
import BusinessPartners from './components/business-partners';
import BusinessPartnerAdmins from './components/business-partner-admins';
import PartnerAdmins from './components/partner-admins';
import StoreManagers from './components/store-managers';
import SalesRepresentatives from './components/sales-representatives';
import SalesPartners from './components/sales-partners';
import PartnerStores from './components/partner-stores';
import Incentives from './components/incentives';
import IncentivesCalcuation from './components/incentives-calculation';
import LoginPage from './components/authentication/LoginPage';
import { authProvider } from './providers/authProvider';
import { default as dataProvider } from './providers/dataProvider';
import SignoutButton from './components/common/Form/Buttons/SignoutButton';
import i18nProvider from './providers/i18nProvider';
import Redirect from './components/authentication/Redirect';
import FullStory from 'react-fullstory';

const history = createHistory();

export const App: React.FC = (props) => {
  return (
    <>
      {localStorage.getItem('user') && (
        <FullStory org={process.env.REACT_APP_FULLSTORY_ORG_ID || ''} />
      )}
      <Admin
        dashboard={Dashboard}
        theme={defaultTheme}
        layout={AsurionLayout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        history={history}
        customRoutes={customRoutes}
        loginPage={LoginPage}
        logoutButton={SignoutButton}
        i18nProvider={i18nProvider}
      >
        {(permissions) => [
          <Resource
            name="country-managers"
            create={
              permissions.includes('create_user_country_manager')
                ? CountryManagers.create
                : Redirect
            }
            list={
              permissions.includes('read_user_country_manager')
                ? CountryManagers.list
                : Redirect
            }
            edit={
              permissions.includes('update_user_country_manager')
                ? CountryManagers.edit
                : Redirect
            }
          />,

          <Resource
            name="sales-managers"
            create={
              permissions.includes('create_user_sales_manager')
                ? SalesManagers.create
                : Redirect
            }
            list={
              permissions.includes('read_user_sales_manager')
                ? SalesManagers.list
                : Redirect
            }
            edit={
              permissions.includes('update_user_sales_manager')
                ? SalesManagers.edit
                : Redirect
            }
          />,

          <Resource
            name="partner-admins"
            create={
              permissions.includes('create_user_partner_admin')
                ? PartnerAdmins.create
                : Redirect
            }
            list={
              permissions.includes('read_user_partner_admin')
                ? PartnerAdmins.list
                : Redirect
            }
            edit={
              permissions.includes('update_user_partner_admin')
                ? PartnerAdmins.edit
                : Redirect
            }
          />,

          <Resource
            name="store-managers"
            create={
              permissions.includes('create_user_store_manager')
                ? StoreManagers.create
                : Redirect
            }
            list={
              permissions.includes('read_user_store_manager')
                ? StoreManagers.list
                : Redirect
            }
            edit={
              permissions.includes('update_user_store_manager')
                ? StoreManagers.edit
                : Redirect
            }
          />,

          <Resource
            name="sales-representatives"
            create={
              permissions.includes('create_user_sales_representative')
                ? SalesRepresentatives.create
                : Redirect
            }
            list={
              permissions.includes('read_user_sales_representative')
                ? SalesRepresentatives.list
                : Redirect
            }
            edit={
              permissions.includes('update_user_sales_representative')
                ? SalesRepresentatives.edit
                : Redirect
            }
            show={
              permissions.includes('read_user_sales_representative')
                ? SalesRepresentatives.show
                : Redirect
            }
          />,

          <Resource
            name="sales-partners"
            create={
              permissions.includes('create_partners')
                ? permissions.includes('no_bank_details')
                  ? SalesPartners.variants.noBankDetails.create
                  : SalesPartners.create
                : Redirect
            }
            list={
              permissions.includes('read_partners')
                ? permissions.includes('no_bank_details')
                  ? SalesPartners.variants.noBankDetails.list
                  : SalesPartners.list
                : Redirect
            }
            edit={
              permissions.includes('update_partners')
                ? permissions.includes('no_bank_details')
                  ? SalesPartners.variants.noBankDetails.edit
                  : SalesPartners.edit
                : Redirect
            }
            show={
              permissions.includes('read_partners')
                ? permissions.includes('no_bank_details')
                  ? SalesPartners.variants.noBankDetails.show
                  : SalesPartners.show
                : Redirect
            }
          />,

          <Resource
            name="partner-stores"
            create={
              permissions.includes('create_store')
                ? PartnerStores.create
                : Redirect
            }
            list={
              permissions.includes('read_store') ? PartnerStores.list : Redirect
            }
            edit={
              permissions.includes('update_store')
                ? PartnerStores.edit
                : Redirect
            }
            show={
              permissions.includes('read_store') ? PartnerStores.show : Redirect
            }
          />,

          <Resource
            name="incentives"
            list={
              permissions.includes('view_incentives')
                ? Incentives.list
                : Redirect
            }
          />,

          <Resource
            name="incentives-calculation"
            list={
              permissions.includes('calculate_incentives')
                ? IncentivesCalcuation.list
                : Redirect
            }
          />,

          <Resource name="banks" />,
          <Resource name="account-types" />,
          <Resource
            name="business-partners"
            list={
              permissions.includes('read_business_partner')
                ? BusinessPartners.list
                : Redirect
            }
            create={
              permissions.includes('create_business_partner')
                ? BusinessPartners.create
                : Redirect
            }
            edit={
              permissions.includes('update_business_partner')
                ? BusinessPartners.edit
                : Redirect
            }
          />,
          <Resource
            name="business-partner-admins"
            list={
              permissions.includes('read_user_business_partner_admin')
                ? BusinessPartnerAdmins.list
                : Redirect
            }
            create={
              permissions.includes('create_user_business_partner_admin')
                ? BusinessPartnerAdmins.create
                : Redirect
            }
            edit={
              permissions.includes('update_user_business_partner_admin')
                ? BusinessPartnerAdmins.edit
                : Redirect
            }
          />,
        ]}
      </Admin>
    </>
  );
};

export default App;

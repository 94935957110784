import * as React from 'react';
import { Typography } from '@material-ui/core';
import {
  Edit,
  EditProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import AsurionForm from '../common/Form/AsurionForm';
import { FilteredStoresInput } from '../common/Form/Collection/FilteredStoresInput';
import ContactNumber from '../common/Field/ContactNumber';
import EmailInput from '../common/Form/Input/EmailInput';
import TitleSelector from '../common/Form/Collection/TitleSelector';

export const SalesRepresentativesEdit = (props: EditProps) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <Edit {...props} mutationMode="pessimistic" actions={false}>
      <AsurionForm
        initialValues={{
          salesPartnerId: identity?.extra.salesPartnerId,
        }}
        title={translate('resources.sales-representatives.edit_title')}
        mutators={{
          resetStore: (_args: any, state: any, utils: any) => {
            utils.changeValue(state, 'salesPartnerStoreId', () => '');
          },
        }}
      >
        <Typography variant="h2">
          {translate('asurion.common.form.sections.sales_rep_details')}
        </Typography>

        {!identity?.extra.salesPartnerId && (
          <ReferenceInput
            label="Assign to Partner"
            source="salesPartnerId"
            reference="sales-partners"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
            disabled
            filter={{
              activeIndStatus: 'active',
            }}
          >
            <SelectInput optionText="partnerName" />
          </ReferenceInput>
        )}

        <FilteredStoresInput
          source="salesPartnerStoreId"
          className="required"
          fullWidth
          onEmptyStore={(form: any) => {
            form.mutators.resetStore();
          }}
        />

        <SelectInput
          source="role"
          choices={[
            {
              id: 'Sales Representative',
              name: 'Sales Representative',
            },
          ]}
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />

        <TitleSelector source="title" />

        <TextInput
          placeholder="e.g. John"
          source="firstName"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />

        <TextInput
          placeholder="e.g. Smith"
          source="lastName"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <TextInput
          placeholder="e.g. John"
          source="nickname"
          fullWidth
          variant="outlined"
        />

        <Typography variant="h2">
          {translate('asurion.common.form.sections.contact_details')}
        </Typography>

        <EmailInput source="userEmail" className="required" />

        <ContactNumber
          label="asurion.common.form.contact_number.label"
          source="contactNumber"
          className="required"
        />

        <ContactNumber
          placeholder="08-1234-5678"
          source="salesRepInfo.altMdn"
          validate={undefined}
          disabled
        />

        <TextInput
          placeholder="08-1234-5678"
          source="salesRepInfo.lineId"
          fullWidth
          variant="outlined"
          disabled
        />
      </AsurionForm>
    </Edit>
  );
};

export default SalesRepresentativesEdit;

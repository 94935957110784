import React from 'react';
import { TextField } from 'react-admin';
import { CountryManager } from './types';
import { QuickPreviewDialog } from '../common/Dialog/QuickPreviewDialog';
interface Props {
  id: CountryManager['salesStoreUserId'];
  open: boolean;
  editPermission?: string | Array<string>;
  onClose?: () => any;
}

const resourceName = 'country-managers';

const CountryManagerQuickPreviewDialog: React.FC<Props> = ({
  id,
  open,
  editPermission = [],
  onClose = () => {},
}) => {
  const title = (data: CountryManager) => {
    return `${data.firstName} ${data.lastName} `;
  };

  return (
    <QuickPreviewDialog
      id={id as string}
      open={open}
      onClose={onClose}
      resourceName={resourceName}
      title={title}
      editPermission={editPermission}
    >
      <TextField source="firstName" label="First Name" />
      <TextField source="lastName" label="Last Name" />
      <TextField source="nickname" />
      <TextField source="userEmail" label="Email Address" />
      <TextField source="contactNumber" label="Contact Number" />
    </QuickPreviewDialog>
  );
};

export default CountryManagerQuickPreviewDialog;

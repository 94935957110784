import { stringify } from 'querystring';
import { DataProvider, fetchUtils } from 'react-admin';
import { ACCESS_TOKEN } from './authProvider';

const resourcesWithContentRanges = ['incentives', 'incentives-calculation'];

export const fetchJson = (
  url: string,
  options: any = {},
  needAuth: boolean = true
) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  // Add your own headers here
  options.headers.set('x-api-key', process.env.REACT_APP_ADMIN_API_KEY);
  // Add Authtoken in headers
  if (needAuth) {
    options.headers.set('Authtoken', sessionStorage.getItem(ACCESS_TOKEN));
  }

  return fetchUtils.fetchJson(url, options);
};

export const dataProvider: DataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    const url = `${process.env.REACT_APP_ADMIN_API_URL}/${resource}?${stringify(
      query
    )}`;
    const { json = [], headers } = await fetchJson(url);

    const total = headers.get('content-range')?.split('/').pop();

    const parseTotal = (json: any) =>
      resourcesWithContentRanges.includes(resource) && total
        ? parseInt(total)
        : json.length;

    // const parseDefaultId = (data: any) => resourcesWithContentRanges.includes(resource) && validator.isUUID(data.id) ?
    //   uuidUncanonical(data.id): data.id;

    let response = {
      data: json.map((data: any) => ({
        id: data.salesStoreUserId || data.id,
        ...data,
      })),
      total: parseTotal(json),
    };

    // Hack to allow empty value
    const emptyKey = params.filter._appendEmpty;
    if (emptyKey) {
      response = {
        ...response,
        data: [
          {
            id: 'All',
            [emptyKey]: 'All',
          },
          ...response.data,
        ],
      };
    }
    return response;
  },

  getOne: async (resource, { id }) => {
    const { json = [{}] } = await fetchJson(
      `${process.env.REACT_APP_ADMIN_API_URL}/${resource}/${id}`
    );
    const [item] = json;
    return {
      data: {
        ...item,
        id: item?.salesStoreUserId,
      },
    };
  },

  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify(params),
    };
    const url = `${process.env.REACT_APP_ADMIN_API_URL}/${resource}?${stringify(
      query
    )}`;
    const { json = [] } = await fetchJson(url);
    return {
      data: json.map((data: any) => ({
        id: data.salesStoreUserId,
        ...data,
      })),
      total: json.length,
    };
  },

  getManyReference: async (resource, params) => {
    const query = {
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${process.env.REACT_APP_ADMIN_API_URL}/${resource}?${stringify(
      query
    )}`;
    const { json = [] } = await fetchJson(url);
    return {
      data: json.map((data: any) => ({
        id: data.salesStoreUserId,
        ...data,
      })),
      total: json.length,
    };
  },

  update: async (resource, { id, data }) => {
    const url = `${process.env.REACT_APP_ADMIN_API_URL}/${resource}/${id}`;
    const overrides = JSON.parse(localStorage.getItem('overrides') || '{}');
    const { json: item } = await fetchJson(url, {
      method: 'PUT',
      body: JSON.stringify({ ...data, overrides }),
    });
    return {
      data: {
        ...item,
        id: item.salesStoreUserId,
      },
    };
  },

  updateMany: (resource, params) => new Promise(() => {}),

  create: async (resource, params) => {
    const url = `${process.env.REACT_APP_ADMIN_API_URL}/${resource}`;
    const { json: item } = await fetchJson(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    });
    return {
      data: {
        ...item,
        id: item.salesStoreUserId,
      },
    };
  },

  delete: async (resource, { id }) => {
    const url = `${process.env.REACT_APP_ADMIN_API_URL}/${resource}/${id}`;
    const { json: item } = await fetchJson(url, { method: 'DELETE' });
    return {
      data: {
        ...item,
        id: item.salesStoreUserId,
      },
    };
  },

  deleteMany: (resource, params) => new Promise(() => {}),
};

export default dataProvider;

import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import {
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  TextField,
  TextInput,
  useGetIdentity,
  usePermissions,
  useTranslate,
} from 'react-admin';
import ListActions from '../overrides/ListActions';
import BusinessPartnersQuickPreviewDialog from './BusinessPartnerAdminsQuickPreviewDialog';
import ActiveIndStatusField from '../common/Field/ActiveIndStatusField';
import EditIconButton from '../common/Form/Buttons/EditIconButton';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import ActiveStatusSelectInput from '../common/Form/Collection/ActiveIndStatusSelectInput';
import { useCountryId } from '../../contexts/CountryContext';

const BusinessPartnerAdminsFilter = () => {
  const translate = useTranslate();
  const { loaded } = useGetIdentity();
  return loaded ? (
    <AsurionSearchForm>
      <TextInput
        label={translate('resources.partner-stores.fields.partnerStoreName')}
        placeholder={translate(
          'asurion.common.form.full_name.search_placeholder'
        )}
        source="name"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label={translate('asurion.common.form.email.label')}
        placeholder={translate('asurion.common.form.email.search_placeholder')}
        source="userEmail"
        fullWidth
        variant="outlined"
      />
      <ActiveStatusSelectInput />
    </AsurionSearchForm>
  ) : null;
};

export const BusinessPartnerAdminsList = (props: ListProps) => {
  const { permissions = [] } = usePermissions();
  const { identity, loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [openQuickPreviewDialog, setOpenQuickPreviewDialog] =
    useState<boolean>(false);

  const handleQuickPreviewDialogClose = () => {
    setOpenQuickPreviewDialog(false);
  };

  let filter: any = { countryId };
  if (
    !permissions.includes('is_super_admin') &&
    identity?.extra.salesPartnerId
  ) {
    filter = {
      ...filter,
      salesPartnerId: identity?.extra.salesPartnerId,
    };
  }

  return loaded ? (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={<ListActions />}
        filter={filter}
        filters={<BusinessPartnerAdminsFilter />}
        pagination={false}
        hasCreate={permissions.includes('create_user_business_partner_admin')}
      >
        <Datagrid>
          <FunctionField
            label="resources.partner-admins.fields.name"
            sortBy="firstName"
            render={(record: any) => {
              return (
                <Link
                  onClick={() => {
                    setSelectedUserId(record.salesStoreUserId);
                    setOpenQuickPreviewDialog(true);
                  }}
                >
                  {record.firstName} {record.lastName}
                </Link>
              );
            }}
          />
          <EmailField source="userEmail" sortable={false} />
          <ReferenceField
            label="Business Partner"
            source="businessPartnerId"
            reference="business-partners"
            link={false}
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdDate" locales="sv-se" />
          <EditIconButton label="asurion.common.table.fields.action" />
          <ActiveIndStatusField
            label="asurion.common.table.fields.status"
            textAlign="center"
            withPasswordSetup={true}
            showSetupLink={true}
          />
        </Datagrid>
      </List>
      {selectedUserId && (
        <BusinessPartnersQuickPreviewDialog
          id={selectedUserId}
          open={openQuickPreviewDialog}
          onClose={handleQuickPreviewDialogClose}
          editPermission="update_business_partner"
        />
      )}
    </>
  ) : null;
};

export default BusinessPartnerAdminsList;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { EditButton, EditButtonProps, PublicFieldProps } from 'react-admin';
import { EditIcon } from '../../Branding/Icons/EditIcon';

const useStyle = makeStyles({
  button: {
    width: 35,
    minWidth: 35,
  },
  label: {
    display: 'none',
  },
});

export const EditIconButton: React.FC<EditButtonProps & PublicFieldProps> = (
  props
) => {
  const classes = useStyle();
  return (
    <EditButton label="" {...props} classes={classes} icon={<EditIcon />} />
  );
};

export default EditIconButton;

import * as React from 'react';
import { email, required, TextInput } from 'react-admin';
import { TextInputProps } from 'ra-ui-materialui';
import { emailUnicity } from '../../../common/Validator/emailUnicity';

export const EmailInput = ({
  label = 'asurion.common.form.email.label',
  placeholder = 'e.g. johnsmith@gmail.com',
  fullWidth = true,
  variant = 'outlined',
  ...restProps
}: TextInputProps) => {
  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      type="email"
      fullWidth={fullWidth}
      variant={variant}
      validate={[required(), email(), emailUnicity]}
      {...restProps}
    />
  );
};

export default EmailInput;

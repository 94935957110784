import * as React from 'react';
import {
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import ShowSection from '../common/Scaffolding/ShowSection';
import AsurionAddress from '../common/Collection/AsurionAddress';
import EntityShowActions from '../common/EntityShowActions';
import { ROUTES } from '../../routes';

export const SalesPartnersShow = (props: ShowProps) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <Show
      {...props}
      actions={
        <EntityShowActions
          BackLinkProps={{
            to: {
              pathname: ROUTES.PARTNER_STORES.path,
            },
          }}
        >
          <TextField
            label="resources.partner-stores.fields.partnerStoreName_alt"
            source="partnerStoreName"
            fullWidth
          />
        </EntityShowActions>
      }
    >
      <SimpleShowLayout>
        <ShowSection
          title={translate('asurion.common.form.sections.store_details')}
        >
          {!['PARTNER_ADMIN', 'SALES_MGR'].includes(identity?.role) && (
            <ReferenceField
              source="salesPartnerId"
              reference="sales-partners"
              link={false}
            >
              <TextField source="partnerName" />
            </ReferenceField>
          )}
          <TextField
            label={
              identity?.role === 'SALES_MGR'
                ? 'Store Name'
                : 'resources.partner-stores.fields.partnerStoreName_alt'
            }
            source="partnerStoreName"
            fullWidth
          />
          <TextField source="contactNumber1" fullWidth />
          <TextField source="branchName" fullWidth />
        </ShowSection>

        <ShowSection title={translate('asurion.common.form.sections.address')}>
          <AsurionAddress />
        </ShowSection>

        <ShowSection
          title={translate('asurion.common.form.sections.contact_details')}
        >
          <TextField source="contactPerson1" fullWidth emptyText="N/A" />
          <TextField source="email1" fullWidth emptyText="N/A" />
        </ShowSection>
      </SimpleShowLayout>
    </Show>
  );
};

export default SalesPartnersShow;

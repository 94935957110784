import SalesManagerList from './SalesManagerList';
import SalesManagerCreate from './SalesManagerCreate';
import SalesManagerEdit from './SalesManagerEdit';

const resourceMapping = {
  list: SalesManagerList,
  create: SalesManagerCreate,
  edit: SalesManagerEdit,
};

export default resourceMapping;

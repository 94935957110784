import * as React from 'react';
import { Tab, makeStyles } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { colors } from '../../themes/default';
import { useTranslate } from 'ra-core';

export interface LinkTabProps {
  label: string;
  to: string | { pathname: string; state?: object };
  value: string;
  resolver?: (to: string | { pathname: string; state?: object }) => boolean;
}

const useStyles = makeStyles({
  root: {
    paddingTop: '12px 0',
    minHeight: 0,
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: colors.extra.indicator,
    borderBottomStyle: 'solid',
  },
});

export const LinkTab: React.FC<LinkTabProps> = (props) => {
  const { to, resolver = () => true, value, label, ...restProps } = props;
  const location = useLocation();
  const classes = useStyles();
  const translate = useTranslate();

  return resolver(to) ? (
    <Tab
      label={translate(label)}
      className={value === location.pathname ? classes.activeTab : undefined}
      classes={{
        root: classes.root,
      }}
      component={Link}
      {...{
        to,
        value,
        ...restProps,
      }}
    />
  ) : null;
};

export default LinkTab;

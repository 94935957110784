export const maxNumericValue = (
  upperLimit: number,
  transformer = (value: number): string | number => value
) => (value: number) => {
  return value > upperLimit
    ? {
        message: 'ra.validation.maxValue',
        args: {
          max: transformer(value),
        },
      }
    : undefined;
};

export default maxNumericValue;

import { makeStyles } from '@material-ui/core';
import { SelectInput, SelectInputProps, useTranslate } from 'react-admin';

import { enumToObject } from '../../common/util';

export enum Plan {
  all = 'All',
  MONTHLY = 'Monthly',
  '12MONTHLY' = 'Term (12)',
  '24MONTHLY' = 'Term (24)',
}

export declare type PlanType = keyof typeof Plan;

export enum PaymentStatus {
  all = 'All',
  Pending = 'Pending',
  Failed = 'Failed',
  Success = 'Completed',
}

export enum IncentiveStatus {
  all = 'All',
  Unpaid = 'Unpaid',
  Pending = 'Pending',
  Processing = 'Processing',
  Paid = 'Paid',
}

const useStyles = makeStyles({
  selectInputWidth: {
    width: '0%',
    minWidth: '100px',
  },
});

const IncentiveSelectInput = (props: SelectInputProps) => {
  const classes = useStyles();
  const { label, source, choices, ...restProps } = props;

  return (
    <SelectInput
      className={classes.selectInputWidth}
      label={label}
      source={source}
      choices={choices}
      variant="outlined"
      initialValue="all"
      {...restProps}
    />
  );
};

export const PlanSelectInput = ({ ...restProps }: SelectInputProps) => {
  const translate = useTranslate();
  var plans = enumToObject(Plan).map(({ id, name }) => ({
    id,
    name: translate(
      `asurion.common.form.collection.plans.${(id as string).toLowerCase()}`,
      { _: name }
    ),
  }));
  return (
    <IncentiveSelectInput
      label={translate('asurion.common.form.plan.label')}
      source="plan"
      choices={plans}
      {...restProps}
    />
  );
};

export const PaymentStatusSelectInput = ({
  identity,
  ...restProps
}: SelectInputProps) => {
  const translate = useTranslate();
  const paymentStatuses = enumToObject(PaymentStatus).map(({ id, name }) => ({
    id,
    name: translate(
      `asurion.common.form.collection.payment_status.${(
        id as string
      ).toLowerCase()}`,
      { _: name }
    ),
  }));
  const statusLabel = [
    'BUSINESS_PARTNER_ADMIN',
    'PARTNER_ADMIN',
    'COUNTRY_MGR',
  ].includes(identity?.role)
    ? translate('asurion.common.table.fields.status')
    : translate('resources.incentives.fields.paymentStatus');
  return (
    <IncentiveSelectInput
      label={statusLabel}
      source="paymentStatus"
      choices={paymentStatuses}
      {...restProps}
    />
  );
};

export const IncentiveStatusSelectInput = ({
  ...restProps
}: SelectInputProps) => {
  const translate = useTranslate();
  const incentiveStatuses = enumToObject(IncentiveStatus).map(
    ({ id, name }) => ({
      id,
      name: translate(
        `asurion.common.form.collection.incentive_status.${(
          id as string
        ).toLowerCase()}`,
        { _: name }
      ),
    })
  );
  return (
    <IncentiveSelectInput
      label={translate('asurion.common.form.incentive_status.label')}
      source="incentiveStatus"
      choices={incentiveStatuses}
      {...restProps}
    />
  );
};

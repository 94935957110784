import { AuthProvider } from 'react-admin';
import JwtUtil from '../services/JwtUtil';
import { decodeJwt } from 'jose';

export const ACCESS_TOKEN = 'id_token';
export const API_TOKEN = 'api_token';
export const CODE = 'code';

export const authProvider: AuthProvider = {
  login: async () => {
    return Promise[
      sessionStorage.getItem(ACCESS_TOKEN) ? 'resolve' : 'reject'
    ]();
  },
  logout: async () => {
    sessionStorage.clear();
    return Promise.resolve('/sign-in');
  },
  checkAuth: async ({ allowed }) => {
    if (allowed) {
      const role = await authProvider.getPermissions({});
      if (role.includes(allowed)) {
        return Promise.resolve();
      } else {
        return Promise.reject({ redirectTo: '/' });
      }
    }
    return Promise[
      sessionStorage.getItem(ACCESS_TOKEN) ? 'resolve' : 'reject'
    ]();
  },
  checkError: ({ response: { status = 200 } = {} }) => {
    const isAuthError = status === 401 || status === 403;
    if (isAuthError) {
      sessionStorage.removeItem(ACCESS_TOKEN);
      sessionStorage.removeItem(API_TOKEN);
    }
    return Promise[isAuthError ? 'reject' : 'resolve']();
  },
  getPermissions: async () => {
    let permissionMatrix = 'guest';
    try {
      const permission = localStorage.getItem('permissions');
      if (permission) {
        const { data: role } = decodeJwt(JwtUtil.reconstructToken(permission));
        permissionMatrix = role ? JSON.parse(role as any) : 'guest';
      }
    } catch (e) {
      console.error(e);
    }
    return Promise.resolve(permissionMatrix);
  },
  getIdentity: async () => {
    try {
      const userInfo = localStorage.getItem('user');
      let user: any;
      if (userInfo) {
        ({ data: user } = decodeJwt(JwtUtil.reconstructToken(userInfo)));
      }
      return Promise.resolve(user);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;

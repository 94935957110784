import 'regenerator-runtime';
import * as React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { getReportIdByTitle } from '../../../../common/functions';
import { OwnEmbeddedReport } from '../../../common/Reports/OwnEmbeddedReport';
import { useGetIdentity, useTranslate } from 'react-admin';
import DateRangePickerInput from '../../../common/Form/DateRangePickerInput';
import { downloadReport } from '../../../../services/reportService';
import { format } from 'date-fns';
import { useState } from 'react';
import Excel from 'exceljs';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    top: -40,
  },
  actions: {
    padding: 16,
    display: 'flex',
    alignItems: 'flex-end',
    gap: 16,
  },
});

const reportColumns = [
  { key: 'ContractId', header: 'ContractId' },
  { key: 'PurchaseDate', header: 'PurchaseDate' },
  { key: 'RetailerName', header: 'RetailerName' },
  { key: 'RetailerId', header: 'RetailerId' },
  { key: 'BranchName', header: 'BranchName' },
  { key: 'BranchId', header: 'BranchId' },
  { key: 'FirstName', header: 'FirstName' },
  { key: 'LastName', header: 'LastName' },
  { key: 'SalesPackage', header: 'SalesPackage' },
  { key: 'RetailPrice', header: 'RetailPrice' },
];

export const WeeklyEnrollmentReport = () => {
  const reportId = getReportIdByTitle('tw-retailer');
  const translate = useTranslate();
  const classes = useStyles();
  const [dates, setDates] = React.useState<Array<any>>([]);
  const { loaded, identity } = useGetIdentity();
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (loaded && identity?.id) {
        const result = await downloadReport(
          identity.id,
          format(dates[0], 'yyyy-MM-dd'),
          format(dates[1], 'yyyy-MM-dd')
        );

        const workbook = new Excel.Workbook();
        const reportWorkSheet = workbook.addWorksheet('Report');

        reportWorkSheet.columns = reportColumns;

        reportWorkSheet.addRows(result);

        const buff = await workbook.xlsx.writeBuffer();

        const blob = new Blob([buff], { type: 'application/vnd.ms-excel' });

        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute(
            'download',
            `report_${format(dates[0], 'yyyy-MM-dd')}-${format(
              dates[1],
              'yyyy-MM-dd'
            )}.xlsx`
          );
          link.style.visibility = 'none';
          document.body.appendChild(link);
          link.click();
          link.remove();

          setDialogMessage(translate('resources.reports.download.complete'));
        }
      }
    } catch (e) {
      console.error(e);
      setDialogMessage(translate('resources.reports.download.error'));
    } finally {
      setOpenDialog(true);
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.actions}>
            <DateRangePickerInput
              dates={dates}
              setDates={setDates}
              placeholder={'Dates'}
              label={'Dates'}
              source="dates"
            />
            <Button
              startIcon={loading && <CircularProgress size={12} />}
              type="submit"
              variant="contained"
              color="primary"
              disabled={dates.length < 1 || loading}
              style={{
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              {translate('asurion.common.form.actions.download')}
            </Button>
          </form>
        )}
      </Form>
      <OwnEmbeddedReport reportId={reportId} />
      <Dialog open={openDialog}>
        <DialogTitle>{dialogMessage}</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(false)}
            style={{
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            {translate('ra.action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WeeklyEnrollmentReport;

import React, { MouseEventHandler, useState } from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { colors, SIZES } from '../../themes/default';
import { Link } from 'react-router-dom';
import { ConfirmSubmit } from './Dialog/ConfirmSubmit';
import axios from 'axios';
import { useTranslate } from 'ra-core';

interface PendingStatusChipProps {
  id: string;
  label: string;
  email: string;
  withPasswordSetup?: boolean;
  inert?: boolean;
  showSetupLink?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chipRoot: {
    display: 'flex',
    alignItems: 'center',
    color: colors.brand.white,
    backgroundColor: colors.neutral.deep,
    height: (props: Partial<PendingStatusChipProps>) =>
      props.withPasswordSetup ? 18 : 24,
    minWidth: 80,
  },
  chipLabel: {
    fontSize: SIZES.font.small.size,
    lineHeight: SIZES.font.small.lineHeight,
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'initial',
    fontSize: 11,
    color: colors.brand.deepPurple,
    lineHeight: '24px',
  },
}));

export const PendingStatusChip = ({
  id,
  label,
  email: userEmail,
  withPasswordSetup = false,
  inert = true,
  showSetupLink = false,
  onClick = () => {},
}: PendingStatusChipProps) => {
  const translate = useTranslate();
  const classes = useStyle({ showSetupLink });
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const handleActivationRequest = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setShowConfirmDialog(true);
  };

  const handleClose = async (confirmSubmit: boolean = false) => {
    if (confirmSubmit) {
      await axios.post(`${process.env.REACT_APP_ADMIN_API_URL}/activation`, {
        id,
        userEmail,
        withPasswordSetup,
      });
    }
    setShowConfirmDialog(false);
  };

  return (
    <div className={classes.container}>
      <Chip
        label={label}
        onClick={inert ? undefined : onClick}
        classes={{
          root: classes.chipRoot,
          label: classes.chipLabel,
        }}
      />
      {showSetupLink && (
        <Link to="#" className={classes.link} onClick={handleActivationRequest}>
          {translate('asurion.common.form.activation_link')}
        </Link>
      )}
      <ConfirmSubmit open={showConfirmDialog} onClose={handleClose} />
    </div>
  );
};

export default PendingStatusChip;

import axios from 'axios';
import { createHash } from 'crypto';
// import { API_TOKEN } from '../../../providers/authProvider';

export const emailUnicity = async (
  input: string,
  _initialObject: any,
  formProps: any
) => {
  const { initial = '', value = '' } = formProps;
  if (initial && initial === value) {
    return undefined;
  }
  try {
    const url = `${process.env.REACT_APP_SECURITY_API_URL}/security/emailCheck`;
    const isEmailUnique = await axios.post(
      url,
      {
        e: createHash('md5').update(input).digest('hex'),
      }
      // {
      //   headers: {
      //     authtoken: sessionStorage.getItem(API_TOKEN),
      //   },
      // }
    );
    if (isEmailUnique) {
      return undefined;
    }
  } catch (e) {
    // // You can return a translation key as well
    // return 'myroot.validation.email_already_used';
    return 'Email already used';
  }
};

import React from 'react';
import { Typography } from '@material-ui/core';
import {
  Edit,
  EditProps,
  email,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import AsurionForm from '../common/Form/AsurionForm';
import AsurionAddress from '../common/Form/Collection/AsurionAddress';
import ContactNumber from '../common/Field/ContactNumber';
import { decorateWithRole } from '../../common/functions';
import { PartnerStores } from './types';
import { ROUTES } from '../../routes';

export const SalesPartnersEdit: React.FC<EditProps> = (props) => {
  const { identity, loaded } = useGetIdentity();
  const translate = useTranslate();
  const transform = (data: any) => {
    return {
      ...data,
      ...decorateWithRole<PartnerStores>(
        identity,
        'PARTNER_ADMIN',
        'salesPartnerId'
      ),
    };
  };

  return loaded ? (
    <Edit
      {...props}
      actions={false}
      mutationMode="pessimistic"
      transform={transform}
    >
      <AsurionForm
        title={translate('resources.partner-stores.edit_title')}
        BackLinkProps={{
          to: {
            pathname: ROUTES.PARTNER_STORES.path,
          },
        }}
      >
        <Typography variant="h2">
          {translate('asurion.common.form.sections.store_details')}
        </Typography>

        <TextInput
          placeholder="e.g. Public Company Limited"
          source="partnerStoreName"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <ContactNumber
          source="contactNumber"
          placeholder="08-1234-5678"
          className="required"
        />

        {identity?.role !== 'PARTNER_ADMIN' && (
          <ReferenceInput
            label="Assign to Partner"
            source="salesPartnerId"
            reference="sales-partners"
            fullWidth
            validate={required()}
            className="required"
            variant="outlined"
            disabled
          >
            <SelectInput optionText="partnerName" />
          </ReferenceInput>
        )}
        <TextInput
          placeholder={translate(
            'resources.partner-stores.branchName_placeholder'
          )}
          sou
          source="branchName"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />

        <Typography variant="h2">
          {translate('asurion.common.form.sections.address')}
        </Typography>
        <AsurionAddress
          readonlyFields={['address.businessName', 'address.countryCode']}
        />

        <Typography variant="h2">
          {translate('resources.partner-stores.fields.contactPerson1')}
        </Typography>
        <TextInput
          label="resources.partner-stores.fields.contactPerson1_alt"
          placeholder="e.g John Smith"
          source="contactPerson1"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <TextInput
          label="resources.partner-stores.fields.email1_alt"
          placeholder="e.g johnsmith@gmail.com"
          source="email1"
          fullWidth
          validate={[required(), email()]}
          className="required"
          variant="outlined"
        />
        <ContactNumber
          label="resources.partner-stores.fields.contactNumber1_alt2"
          source="contactNumber1"
          className="required"
        />
        <TextInput
          label="resources.partner-stores.fields.contactPerson2"
          placeholder="e.g John Smith"
          source="contactPerson2"
          fullWidth
          variant="outlined"
        />
        <TextInput
          label="resources.partner-stores.fields.email2"
          placeholder="e.g johnsmith@gmail.com"
          source="email2"
          fullWidth
          variant="outlined"
        />
        <ContactNumber
          label="resources.partner-stores.fields.contactNumber2"
          source="contactNumber2"
          validate={undefined}
        />
      </AsurionForm>
    </Edit>
  ) : null;
};
export default SalesPartnersEdit;

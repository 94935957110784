import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Button, useRecordContext, useUpdate } from 'react-admin';
import { ACTIVE_IND } from '../../../common/types';
import { stopPropagation } from '../../../common/functions';
import { useEffect } from 'react';

const useStyles = makeStyles({
  actions: {
    justifyContent: 'flex-start',
    padding: '0 0 40px 0',
  },
  dialogTitleRoot: {
    marginBottom: 20,
  },
  dialogContentRoot: {
    padding: 0,
    '& p': {
      marginBottom: '1em',
    },
  },
  dialogPaper: {
    maxWidth: 360,
  },
});

export type Props = DialogProps & {
  record: ReturnType<typeof useRecordContext>;
  source: string;
  fromStatus?: ACTIVE_IND;
  toStatus?: ACTIVE_IND;
  dialogTitle?: React.ReactNode;
  handleClose?: (result: ACTIVE_IND) => void;
};

export const ActivateUser = ({
  open,
  source,
  record,
  fromStatus = 'inactive',
  toStatus = 'active',
  handleClose = (_result) => {},
  dialogTitle,
  children,
}: Props) => {
  const [activate, { data, loading, loaded }] = useUpdate(
    source,
    record.id,
    {
      ...record,
      activeInd: toStatus,
    },
    record
  );

  useEffect(() => {
    if (!loading && loaded) {
      handleClose(data?.activeInd || toStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loaded]);

  const onClose = (e: any) => {
    stopPropagation(e);
    handleClose(fromStatus);
  };

  const handleActivate = (e: any) => {
    stopPropagation(e);
    activate();
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="default"
          label="ra.action.cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          label="ra.action.confirm"
          onClick={handleActivate}
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ActivateUser;

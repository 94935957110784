import * as React from 'react';
import { Create, CreateProps, required, TextInput } from 'react-admin';
import { useSuccessHandler } from '../../common/hooks';
import { useCountryId } from '../../contexts/CountryContext';
import AsurionForm from '../common/Form/AsurionForm';
import CountrySelector from '../common/Form/Collection/CountrySelector';

export const BusinessPartnersCreate = (props: CreateProps) => {
  const { onSuccessHandler } = useSuccessHandler(
    'Business Partner',
    props.basePath
  );
  const { countryId } = useCountryId();
  const transform = (data: any) => ({
    ...data,
    countryId,
    status: 'Pending',
  });

  return (
    <Create {...props} transform={transform} onSuccess={onSuccessHandler}>
      <AsurionForm title="New Business Partner" redirect="list">
        <TextInput
          label="Business Partner Name"
          placeholder="e.g. Public Company Limited"
          source="name"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <CountrySelector source="countryTlId" label="Country" />
      </AsurionForm>
    </Create>
  );
};

export default BusinessPartnersCreate;

import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import {
  Button,
  downloadCSV,
  ExportButton,
  ListActionsProps,
  sanitizeListRestProps,
  TopToolbar,
  useGetIdentity,
  useListContext,
  usePermissions,
} from 'react-admin';

import { formatDateToLocalTime, isFinanceUser } from '../../common/util';
import { colors } from '../../themes/default';

import jsonExport from 'jsonexport/dist';

const defaultCSVHeaders = new Map([
  ['agreementRequestId', 'Agreement Request ID'],
  ['billingOrderNumber', 'Billing Order Number'],
  ['policyNumber', 'Policy Number'],
  ['payoutBatchId', 'Payout Batch ID'],
  ['incentiveCalculationId', 'Incentive ID'],
  ['invoiceId', 'Invoice ID'],
  ['enrollmentDate', 'Enrollment Date'],
  ['paymentDate', 'Payment Date'],
  ['salesPerson', 'Sales Person'],
  ['store', 'Store'],
  ['partnerCode', 'Partner Code'],
  ['tier', 'Tier'],
  ['plan', 'Plan'],
  ['paymentStatus', 'Payment Status'],
  ['incentiveStatus', 'Incentive Status'],
  ['subscriptionFee', 'Subscription Fees'],
  ['discount', 'Discount'],
  ['discountedSubscriptionFee', 'Discounted Subscription Fee'],
  ['incentive', 'Incentive'],
]);

interface IncentiveActionsProps {
  onSubmit: (d: any, f?: any) => void;
}

export const useStyles = makeStyles({
  incentiveActions: {
    gap: 12,
    paddingTop: '32px',
  },
  incentiveExport: {
    backgroundColor: '#6EFAC3',
    color: colors.brand.black,
    '&:hover': {
      backgroundColor: '#99fbd5',
    },
    '& span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
      textOverflow: 'ellipsis',
    },
  },
  incentiveSubmitPayout: {
    backgroundColor: '#8223D2',
    color: colors.brand.white,
    '&:hover': {
      backgroundColor: '#a44fd1',
    },
  },
});

export const AdminDefaultIncentiveExporter = (
  incentives: any,
  resource: any
) => {
  const exportedData = incentives.map((incentiveEntry: any) => {
    const {
      id,
      storeId,
      partnerId,
      salesPersonId,
      mobileNumber,
      partner,
      salesPersonStatus,
      enrollmentDate,
      paymentDate,
      incentivePercentage,
      isRecurring,
      monthlyEnrollmentReferenceId,
      payoutBatchId,
      incentiveCalculationId,
      invoiceId,
      billingOrderNumber,
      policyNumber,
      ...rest
    } = incentiveEntry;

    const formattedEnrollmentDate = formatDateToLocalTime(enrollmentDate);
    const formattedPaymentDate = formatDateToLocalTime(paymentDate);

    return {
      enrollmentDate: formattedEnrollmentDate,
      paymentDate: formattedPaymentDate,
      ...rest,
    };
  });

  const adminHeaders = new Map(defaultCSVHeaders);
  adminHeaders.delete('billingOrderNumber');
  adminHeaders.delete('policyNumber');
  adminHeaders.delete('payoutBatchId');
  adminHeaders.delete('incentiveCalculationId');
  adminHeaders.delete('invoiceId');

  exportCSV(exportedData, adminHeaders);
};

export const FinanceUserIncentiveExporter = (
  incentives: any,
  resource: any
) => {
  const exportedData = incentives.map((incentiveEntry: any) => {
    const {
      id,
      storeId,
      partnerId,
      salesPersonId,
      mobileNumber,
      partner,
      salesPersonStatus,
      enrollmentDate,
      paymentDate,
      incentivePercentage,
      isRecurring,
      monthlyEnrollmentReferenceId,
      ...rest
    } = incentiveEntry;

    const formattedEnrollmentDate = formatDateToLocalTime(enrollmentDate);
    const formattedPaymentDate = formatDateToLocalTime(paymentDate);

    return {
      enrollmentDate: formattedEnrollmentDate,
      paymentDate: formattedPaymentDate,
      ...rest,
    };
  });

  exportCSV(exportedData, defaultCSVHeaders);
};

export const PartnerAdminIncentiveExporter = (
  incentives: any,
  resource: any
) => {
  const exportedData = incentives.map((incentiveEntry: any) => {
    const {
      id,
      storeId,
      partnerId,
      salesPersonId,
      mobileNumber,
      partner,
      partnerCode,
      salesPersonStatus,
      enrollmentDate,
      paymentDate,
      incentive,
      incentiveStatus,
      incentivePercentage,
      isRecurring,
      monthlyEnrollmentReferenceId,
      payoutBatchId,
      incentiveCalculationId,
      invoiceId,
      billingOrderNumber,
      policyNumber,
      ...rest
    } = incentiveEntry;

    const formattedEnrollmentDate = formatDateToLocalTime(enrollmentDate);
    const formattedPaymentDate = formatDateToLocalTime(paymentDate);

    return {
      enrollmentDate: formattedEnrollmentDate,
      paymentDate: formattedPaymentDate,
      ...rest,
    };
  });

  const adminHeaders = new Map(defaultCSVHeaders);
  adminHeaders.delete('partnerCode');
  adminHeaders.delete('billingOrderNumber');
  adminHeaders.delete('policyNumber');
  adminHeaders.delete('payoutBatchId');
  adminHeaders.delete('incentiveCalculationId');
  adminHeaders.delete('invoiceId');
  adminHeaders.delete('incentiveStatus');
  adminHeaders.delete('incentive');

  exportCSV(exportedData, adminHeaders);
};

const exportCSV = (exportedData: any, headersMap: Map<string, string>) => {
  jsonExport(
    exportedData,
    {
      headers: Array.from(headersMap.keys()),
      rename: Array.from(headersMap.values()),
    },
    (err: any, csv: any) => {
      if (err) {
        console.error(err);
      } else {
        csv = '\ufeff' + csv; // For UTF-8 encoding
        downloadCSV(csv, 'incentives');
      }
    }
  );
};

export const IncentiveActions: FC<IncentiveActionsProps & ListActionsProps> = ({
  className,
  filters,
  ...rest
}) => {
  const classes = useStyles();
  const { identity } = useGetIdentity();
  const { permissions = [] } = usePermissions();
  const { total, data, filterValues } = useListContext();

  const { onSubmit } = rest;

  const handleCalculate = () => {
    // console.log(filterValues);
    onSubmit(data, filterValues);
  };

  return (
    <TopToolbar
      className={classes.incentiveActions}
      {...sanitizeListRestProps(rest)}
    >
      <ExportButton className={classes.incentiveExport} icon={<span />} />
      {isFinanceUser(identity) &&
        permissions.includes('calculate_incentives') && (
          <Button
            className={classes.incentiveSubmitPayout}
            size="large"
            icon={<span />}
            label="Calculate"
            disabled={total === 0}
            onClick={handleCalculate}
          />
        )}
    </TopToolbar>
  );
};

import * as React from 'react';
import { format } from 'date-fns';
import { subMonths } from 'date-fns/esm';
import { useEffect, useState } from 'react';
import {
  ReferenceInput,
  SelectInput,
  useGetIdentity,
  usePermissions,
} from 'react-admin';
import { useCountryId } from '../../../../contexts/CountryContext';
import { useSalesPartnerId } from '../../../../contexts/SalesPartnerContext';
import AsurionSearchForm from '../../../common/Form/AsurionSearchForm';
import { noop } from '../../../../common/functions';

export interface MonthYear {
  id: string;
  name: string;
}

export const PartnerSalesPerformance = () => {
  const { permissions = [] } = usePermissions();
  const { identity } = useGetIdentity();
  const { countryId } = useCountryId();
  const { salesPartnerId } = useSalesPartnerId();
  const [monthYearSelection, setMonthYearSelection] = useState<
    Array<MonthYear>
  >([]);

  useEffect(() => {
    const now = new Date();
    let intervals = new Array(12).fill(undefined).map((_i, i) => {
      const m = subMonths(now, i);
      return {
        id: format(m, 'y-M'),
        name: format(m, 'LLLL y'),
      };
    });
    setMonthYearSelection(intervals);
  }, []);

  let storeFilter: any = {
    countryId,
    activeIndStatus: 'active',
  };

  if (!permissions.includes('is_super_admin')) {
    if (identity?.role === 'SALES_MGR') {
      storeFilter = {
        ...storeFilter,
        salesManagerId: identity?.id,
      };
    }

    if (identity?.extra.salesPartnerId) {
      storeFilter = {
        ...storeFilter,
        salesPartnerId: identity?.extra.salesPartnerId,
      };
    }
  }

  if (salesPartnerId) {
    storeFilter = {
      ...storeFilter,
      salesPartnerId,
    };
  }

  return (
    <>
      <AsurionSearchForm
        onSubmit={noop}
        onReset={(_values, form) => form.reset()}
      >
        <ReferenceInput
          label="Store"
          source="partnerStoreId"
          reference="partner-stores"
          fullWidth
          filter={storeFilter}
          variant="outlined"
        >
          <SelectInput optionText="partnerStoreName" />
        </ReferenceInput>
        <SelectInput
          label="Month and Year"
          source="selectedMonthYear"
          choices={monthYearSelection}
          variant="outlined"
        />
      </AsurionSearchForm>
    </>
  );
};

export default PartnerSalesPerformance;

import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const GlobalCss = withStyles((theme: Theme) => ({
  '@global': {
    '.required, [class*=makeStyles-requiredContainer]': {
      '& .MuiFormLabel-root:not(.Mui-disabled) span': {
        background: 'linear-gradient(to left, red 0% 1ch, black 10px 10px)',
        '-webkit-background-clip': 'text',
        color: 'transparent',
      },
    },
  },
}))(() => null);

export default GlobalCss;

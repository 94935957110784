import PartnerAdminsList from './PartnerAdminsList';
import PartnerAdminsCreate from './PartnerAdminsCreate';
import PartnerAdminsEdit from './PartnerAdminsEdit';

const resourceMapping = {
  list: PartnerAdminsList,
  create: PartnerAdminsCreate,
  edit: PartnerAdminsEdit,
};

export default resourceMapping;

import { Link } from '@material-ui/core';
import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  List,
  ListProps,
  TextInput,
} from 'react-admin';
import ListActions from '../overrides/ListActions';
import CountryManagerQuickPreviewDialog from './CountryManagerQuickPreviewDialog';
import { ActiveIndStatusField } from '../common/Field/ActiveIndStatusField';
import { EditIconButton } from '../common/Form/Buttons/EditIconButton';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import ActiveStatusSelectInput from '../common/Form/Collection/ActiveIndStatusSelectInput';
import { useCountryId } from '../../contexts/CountryContext';

const CountryManagerFilter = () => {
  return (
    <AsurionSearchForm>
      <TextInput
        label="Name"
        placeholder="Search Name"
        source="name"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label="Email Address"
        placeholder="Search Email Address"
        source="userEmail"
        fullWidth
        variant="outlined"
      />
      <ActiveStatusSelectInput />
    </AsurionSearchForm>
  );
};

export const CountryManagerList: React.FC<ListProps> = (props) => {
  const { countryId } = useCountryId();
  const [openQuickPreviewDialog, setOpenQuickPreviewDialog] = useState<boolean>(
    false
  );
  const [selectedCountryManagerId, setSelectedCountryManagerId] = useState<
    string | null
  >(null);

  const handleQuickPreviewDialogClose = () => {
    setOpenQuickPreviewDialog(false);
  };

  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={<ListActions />}
        filter={{
          countryId,
        }}
        filters={<CountryManagerFilter />}
        pagination={false}
      >
        <Datagrid>
          <FunctionField
            label="Name"
            sortBy="firstName"
            render={(record: any) => {
              return (
                <Link
                  onClick={() => {
                    setSelectedCountryManagerId(record.salesStoreUserId);
                    setOpenQuickPreviewDialog(true);
                  }}
                >
                  {record.firstName} {record.lastName}
                </Link>
              );
            }}
          />
          <EmailField
            source="userEmail"
            label="Email Address"
            sortable={false}
          />
          {/* Hack to use a locale with yyyy-mm-dd since Datefield only uses locales to format days */}
          <DateField
            label="Date Created"
            source="createdDate"
            locales="sv-se"
          />
          <EditIconButton label="asurion.common.table.fields.action" />
          <ActiveIndStatusField textAlign="center" inert={false} />
        </Datagrid>
      </List>
      {selectedCountryManagerId && (
        <CountryManagerQuickPreviewDialog
          id={selectedCountryManagerId}
          open={openQuickPreviewDialog}
          onClose={handleQuickPreviewDialogClose}
          editPermission="update_user_country_manager"
        />
      )}
    </>
  );
};

export default CountryManagerList;

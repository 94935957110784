import axios, { AxiosResponse } from 'axios';
import { createHash } from 'crypto';

export const contactNumberUnicity = async (
  input: string,
  _initialObject: any,
  formProps: any
) => {
  const { initial = '', value = '' } = formProps;
  if (initial && initial === value) {
    return undefined;
  }
  try {
    const url = `${process.env.REACT_APP_SECURITY_API_V2_URL}/security/checkMobile`;
    const contactNumberExists = await axios.get(url, {
      params: {
        u: createHash('md5').update(input).digest('hex'),
        check: true,
      },
    });
    if (contactNumberExists) {
      return;
    }
  } catch (e) {
    const response = (e as any).response as AxiosResponse;
    if (response.status === 401) {
      return 'asurion.common.form.contact_number.validation.already_exists';
    }
    if (response.status === 404) {
      return undefined;
    }
    return 'ra.notification.http_error';
  }
};

import React from 'react';
import { ReferenceField, TextField, useGetIdentity } from 'react-admin';
import { PartnerAdmin } from './types';
import QuickPreviewDialog from '../common/Dialog/QuickPreviewDialog';

interface Props {
  id: PartnerAdmin['salesStoreUserId'];
  open: boolean;
  editPermission?: string | Array<string>;
  onClose?: () => any;
}

const resourceName = 'partner-admins';

const PartnerAdminsQuickPreviewDialog: React.FC<Props> = ({
  id,
  open,
  editPermission = [],
  onClose = () => {},
}) => {
  const { identity } = useGetIdentity();
  const title = (data: PartnerAdmin) => {
    return `${data.firstName} ${data.lastName} `;
  };

  return (
    <QuickPreviewDialog
      id={id as string}
      open={open}
      onClose={onClose}
      resourceName={resourceName}
      title={title}
      editPermission={editPermission}
    >
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="nickname" />
      <TextField source="userEmail" />
      <TextField source="contactNumber" />
      {identity?.role !== 'PARTNER_ADMIN' && (
        <ReferenceField
          label="resources.partner-admins.fields.partnerAccount"
          source="salesPartnerId"
          reference="sales-partners"
          link={false}
        >
          <TextField source="partnerName" />
        </ReferenceField>
      )}
    </QuickPreviewDialog>
  );
};

export default PartnerAdminsQuickPreviewDialog;

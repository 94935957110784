import React from 'react';
import { createTheme } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { LayoutProps } from 'ra-ui-materialui';
import { Notification } from 'react-admin';
import DefaultAppBar from './DefaultAppBar';
import SecondaryNavigation from './SecondaryNavigation';
import { GlobalCss } from '../themes/GlobalCss';
import { ThemeConfigProvider } from '../contexts/ThemeContext';
import { CountryProvider } from '../contexts/CountryContext';
import { SalesPartnerProvider } from '../contexts/SalesPartnerContext';
import { DoneModalProvider } from '../contexts/DoneModalContext';
import { NavigationProvider } from '../contexts/NavigationContext';
import { ProfileProvider } from '../contexts/ProfileContext';

const useStyles = makeStyles({
  root: {},
  main: {
    marginTop: 60,
  },
});

const DefaultLayout: React.FC<LayoutProps> = ({
  theme: _theme,
  children,
  ...restProps
}) => {
  const theme = createTheme(_theme);
  const classes = useStyles();
  return (
    <ThemeConfigProvider neutralBackground={false}>
      <ThemeProvider theme={theme}>
        <GlobalCss />

        <CountryProvider>
          <SalesPartnerProvider>
            <DoneModalProvider>
              <ProfileProvider>
                <div className={classes.root}>
                  <NavigationProvider>
                    <DefaultAppBar />
                    <SecondaryNavigation />
                  </NavigationProvider>
                  <main className={classes.main}>
                    <div>{children}</div>
                  </main>
                  <Notification />
                </div>
              </ProfileProvider>
            </DoneModalProvider>
          </SalesPartnerProvider>
        </CountryProvider>
      </ThemeProvider>
    </ThemeConfigProvider>
  );
};

export default DefaultLayout;

import PartnerStoresList from './PartnerStoresList';
import SalesPartnersCreate from './PartnerStoresCreate';
import SalesPartnersEdit from './PartnerStoresEdit';
import SalesPartnersShow from './PartnerStoresShow';

const resourceMapping = {
  list: PartnerStoresList,
  create: SalesPartnersCreate,
  edit: SalesPartnersEdit,
  show: SalesPartnersShow,
};

export default resourceMapping;

import { createTheme, lighten } from '@material-ui/core';
import { RaThemeOptions } from 'react-admin';

export const colors = {
  brand: {
    black: '#000000',
    brightBlue: '#4A68F9',
    deepPurple: '#8223D2',
    green: '#6EFAC3',
    white: '#FFFFFF',
    red: '#DB1B1B',
  },
  neutral: {
    bright: '#F0F0F5',
    brighter: '#E6E6EB',
    brightest: '#D5D6Da',
    deep: '#A5AAAF',
    deeper: '#6E767D',
    deepest: '#333F48',
  },
  extra: {
    indicator: '#77E6BE',
    table: {
      border: '#979797',
      divider: '#E9E9E9',
      header: '#F7F7F7',
    },
    page: {
      background: '#f5f5f5',
    },
  },
};

export const SIZES = {
  font: {
    small: {
      size: 10,
      lineHeight: '12px',
    },
    medium: {
      size: 14,
      lineHeight: '16px',
    },
  },
};

export const defaultTheme = createTheme({});

export const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: ['Apercu Pro', 'sans-serif'].join(','),
    h2: {
      fontSize: 24,
      marginBottom: 20,
    },
  },

  // Nuke shadows
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiPopover: {
      elevation: 1,
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        padding: 0,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 4px 3px rgb(0 0 0 / 10%)',
        borderRadius: 8,
        border: '1px solid rgba(0, 0, 0, 0.23)',
      },
    },
    MuiButton: {
      root: {
        height: 35,
        borderRadius: 18,
        padding: 0,
        border: 0,
        textTransform: 'capitalize',
        display: 'inline-flex',
        fontSize: 14,
      },
      containedPrimary: {
        backgroundColor: colors.brand.green,
        color: colors.brand.black,
        '&:hover': {
          backgroundColor: lighten(colors.brand.green, 0.3),
        },
      },
      containedSecondary: {
        backgroundColor: colors.brand.black,
        color: colors.brand.white,
        '&:hover': {
          backgroundColor: lighten(colors.brand.black, 0.3),
        },
      },
      containedSizeSmall: {
        padding: `0 ${SIZES.font.medium.size}px`,
        fontSize: SIZES.font.medium.size,
      },
      text: {
        color: colors.brand.brightBlue,
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          fontWeight: 'bold',
        },
      },
      textColorInherit: {
        color: colors.brand.white,
        opacity: 1,
        textTransform: 'initial',
        fontWeight: 'normal',
      },
      textColorPrimary: {
        color: colors.brand.black,
        textTransform: 'initial',
      },
    },
    MuiPopover: {
      paper: {
        border: `1px solid ${colors.neutral.brightest}`,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.brand.white,
        margin: '12px 0',
      },
    },
    MuiInputBase: {
      input: {
        padding: '12px 8px 12px 12px',
        fontSize: 12,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '12px 8px 12px 12px',
        fontSize: 12,
        lineHeight: '12px',
        background: colors.brand.white,
        border: colors.neutral.brighter,
        borderRadius: 4,
      },
      adornedEnd: {
        background: colors.brand.white,
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.brand.black,
        fontSize: 14,
        lineHeight: '21px',
      },
      outlined: {
        '&.MuiInputLabel-shrink': {
          transform: 'none',
          display: 'block',
          position: 'relative',
        },
      },
      asterisk: {
        color: colors.brand.red,
      },
    },
    MuiDialog: {
      paper: {
        minWidth: 370,
        paddingLeft: 29,
        paddingRight: 31,
        borderRadius: 10,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        paddingTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
      },
    },
    MuiIconButton: {
      root: {
        margin: 0,
        marginLeft: 10,
        marginRight: 10,
      },
      colorPrimary: {
        backgroundColor: colors.brand.green,
        color: colors.brand.black,
        '&:hover': {
          backgroundColor: lighten(colors.brand.green, 0.3),
        },
      },
    },
    MuiTable: {
      root: {
        border: 'none',
      },
    },
    MuiTableCell: {
      head: {
        lineHeight: 'unset',
      },
    },
    MuiAlert: {
      root: {
        width: '100%',
      },
    },
    MuiGrid: {
      item: {
        '& > .inline-field:last-child, .inline-field:first-child': {
          padding: 0,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
      img: {
        width: 32,
        height: 32,
      },
    },
    MuiCheckbox: {
      root: {
        margin: 0,
        '&:hover': {
          background: 'transparent',
        },
      },
      colorPrimary: {
        backgroundColor: 'transparent',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        lineHeight: '21px',
      },
    },

    RaDatagrid: {
      thead: {
        borderBottom: `0.5px solid ${colors.extra.table.border}`,
      },
      headerRow: {
        '& > th': {
          backgroundColor: colors.extra.table.header,
          fontWeight: 700,
        },
      },
      // .RaDatagrid-rowCell
      row: {
        '&:last-child > *': {
          borderBottom: 'none',
        },
      },
    },

    RaShow: {
      root: {
        maxWidth: 600,
        margin: '0 auto',
      },
      card: {
        border: 'none',
        boxShadow: 'none',
      },
    },
    RaButton: {
      label: {
        paddingLeft: 0,
      },
    },
    RaLabeled: {
      label: {
        fontSize: 14,
        lineHeight: '150%',
        color: colors.neutral.deeper,
      },
    },
    RaFormInput: {
      input: {
        width: '100%',
      },
    },
    RaList: {
      content: {
        border: `0.5px solid ${colors.extra.table.border}`,
        borderRadius: 0,
      },
    },
    RaCardContentInner: {
      root: {
        '&:last-child': {
          paddingBottom: 20,
        },
      },
    },
    RaAutocompleteInput: {
      clearButton: {
        marginRight: 0,
      },
    },
    // RaAutocompleteSuggestionList: {
    //   suggestionsPaper: {
    //     boxShadow: '0px 4px 3px rgb(0 0 0 / 10%)',
    //     borderRadius: 8,
    //     border: '1px solid rgba(0, 0, 0, 0.23)',
    //   },
    // },
    RaAutocompleteArrayInput: {
      inputRoot: {
        paddingLeft: 4,
      },
      chipContainerOutlined: {
        margin: 4,
      },
    },
    RaEdit: {
      card: {
        boxShadow: 'none',
        border: 'none',
      },
    },
    RaCreate: {
      card: {
        boxShadow: 'none',
        border: 'none',
      },
    },
  },
} as RaThemeOptions);

export default theme;

import '@calvear/rsuite-styles';
import { makeStyles } from '@material-ui/styles';

import countries from 'i18n-iso-countries';

import { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Datagrid,
  Empty,
  FunctionField,
  List,
  ListActionsProps,
  ListProps,
  Pagination,
  PaginationProps,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useGetIdentity,
  useListContext,
  useNotify,
  usePermissions,
} from 'react-admin';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { PAYOUT_BATCH_ID } from '../../common/constants';
import { noop, notifyError } from '../../common/functions';
import {
  formatDateToLocalTime,
  getTimezoneFromCountryCode,
  getBrowserDefaultTimezone,
  isFinanceUser,
  uuidUncanonical,
} from '../../common/util';

import { ROUTES } from '../../routes';
import { getPendingIncentiveCalculation } from '../../services/apiService';
import { colors } from '../../themes/default';

import InfoPopover from '../common/Dialog/InfoPopover';
import ActionableInfoField from '../common/Field/ActionableInfoField';
import { useStyles as useFormStyles } from '../common/Form/AsurionSearchForm';
import { useStyles as useIncentiveStyles } from '../incentives/IncentiveFilter';
import DateRangePickerInput from '../common/Form/DateRangePickerInput';
import PageContainer from '../common/Scaffolding/PageContainer';

import {
  ConfirmCancelDialog,
  ConfirmPayoutDialog,
  DoneCancelDialog,
  DonePayoutDialog,
} from './IncentiveCalculationDialogs';
import {
  cancelIncentivesCalculation,
  payoutIncentives,
} from './IncentiveService';

export const useStyles = makeStyles({
  incentiveActions: {
    gap: 12,
    paddingTop: '30px',
  },
  incentiveExport: {
    backgroundColor: '#6EFAC3',
    color: colors.brand.black,
    '&:hover': {
      backgroundColor: '#99fbd5',
    },
  },
  incentiveSubmitPayout: {
    backgroundColor: '#8223D2',
    color: colors.brand.white,
    '&:hover': {
      backgroundColor: '#a44fd1',
    },
  },
  disabledInputWidth: {
    minWidth: '250px',
  },
});

interface IncentiveCalculationActionsProps {
  onSubmit: (d: any, f?: any) => void;
  onCancel: (d: any, f?: any) => void;
  showActions: boolean;
}

const IncentiveCalculationPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const IncentiveCalculationActions: FC<
  IncentiveCalculationActionsProps & ListActionsProps
> = ({ className, filters, ...rest }) => {
  const classes = useStyles();
  const { identity } = useGetIdentity();
  const { permissions = [] } = usePermissions();
  const { data } = useListContext();

  const { onSubmit, onCancel, showActions } = rest;

  return (
    <TopToolbar
      className={classes.incentiveActions}
      {...sanitizeListRestProps(rest)}
    >
      {showActions && (
        <Button
          label="Cancel"
          variant="contained"
          color="default"
          onClick={onCancel}
        />
      )}
      {showActions &&
        isFinanceUser(identity) &&
        permissions.includes('payout_incentives') && (
          <Button
            className={classes.incentiveSubmitPayout}
            size="large"
            icon={<span />}
            label="Payout"
            onClick={() => onSubmit(data)}
          />
        )}
    </TopToolbar>
  );
};

const DisabledFilter = (props: any) => {
  const defaultFormClass = useFormStyles();
  const customFormClass = useIncentiveStyles();
  const classes = useStyles();

  const { details, timezone } = props;

  const defaultValues = {
    payoutBatchId: uuidUncanonical(details.payoutBatchId),
    period: [
      formatDateToLocalTime(details.periodStartDate),
      formatDateToLocalTime(details.periodEndDate, timezone),
    ],
    payoutStatus: details.payoutStatus,
  };

  // let periodStartDate = formatDateToLocale(details.periodStartDate).toString();
  // console.log(periodStartDate);

  return (
    <div className={defaultFormClass.container}>
      <Form onSubmit={noop} initialValues={defaultValues}>
        {() => (
          <form
            onSubmit={noop}
            className={`${defaultFormClass.formRoot} ${customFormClass.incentivesFormRoot}`}
          >
            <TextInput
              className={classes.disabledInputWidth}
              label="Batch ID"
              placeholder="Incentive Calculation Batch ID"
              variant="outlined"
              source="payoutBatchId"
              initialValue={defaultValues.payoutBatchId}
              disabled={true}
            />
            <DateRangePickerInput
              dates={defaultValues.period}
              setDates={noop}
              label="Period"
              placeholder="Incentive Calculation Period"
              source="period"
              defaultValue={defaultValues.period}
              disabled={true}
            />
            {/* <TextInput
              label="Period"
              placeholder="Incentive Calculation Period"
              variant="outlined"
              source="period"
              initialValue={periodStartDate}
              disabled={true}
            /> */}
            <TextInput
              label="Status"
              placeholder="Payout Status"
              variant="outlined"
              source="payoutStatus"
              initialValue={defaultValues.payoutStatus}
              disabled={true}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

export const IncentivesCalculationList = (props: ListProps) => {
  const history = useHistory();
  const notify = useNotify();
  const { loaded, identity } = useGetIdentity();

  const [payoutBatchDetails, setPayoutBatchDetails] = useState<Object>({});

  const [showConfirmCancelDialog, setShowConfirmCancelDialog] =
    useState<boolean>(false);
  const [showConfirmPayoutDialog, setShowConfirmPayoutDialog] =
    useState<boolean>(false);
  const [showDoneCancelDialog, setShowDoneCancelDialog] =
    useState<boolean>(false);
  const [showDonePayoutDialog, setShowDonePayoutDialog] =
    useState<boolean>(false);

  const [showActions, setShowActions] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const payoutBatchId = localStorage.getItem(PAYOUT_BATCH_ID);
  const financeUserId = identity?.id;

  let countryCode = identity?.extra.countryId || 'tha';
  if (countryCode.length > 2) {
    let iso2 = countries.alpha3ToAlpha2(countryCode.toUpperCase());
    countryCode = iso2.toUpperCase();
  }

  const userDefaultTimezone =
    getTimezoneFromCountryCode(countryCode) || getBrowserDefaultTimezone();

  const defaultFilters = {
    financeUserId,
  };

  const handleSubmitPayout = useCallback(() => {
    setLoading(true);
    payoutIncentives(
      financeUserId,
      localStorage.getItem(PAYOUT_BATCH_ID),
      () => {
        setLoading(false);
        setShowActions(false);
        setShowConfirmPayoutDialog(false);
        setShowDonePayoutDialog(true);
      },
      (err) => {
        setLoading(false);
        notifyError(err, notify);
      }
    );
  }, [financeUserId, notify]);

  const handleSuccessPayout = () => {
    setShowDonePayoutDialog(false);
    history.push(ROUTES.INCENTIVES.path);
    localStorage.removeItem(PAYOUT_BATCH_ID);
  };

  const handleCancelCalculation = useCallback(() => {
    setLoading(true);
    cancelIncentivesCalculation(
      financeUserId,
      localStorage.getItem(PAYOUT_BATCH_ID),
      () => {
        setLoading(false);
        setShowConfirmCancelDialog(false);
        setShowDoneCancelDialog(true);
        localStorage.removeItem(PAYOUT_BATCH_ID);
      },
      (err) => {
        setLoading(false);
        notifyError(err, notify);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financeUserId, history, notify]);

  const handleSuccessCancel = () => {
    setShowDoneCancelDialog(false);
    history.push(ROUTES.INCENTIVES.path);
    localStorage.removeItem(PAYOUT_BATCH_ID);
  };

  const setIncentiveCalculationBatchDetails = (data: any) => {
    setPayoutBatchDetails((d) => Object.assign(d, data));
    // If Pending, must show actions. If not Pending, must hide actions.
    setShowActions(
      !(data && data.payoutStatus && data.payoutStatus !== 'Pending')
    );
  };

  const getIncentiveCalculationDetails = useCallback(async () => {
    await getPendingIncentiveCalculation(
      financeUserId,
      false,
      setIncentiveCalculationBatchDetails
    );
  }, [financeUserId]);

  const handleDates = (record: any) => {
    const firstDate = formatDateToLocalTime(
      record.enrollmentFirstDate,
      userDefaultTimezone
    ); // trimDate(record.enrollmentFirstDate);
    const lastDate = formatDateToLocalTime(
      record.enrollmentLastDate,
      userDefaultTimezone
    ); // trimDate(record.enrollmentLastDate);

    return firstDate === lastDate ? firstDate : `${firstDate} ~ ${lastDate}`;
  };

  // const handleLinks = (
  //   record: any,
  //   renderSource: string,
  //   linkTo: string,
  //   linkFilter: string,
  //   linkFilterSource: string
  // ) => {
  //   const redirect = `${linkTo}?filter={"${linkFilter}":"${record[linkFilterSource]}"}`;

  //   return <Link to={redirect}>{record[renderSource]}</Link>;
  // };

  useEffect(() => {
    if (financeUserId && payoutBatchId) {
      getIncentiveCalculationDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financeUserId, payoutBatchId]);

  return loaded && payoutBatchDetails && payoutBatchId ? (
    <PageContainer>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        filterDefaultValues={defaultFilters}
        filters={
          <DisabledFilter
            details={payoutBatchDetails}
            timezone={userDefaultTimezone}
          />
        }
        actions={
          <IncentiveCalculationActions
            onSubmit={() => setShowConfirmPayoutDialog(true)}
            onCancel={() => setShowConfirmCancelDialog(true)}
            showActions={showActions}
          />
        }
        pagination={<IncentiveCalculationPagination />}
        perPage={100}
        sort={{ field: 'salesPerson', order: 'desc' }}
      >
        <Datagrid size="medium">
          <InfoPopover label="Info">
            <ActionableInfoField
              label="Batch ID"
              source="payoutBatchId"
              copy={true}
            />
            <ActionableInfoField
              label="Incentive ID"
              source="incentiveId"
              copy={true}
              // navFilters={{"incentiveId":"incentiveCalculationId", "involvedPlans": "plan"}}
              // redirect={ROUTES.INCENTIVES.path}
            />
          </InfoPopover>
          <FunctionField
            label="Enrollment Dates"
            render={handleDates}
            sortable={false}
          />
          <TextField label="Sales Person" source="salesPerson" />
          <FunctionField
            label="Plan"
            render={(record: any) => record.involvedPlans.join(', ')}
            sortable={false}
          />
          <TextField label="Store" source="store" />
          <TextField label="Partner Code" source="partnerCode" />
          <TextField
            label="Incentive Status"
            source="incentiveStatus"
            sortable={false}
          />
          {/* <TextField textAlign="right" label="Enrollments" source="enrollmentCount" /> */}
          {/* <FunctionField
            textAlign="right"
            label="Enrollments"
            render={(record: any) =>
              handleLinks(record, 'enrollmentCount', ROUTES.INCENTIVES.path, 'incentiveCalculationId', 'id')
            }
            sortable={false}
          /> */}
          <TextField
            label="Enrollments"
            source="aggregatedCount"
            sortable={false}
          />
          {/* <FunctionField label="Plans" render={(record: any) => record.involvedPlans.join(', ')} sortable={false} /> */}
          <FunctionField
            textAlign="right"
            label="Total Incentives"
            render={(res: any) => `THB ${res.aggregatedAmount}`}
            sortable={false}
          />
        </Datagrid>
      </List>
      <ConfirmCancelDialog
        isOpen={showConfirmCancelDialog}
        onOk={handleCancelCalculation}
        onCancel={() => setShowConfirmCancelDialog(false)}
        identity={identity}
        loading={loading}
      />
      <ConfirmPayoutDialog
        isOpen={showConfirmPayoutDialog}
        onOk={handleSubmitPayout}
        onCancel={() => setShowConfirmPayoutDialog(false)}
        identity={identity}
        loading={loading}
      />
      <DoneCancelDialog
        isOpen={showDoneCancelDialog}
        onOk={handleSuccessCancel}
      />
      <DonePayoutDialog
        isOpen={showDonePayoutDialog}
        onOk={handleSuccessPayout}
      />
    </PageContainer>
  ) : (
    <Empty />
  );
};

export default IncentivesCalculationList;

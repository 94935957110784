import * as React from 'react';
import { Form } from 'react-final-form';
import { Button, Container, Typography, makeStyles } from '@material-ui/core';
import { CountrySelector } from '../common/Form/Collection/CountrySelector';
import { DEFAULT_COUNTRY_LIST } from '../../common/constants';
import { Flag } from '../common/Branding/Icons/Flag';
import { useHistory } from 'react-router-dom';
import { useCountry } from '../../contexts/CountryContext';
import { useSetTheme } from '../../contexts/ThemeContext';
import { useCallback, useEffect } from 'react';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles({
  header: {
    fontSize: 32,
  },
  containerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
    width: 430,
    marginTop: 120,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 430,
  },
  buttonRoot: {
    width: 120,
  },
});

export const CountrySelect = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setCountry } = useCountry();
  const { setHasMenu } = useSetTheme();
  const translate = useTranslate();

  const setUp = useCallback(() => {
    setHasMenu(false);
  }, [setHasMenu]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  const onSubmit = (value: any) => {
    const country = DEFAULT_COUNTRY_LIST.find(
      (country) => country.id === value.id
    );
    setCountry(country);
    // setLocale(country?.defaultLanguage || 'en');
    setHasMenu(true);
    history.push({ pathname: '/' });
  };

  return (
    <>
      <Container fixed classes={{ root: classes.containerRoot }}>
        <Flag />
        <Typography
          className={classes.header}
          variant="h3"
          component="h1"
          align="center"
        >
          Select Country
        </Typography>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form
              className={classes.formRoot}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <CountrySelector
                source="id"
                label={false}
                fullWidth
                disabled={false}
              />
              <Button
                classes={{ root: classes.buttonRoot }}
                type="submit"
                color="primary"
              >
                {translate('asurion.common.navigation.done')}
              </Button>
            </form>
          )}
        />
      </Container>
    </>
  );
};

export default CountrySelect;

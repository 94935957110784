import StoreManagersList from './StoreManagerList';
import StoreManagersCreate from './StoreManagersCreate';
import StoreManagersEdit from './StoreManagersEdit';

const resourceMapping = {
  list: StoreManagersList,
  create: StoreManagersCreate,
  edit: StoreManagersEdit,
};

export default resourceMapping;

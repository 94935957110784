import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ListButton, SaveButton, Toolbar, useTranslate } from 'react-admin';
import { ConfirmSubmit } from '../Dialog/ConfirmSubmit';
import { useFormState } from 'react-final-form';
import { colors } from '../../../themes/default';

const useStyles = makeStyles({
  toolbar: {
    background: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    gap: 10,
  },
  listButtonRoot: {
    backgroundColor: colors.neutral.brightest,
    width: 90,
    height: 35,
    '& [class*="MuiSvgIcon-root"]': {
      display: 'none',
    },
  },
  saveButtonRoot: {
    minWidth: 90,
    padding: '0 24px',
    height: 35,
    '& [class*="MuiSvgIcon-root"]': {
      display: 'none',
    },
  },
});

export const AsurionToolbar = (
  props: React.ComponentProps<typeof Toolbar> & {
    hasConfirm?: boolean;
    saveButtonLabel?: string;
    BackLinkProps?: any;
  }
) => {
  const {
    basePath,
    // handleSubmitWithRedirect,
    hasConfirm = true,
    saveButtonLabel = 'ra.action.save',
    BackLinkProps = {},
    ...restProps
  } = props;
  const translate = useTranslate();
  const [submitForm, setSubmitForm] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const formState = useFormState();

  useEffect(() => {
    const { handleSubmitWithRedirect } = props;
    if (
      submitForm &&
      handleSubmitWithRedirect &&
      !formState.hasValidationErrors
    ) {
      handleSubmitWithRedirect();
    } else {
      setSubmitForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  const handleClose = (confirmSubmitForm?: boolean) => {
    setShowConfirmDialog(false);
    if (confirmSubmitForm) {
      setSubmitForm(confirmSubmitForm);
    }
  };

  const classes = useStyles();
  return (
    <>
      <Toolbar {...restProps} className={classes.toolbar}>
        <ListButton
          label="asurion.common.form.cancel"
          variant="contained"
          className={classes.listButtonRoot}
          basePath={basePath}
          {...BackLinkProps}
        />
        <SaveButton
          label={
            formState.validating
              ? translate('asurion.common.action.validating')
              : saveButtonLabel
          }
          className={classes.saveButtonRoot}
          disabled={formState.validating}
          handleSubmitWithRedirect={() => {
            if (hasConfirm && !formState.hasValidationErrors) {
              setShowConfirmDialog(true);
            } else {
              setSubmitForm(true);
            }
          }}
        />
      </Toolbar>
      <ConfirmSubmit open={showConfirmDialog} onClose={handleClose} />
    </>
  );
};

export default AsurionToolbar;

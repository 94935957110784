import { CircularProgress, makeStyles, Typography } from '@material-ui/core';

import { useTranslate } from 'ra-core';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ACCESS_TOKEN, CODE } from '../../providers/authProvider';
import { ROUTES } from '../../routes';
import { ssoGenerateAPIToken } from '../../services/apiService';
import {
  postAuthentication,
  processPermissions,
} from '../../services/authService';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    height: 'calc(100vh - 16px)',
  },
});

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
export const LoginPage: React.FC = () => {
  const query = useQuery();

  const history = useHistory();
  const translate = useTranslate();
  const classes = useStyles();
  const [retries, setRetries] = useState<number>(0);
  const [decay, setDecay] = useState<number>(10);

  const baseUrl = process.env.REACT_APP_COGNITO_BASE_URL;
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const redirectUrl = process.env.REACT_APP_COGNITO_REDIRECT_URL;
  const scope = process.env.REACT_APP_COGNITO_SCOPE;
  const responseType = process.env.REACT_APP_COGNITO_RESPONSE_TYPE;

  const redirectToSamlLogin = useCallback(() => {
    window.location.replace(
      `${baseUrl}?client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scope}&response_type=${responseType}`
    );
  }, [baseUrl, clientId, redirectUrl, responseType, scope]);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const code = query.get('code');
        const email = localStorage.getItem('email') || '';
        if (code) {
          sessionStorage.setItem(CODE, code);
          let token = await ssoGenerateAPIToken(code);
          sessionStorage.setItem(ACCESS_TOKEN, token);
          if (token && email) {
            const { user } = await processPermissions(email, token);
            await postAuthentication(user, token);
            window.location.replace('/');
          } else {
            throw new Error('Error processing token');
          }
        } else {
          redirectToSamlLogin();
        }
      } catch (e) {
        console.error(e);
        window.location.replace(ROUTES.SIGN_IN.path);
      }
    };
    loadUser();
  }, [query, redirectToSamlLogin, retries, setRetries, decay, setDecay]);

  useEffect(() => {
    if (retries > 2) {
      history.push({ pathname: '.' });
    }
  }, [retries, history]);

  return (
    <div className={classes.container}>
      <CircularProgress size={100} />
      <Typography>{translate('asurion.login.login_transition')}</Typography>
    </div>
  );
};

export default LoginPage;

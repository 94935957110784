import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  DateField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useGetOne,
} from 'react-admin';
import AsurionAddress from '../common/Collection/AsurionAddress';
import EntityShowActions from '../common/EntityShowActions';
import ShowSection from '../common/Scaffolding/ShowSection';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
});

export const SalesPartnersShow = (props: any) => {
  const { data, loading, loaded } = useGetOne(props.resource, props.id);
  const classes = useStyles();

  const [noIncentives, setNoIncentives] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && loaded) {
      const { incentivePercentage = 0, partnerIncentivePercentage = 0 } =
        data as any;
      setNoIncentives(
        incentivePercentage <= 0 && partnerIncentivePercentage <= 0
      );
    }
  }, [data, loading, loaded]);

  return (
    <Show
      {...props}
      actions={
        <EntityShowActions>
          <TextField label="Company Name" source="partnerName" fullWidth />
        </EntityShowActions>
      }
    >
      <SimpleShowLayout>
        <ShowSection title="Partner Account Details">
          <TextField label="Company Name" source="partnerName" fullWidth />
          <TextField label="Partner Code" source="partnerCode" fullWidth />
          <TextField label="Tax Registration Number" source="tin" fullWidth />
          <DateField label="Date of Partnership" source="startDate" fullWidth />

          <Grid container spacing={0}>
            <Grid item xs={6} spacing={0}>
              <SimpleShowLayout className="inline-field">
                <NumberField
                  label="Revenue Share Percentage"
                  source="revenueSharePct"
                  fullWidth
                  options={{ style: 'percent' }}
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6} spacing={0}>
              <SimpleShowLayout className="inline-field">
                <NumberField
                  label="Revenue Target"
                  source="revenueTarget"
                  fullWidth
                />
              </SimpleShowLayout>
            </Grid>
          </Grid>

          {!noIncentives && (
            <Grid container spacing={0}>
              <Grid item xs={6} spacing={0}>
                <SimpleShowLayout className="inline-field">
                  <NumberField
                    label="Sales Rep Incentive Percentage"
                    source="incentivePercentage"
                    fullWidth
                    options={{ style: 'percent' }}
                  />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={6} spacing={0}>
                <SimpleShowLayout className="inline-field">
                  <NumberField
                    label="Partner Incentive Percentage"
                    source="partnerIncentivePercentage"
                    fullWidth
                    options={{ style: 'percent' }}
                    emptyText="N/A"
                  />
                </SimpleShowLayout>
              </Grid>
            </Grid>
          )}

          <ReferenceArrayField
            label="Asurion Sales Manager"
            reference="sales-managers"
            source="salesManagers"
            sortable={false}
            filter={{
              activeInd: 'active',
            }}
            className={classes.arrayReferenceList}
          >
            <SingleFieldList linkType={false}>
              <TextField source="firstName" />
            </SingleFieldList>
          </ReferenceArrayField>
        </ShowSection>

        <ShowSection title="Address">
          <AsurionAddress />
        </ShowSection>

        <ShowSection title="Bank Details">
          <TextField
            label="Company Name (As appears in bank account)"
            source="bankDetails.accountName"
            fullWidth
          />
          <ReferenceField
            label="Bank Name"
            reference="banks"
            source="bankDetails.bankNameId"
            fullWidth
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField
            label="Bank Branch"
            source="bankDetails.bankBranchId"
            fullWidth
          />
          <ReferenceField
            label="Swift Code"
            reference="banks"
            source="bankDetails.bankNameId"
            fullWidth
            link={false}
          >
            <TextField source="swift_code" />
          </ReferenceField>
          <ReferenceField
            label="Account Type"
            reference="account-types"
            source="bankDetails.accountTypeId"
            emptyText="N/A"
            fullWidth
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </ShowSection>

        <ShowSection title="Contact Details">
          <TextField
            label="Director Name"
            source="contactDetails.directorName"
            fullWidth
            emptyText="N/A"
          />
          <TextField
            label="Primary Contact Person Name"
            source="contactDetails.contactPerson1"
            fullWidth
            emptyText="N/A"
          />
          <TextField
            label="Primary Contact Person Email Address"
            source="contactDetails.email1"
            fullWidth
            emptyText="N/A"
          />
          <TextField
            label="Primary Contact Number"
            source="contactDetails.contactNumber1"
            fullWidth
            emptyText="N/A"
          />
        </ShowSection>
      </SimpleShowLayout>
    </Show>
  );
};

export default SalesPartnersShow;

import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { InputProps, useInput, useLocale } from 'react-admin';

import { DateRangePicker, IntlProvider } from 'rsuite';

import {
  convertToStartOfDay,
  convertToEndOfDay,
  getBrowserDefaultTimezone,
} from '../../../common/util';
import { rsuiteLocaleResolver } from '../../../providers/i18nProvider';
import { enUS } from 'date-fns/locale';
import moment from 'moment';

const useStyles = makeStyles({
  '@global': {
    '.rs-picker-toolbar-option': {
      fontSize: '12px',
    },
    // '.rs-calendar-table-cell-selected .rs-calendar-table-cell-content': {
    //   borderColor: '#99fbd5',
    //   backgroundColor: '#6eFa3c',
    // },
    // 'rs-calendar-table-cell-in-range::before': {
    //   backgroundColor: '#99fbd5'
    // }
  },
  dateRangeInputDiv: {
    border: 0,
    margin: 0,
    display: 'inline-flex',
    padding: 0,
    position: 'relative',
    minWidth: '210px',
    flexDirection: 'column',
    verticalAlign: 'top',
    marginTop: '8px',
    // marginTop: '-15px', // Hackaround
    // marginBottom: '4px',
    borderColor: 'initial',
  },
  dateRangePickerInput: {
    fontSize: '12px',
    display: 'inline-flex',
    '& a': {
      lineHeight: '18px',
      borderRadius: '18px',
      '&:hover': {
        borderColor: 'black !important',
      },
      '&.active': {
        borderColor: '#3F51b5 !important',
        borderWidth: '2px !important',
      },
      '& span': {
        fontSize: '12px',
        color: '#8E8E93 !important',
        '&.rs-picker-toggle-value': {
          color: 'black !important',
        },
        '&.rs-picker-toggle-clean:hover': {
          color: 'black !important',
        },
      },
      '&[aria-disabled="true"]': {
        borderColor: 'black !important',
        '&.active': {
          borderWidth: '1px !important',
        },
      },
    },
    '& div[aria-expanded="true"]': {
      fontSize: '12px',
      '& input': {
        fontSize: '12px',
      },
    },
  },
  inputLabel: {
    color: '#8E8E93',
    display: 'block',
    fontSize: '10px',
    minWidth: '150px',
    lineHeight: '12px',
    marginBottom: '12px',
    textTransform: 'uppercase',
  },
});

interface DateRangePickerProps extends InputProps {
  dates: any;
  setDates: (dates: any) => void;
  label?: string;
  placeholder: string;
  timezone?: string;
}

// TODO: Create Context for reusable components
export const DateRangePickerInput = (props: DateRangePickerProps) => {
  const classes = useStyles();
  // const [dates, setDates] = useState<any>();

  const {
    dates,
    setDates,
    initialValue,
    label,
    timezone = getBrowserDefaultTimezone(),
    ...restProps
  } = props;

  const {
    input: { onChange },
  } = useInput(restProps);

  const localeString = useLocale();
  const [locale, setLocale] = useState<any>(undefined);
  const [dateLocale, setDateLocale] = useState<any>(enUS);

  const formatDate = (data: any, formatStr: any) => {
    // console.log('Format date data: ', data);
    return format(
      moment(data).toDate(),
      // Seems to be a bug with date-fns@^2.23.0
      formatStr.replace('YYYY', 'yyyy').replace('DD', 'dd'),
      { locale: dateLocale }
    );
  };

  const toDateArray = (dates: any) =>
    _.isEmpty(dates) ? dates : dates.map((d: any) => new Date(d));

  // const isValidDates = (dates: any) => dates && dates.every((d: any) => d && moment(d).isValid());

  const handleDateChange = (newDates: any, e: React.SyntheticEvent) => {
    // console.log('Dates are: ', newDates);
    newDates = toDateArray(newDates);

    let convertedDates = [];
    // Handle dates to shift to start and end of day, then convert to UTC for the form
    if (newDates.length === 2) {
      // console.log('Timezone: ', timezone);
      // console.log('Before transform: ', newDates[1]);
      convertedDates[0] = convertToStartOfDay(newDates[0], timezone)
        .utc()
        .toDate(); // convertToStartOfDay(newDates[0]).utc();
      convertedDates[1] = convertToEndOfDay(newDates[1], timezone)
        .utc()
        .toDate(); // convertToEndOfDay(newDates[1]).utc();
      // console.log('After transform: ', convertedDates[1]);
    }

    onChange(convertedDates);
    setDates(newDates);
  };

  // Fix initial default value of Enrollment Date not being set to proper timezone for converted date
  useEffect(() => {
    let convertedInitialValues = [];
    if (initialValue) {
      if (initialValue.length === 2) {
        // console.log(timezone);
        // console.log(newDates[1]);
        convertedInitialValues[0] = convertToStartOfDay(
          initialValue[0],
          timezone
        )
          .utc()
          .toDate(); // convertToStartOfDay(newDates[0]).utc();
        convertedInitialValues[1] = convertToEndOfDay(initialValue[1], timezone)
          .utc()
          .toDate(); // convertToEndOfDay(newDates[1]).utc();
        // console.log(convertedDates[1]);
      }

      onChange(convertedInitialValues);
      setDates(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log('Dates in date range picker are:', dates);

  useEffect(() => {
    const resolvedLocale = rsuiteLocaleResolver(localeString as any);
    setLocale(resolvedLocale.locale);
    setDateLocale(resolvedLocale.date);
  }, [localeString]);

  return (
    <IntlProvider locale={locale} formatDate={formatDate}>
      <div className={classes.dateRangeInputDiv}>
        <label className={classes.inputLabel}>{label}</label>
        <DateRangePicker
          className={classes.dateRangePickerInput}
          // value={value}
          value={dates}
          onChange={handleDateChange}
          defaultValue={initialValue}
          disabledDate={(date) => date > new Date()}
          {...restProps}
          showOneCalendar
        />
      </div>
    </IntlProvider>
  );
};

export default DateRangePickerInput;

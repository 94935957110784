import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import { noop } from '../../../common/functions';

const useStyles = makeStyles({
  actions: {
    justifyContent: 'flex-start',
    padding: '30px 0 10px 0',
  },
  dialogTitleRoot: {
    padding: '10px 0 10px 0',
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  dialogContentRoot: {
    padding: 0,
    '& p': {
      fontSize: '14px',
      // marginBottom: '1em',
    },
  },
  dialogPaper: {
    minWidth: '500px',
    // maxWidth: '750px',
    padding: '30px',
  },
  buttonProgress: {
    position: 'absolute',
    top: 'calc(50% - 25px)',
    left: 'calc(50% - 25px)',
  },
});

export interface ActionDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onOk: (value: boolean, data?: any) => void;
  cancelText?: string;
  okText?: string;
  title?: any;
  showCancel?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
}

// TODO: Create Context for reusable components
export const ActionDialog = (props: ActionDialogProps) => {
  const classes = useStyles();

  let {
    isOpen,
    onCancel,
    cancelText,
    showCancel = true,
    onOk,
    okText,
    title,
    loading,
    children,
  } = props;

  const handleCancel = () => {
    onCancel();
    if (onCancel === noop) {
      isOpen = false;
    }
  };

  const handleOk = () => {
    onOk(true);
  };

  return (
    <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }}>
      {loading && (
        <CircularProgress size={50} className={classes.buttonProgress} />
      )}
      <DialogTitle
        disableTypography
        classes={{ root: classes.dialogTitleRoot }}
      >
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {showCancel && (
          <Button
            variant="contained"
            color="default"
            onClick={handleCancel}
            disabled={loading}
          >
            {cancelText}
          </Button>
        )}
        <Button color="primary" onClick={handleOk} disabled={loading}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

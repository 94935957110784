import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import {
  DEFAULT_EMBED_CONFIG,
  getEmbedConfig,
} from '../../../services/reportService';
import { useEffect, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import { REPORT_ROLES } from '../../../common/constants';
import { AsurionUser, ROLES } from '../../../common/types';
import { useSalesPartnerId } from '../../../contexts/SalesPartnerContext';
import { dataProvider } from '../../../providers/dataProvider';

const useStyles = makeStyles({
  container: {
    height: 'calc(100vh - 145px)',
    minHeight: 500,
    border: 'none',
  },
});

export interface OwnEmbeddedReportProps {
  reportId: string;
  embedConfigIn?: models.IReportEmbedConfiguration;
  extraParams?: object;
}

export const OwnEmbeddedReport = ({
  reportId,
  embedConfigIn = {},
  extraParams = {},
}: OwnEmbeddedReportProps) => {
  const [embedConfig, setEmbedConfig] =
    useState<models.IReportEmbedConfiguration>(DEFAULT_EMBED_CONFIG);
  const { identity }: { identity?: AsurionUser } = useGetIdentity();
  const { salesPartnerId } = useSalesPartnerId();
  const [version, setVersion] = useState<number>(0);

  useEffect(() => {
    const run = async () => {
      if (identity?.id) {
        let username = identity.id as string;
        let role: typeof REPORT_ROLES[keyof typeof REPORT_ROLES] =
          REPORT_ROLES.ALL;

        switch (identity.role as ROLES) {
          case 'SALES_REP':
            username = `${identity.firstName} ${identity.lastName}`;
            role = REPORT_ROLES.SALES_REP;
            break;
          case 'STORE_MGR':
            role = REPORT_ROLES.SALES_MANAGER;
            break;
          case 'PARTNER_ADMIN':
            const { data: adminData } = await dataProvider.getOne(
              'partner-admins',
              {
                id: identity.id,
              }
            );
            const { data } = await dataProvider.getOne('sales-partners', {
              id: adminData.salesPartnerId,
            });

            if (identity?.extra?.countryId === 'twn') {
              username = data.partnerCode;
              role = REPORT_ROLES.RETAILER;
            } else {
              username = data.partnerName;
              role = REPORT_ROLES.PARTNER;
            }
            break;
          case 'SALES_MGR':
            role = REPORT_ROLES.SALES_MANAGER;
            break;
          case 'BUSINESS_PARTNER_ADMIN':
            role = REPORT_ROLES.ALL;
            break;
        }

        const newEmbedConfig = await getEmbedConfig({
          ...embedConfigIn,
          reportId,
          username,
          role,
          extraParams,
        });

        setEmbedConfig(newEmbedConfig || embedConfig);
        setVersion(version + 1);
      }
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity, salesPartnerId]);

  const classes = useStyles();
  return (
    <>
      <PowerBIEmbed
        key={version}
        embedConfig={embedConfig}
        cssClassName={classes.container}
      />
    </>
  );
};

import { models } from 'powerbi-client';
import axios from 'axios';
import { EmbedDetailsResponse } from '../common/types';
import { REPORT_ROLES } from '../common/constants';

const reportRoles = { ...REPORT_ROLES } as const;

export const DEFAULT_EMBED_CONFIG: models.IReportEmbedConfiguration = {
  type: 'report',
  tokenType: models.TokenType.Embed,
  accessToken: undefined,
  embedUrl: undefined,
  id: undefined,
  permissions: models.Permissions.All,
  settings: {
    panes: {
      pageNavigation: {
        visible: true,
      },
    },
  },
};

export interface GetEmbedConfigParams {
  reportId: string;
  username: string;
  role?: typeof reportRoles[keyof typeof reportRoles];
  extraParams?: object;
}

export const getEmbedConfig = async ({
  reportId,
  username,
  role = reportRoles.ALL,
  extraParams = {},
}: GetEmbedConfigParams): Promise<
  models.IReportEmbedConfiguration | undefined
> => {
  const {
    data: { embedUrl, accessToken, embedReportId },
  } = await axios.get<EmbedDetailsResponse>(
    `${process.env.REACT_APP_ADMIN_API_URL}/get-embed-details`,
    {
      params: {
        ...extraParams,
        reportId,
        username,
        role,
      },
    }
  );
  if (embedUrl && accessToken) {
    return {
      ...DEFAULT_EMBED_CONFIG,
      embedUrl,
      id: embedReportId,
      accessToken,
    };
  }
  throw new Error('Error retrieving embed configuration.');
};

export const downloadReport = async (
  username: any,
  fromdate: any,
  todate: any
) => {
  const {
    data: { synnexEmbeddedReportResponse: data = {} },
  } = await axios.get(
    `${process.env.REACT_APP_REPORT_API_URL}/synnex/v1/synnexembeddedreport`,
    {
      params: {
        username,
        fromdate,
        todate,
      },
      headers: {
        'x-api-key': `${process.env.REACT_APP_REPORT_API_X_API}`,
        'asurion-client': `${process.env.REACT_APP_REPORT_API_ASURION_CLIENT}`,
        'asurion-apikey': `${process.env.REACT_APP_REPORT_API_ASURION_API_KEY}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return data;
};

import * as React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SimpleShowLayout } from 'react-admin';

export interface ShowSectionProps {
  title?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  sectionHeader: {
    fontSize: 24,
    lineHeight: '135%',

    '&:not(:first-child)': {
      marginTop: 40,
    },
  },
});

export const ShowSection = ({
  title,
  children,
  ...restProps
}: ShowSectionProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2" className={classes.sectionHeader}>
        {title}
      </Typography>
      <SimpleShowLayout {...restProps}>{children}</SimpleShowLayout>
    </>
  );
};

ShowSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ShowSection;

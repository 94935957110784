import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  makeStyles,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { Category } from './types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface TroubleshootingCategoryProps {
  category: Category;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    marginBottom: 11.5,
  },
  itemList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
  },
  categoryLabel: {
    fontSize: 20,
    lineHeight: 13 / 10,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  categoryIcon: {
    width: 60,
    height: 60,
    backgroundColor: '#6B8BFF',
  },
  itemRoot: {
    border: 'none',
    boxShadow: 'none',
    '&:before': {
      top: 'unset',
      left: 'unset',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  itemExpanded: {},
  questionRoot: {
    padding: 0,
    flexDirection: 'row-reverse',
    gap: 16,
    minHeight: 'unset',
    '&.Mui-expanded': {
      minHeight: 'unset',
    },
  },
  question: {
    margin: 0,
    lineHeight: 'unset',
    color: '#333F48',
    '&.Mui-expanded': {
      margin: 0,
      color: theme.palette.common.black,
      fontWeight: 'bold',
    },
  },
  answer: {
    whiteSpace: 'break-spaces',
    padding: '10px',
    '& .Mui-expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    margin: 0,
    padding: 0,
    transform: 'rotate(-90deg)',
    '&.Mui-expanded': {
      transform: 'rotate(-180deg)',
    },
  },
}));

export const TroubleshootingCategory = (
  props: TroubleshootingCategoryProps
) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.heading}>
        <Avatar src={props.category.logo} className={classes.categoryIcon}>
          {props.category.category.charAt(0)}
        </Avatar>
        <Typography variant="h3" className={classes.categoryLabel}>
          {props.category.category}
        </Typography>
      </div>

      <div className={classes.itemList}>
        {props.category.items.map((item) => (
          <Accordion
            classes={{
              root: classes.itemRoot,
              expanded: classes.itemExpanded,
            }}
          >
            <AccordionSummary
              classes={{
                root: classes.questionRoot,
                content: classes.question,
                expandIcon: classes.expandIcon,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {item.question}
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.answer,
              }}
            >
              {item.answer}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default TroubleshootingCategory;

import axios from 'axios';
import { PAYOUT_BATCH_ID } from '../common/constants';
import { noop, notifyError } from '../common/functions';

import { fetchJson } from '../providers/dataProvider';

export const getBrowserCountry = async () => {
  try {
    const { data: response } = await axios.get('http://ip-api.com/json');

    if (response) {
      return response?.countryCode;
    }
  } catch (err: any) {
    notifyError(err, noop);
  }
};

export const ssoGenerateAPIToken = async (code: string) => {
  try {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_SECURITY_API_URL}/security/sso`,
      {
        c: code,
      }
    );

    return response;
  } catch (err: any) {
    notifyError(err, noop);
  }
};

export const getPendingIncentiveCalculation = async (
  financeUserId: any,
  onLogin: boolean = true,
  onSuccess: (data?: any) => void,
  onFail?: () => void
) => {
  console.log(
    'Params for Get Incentive Calculation Batch Details: ',
    financeUserId
  );

  try {
    const { json: data } = await fetchJson(
      `${process.env.REACT_APP_ADMIN_API_URL}/incentives-calculation/${financeUserId}`
    );
    console.log(data);

    if (data && data.payoutBatchId) {
      console.log('Finance user has existing incentive calculation.');
      localStorage.setItem(PAYOUT_BATCH_ID, data.payoutBatchId);

      if (onSuccess !== noop) {
        onSuccess(data);
      }
    } else {
      console.error('Some unknown error happened.');
      // localStorage.removeItem(PAYOUT_BATCH_ID);
    }
  } catch (err: any) {
    notifyError(err, noop);
    if (onLogin) {
      localStorage.removeItem(PAYOUT_BATCH_ID);
    }
  }
};

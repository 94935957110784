import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import {
  FormWithRedirect,
  SimpleFormView,
  SimpleFormProps,
  useTranslate,
} from 'react-admin';
import AsurionToolbar from './AsurionToolbar';

export interface AsurionFormProps {
  title?: string;
  variant?: SimpleFormProps['variant'];
  margin?: SimpleFormProps['margin'];
  hasConfirm?: boolean;
  saveButtonLabel?: string;
  BackLinkProps?: any;
}

const useStyles = makeStyles({
  title: {
    fontSize: 20,
    lineHeight: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 35,
    marginTop: 50,
  },
  helper: {
    fontSize: 12,
  },
  indicator: {
    color: 'red',
  },
  form: {
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
});

export const AsurionForm: React.FC<SimpleFormProps & AsurionFormProps> = ({
  variant = 'outlined',
  margin = 'none',
  saveButtonLabel,
  title,
  record,
  hasConfirm: _hasConfirm,
  BackLinkProps = {},
  ...restProps
}) => {
  const classes = useStyles();
  const [hasConfirm] = useState<boolean>(
    _hasConfirm ||
      !!(
        record &&
        Object.keys(record).length === 0 &&
        record.constructor === Object
      )
  );
  const translate = useTranslate();

  return (
    <FormWithRedirect
      variant={variant}
      margin={margin}
      record={record}
      {...restProps}
      render={(formProps) => (
        <Container maxWidth="sm">
          {title && (
            <Typography className={classes.title} variant="h2">
              {title}
              <span className={classes.helper}>
                <b className={classes.indicator}>*</b>{' '}
                {translate('ra.validation.required')}
              </span>
            </Typography>
          )}

          <SimpleFormView
            {...formProps}
            className={classes.form}
            margin="none"
            toolbar={
              <AsurionToolbar
                BackLinkProps={BackLinkProps}
                hasConfirm={hasConfirm}
                saveButtonLabel={saveButtonLabel}
              />
            }
          />
        </Container>
      )}
    />
  );
};

export default AsurionForm;

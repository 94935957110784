import {
  AppBar,
  Box,
  Divider,
  IconButton,
  makeStyles,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAuthState, useGetIdentity } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';

import { roleLabelMapping } from '../common/constants';
import { ArrayElement, ROLES } from '../common/types';

import AsurionLogo from '../components/common/Branding/AsurionLogo/AsurionLogo';
import SignoutButton from '../components/common/Form/Buttons/SignoutButton';
import { CountrySelectorMenu } from '../components/common/Form/Collection/CountrySelectorMenu';
import { SalesPartnerSelectorMenu } from '../components/common/Form/Collection/SalesPartnerSelectorMenu';
import tabsMapping from '../components/common/Tab';
import { useNavigationContext } from '../contexts/NavigationContext';
import { useProfile } from '../contexts/ProfileContext';
import { useSetTheme } from '../contexts/ThemeContext';
import { authProvider } from '../providers/authProvider';
import { ROUTES } from '../routes';
import { colors } from '../themes/default';

export const appBarHeight = 60;

const useStyles = makeStyles({
  appBar: {
    background: colors.brand.black,
    height: appBarHeight,
  },
  logo: {
    display: 'flex',
    '& > *': {
      display: 'flex',
      gap: 12,
      textDecoration: 'none',
      color: '#7350BC',
      fontSize: '1.3em',
      alignItems: 'baseline',
    },
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  regular: {
    minHeight: appBarHeight,
  },
  mainNavigationList: {
    color: colors.brand.white,
    height: appBarHeight,
  },
  mainNavigationListContainer: {
    height: appBarHeight,
  },
  indicator: {
    backgroundColor: colors.extra.indicator,
    height: 4,
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  namePlate: {
    paddingLeft: 20,
    paddingRight: 10,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textDecoration: 'none',
    color: 'white',
  },
  name: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 'normal',
  },
  title: {
    fontSize: 10,
    lineHeight: '12px',
  },
});

type TabType = Array<keyof typeof tabsMapping>;

export const DefaultAppBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const { identity, loaded } = useGetIdentity();
  const { loaded: authLoaded } = useAuthState();
  const { hasMenu, hasNavigation } = useSetTheme();
  const [activePath, setActivePath] = useState<string | false>(false);
  const [tabs, setTabs] = useState<TabType>([]);
  const [tabPaths, setTabPaths] = useState<Array<string>>([]);
  const { navigationTree } = useNavigationContext();
  const { profileVersion } = useProfile();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  useEffect(() => {
    const renderTabs = (): TabType => {
      const role: ROLES = identity?.role || 'ANONYMOUS';
      let country_finance_manager = [
        'SalesPerformanceTab',
        'IncentivesTab',
      ] as TabType;
      switch (role) {
        case 'SYSTEM_ADMIN':
          return [
            'UserManagementTab',
            'AccountManagementTab',
            'SalesPerformanceTab',
            'BulletinTab',
            'ETrainingResourcesTab',
            'IncentivesTab',
          ];
        case 'COUNTRY_MGR':
          return country_finance_manager;
        case 'FINANCE_MGR':
          return [...country_finance_manager, 'IncentivesCalculationTab'];
        // return localStorage.getItem(PAYOUT_BATCH_ID)
        //   ? [...country_finance_manager, 'IncentivesCalculationTab']
        //   : country_finance_manager;
        case 'SALES_MGR':
          return ['ManagementTab', 'SalesPerformanceTab', 'BulletinTab'];
        case 'PARTNER_ADMIN':
          if (identity?.extra?.countryId === 'mys') {
            return ['ManagementTab', 'BulletinTab'];
          }
          return [
            'ManagementTab',
            'SalesPerformanceTab',
            'BulletinTab',
            'IncentivesTab',
          ];
        case 'BUSINESS_PARTNER_ADMIN':
          return ['ManagementTab', 'SalesPerformanceTab', 'BulletinTab'];
        default:
          return [];
      }
    };

    const tabs = renderTabs();
    const paths = tabs.map((tab: ArrayElement<TabType>) => {
      return tabsMapping[tab].props.value;
    });
    setTabPaths(paths);
    setTabs(tabs);
    setFirstName(identity?.firstName);
    setLastName(identity?.lastName);
  }, [identity]);

  useEffect(() => {
    const { pathname } = location;
    let level1 = pathname;
    const ancestorList = navigationTree[0]
      .getNodeById(pathname.substring(1))
      ?.getAncestors()
      .reverse();
    if (ancestorList?.length > 1) {
      level1 = ancestorList[1]?.dataObj.path;
    }
    if (tabPaths.includes(level1)) {
      setActivePath(level1);
    }
  }, [location, tabPaths, navigationTree]);

  useEffect(() => {
    (async () => {
      if (authProvider && typeof authProvider.getIdentity === 'function') {
        const currentIdentity = await authProvider.getIdentity();
        setFirstName(currentIdentity.firstName);
        setLastName(currentIdentity.lastName);
      }
    })();
  }, [profileVersion]);

  return authLoaded && loaded && hasNavigation ? (
    <AppBar classes={{ root: classes.appBar }}>
      <Toolbar
        classes={{
          root: classes.toolBar,
          regular: classes.regular,
        }}
      >
        <Typography variant="h6" className={classes.logo}>
          <Link to="/">
            <AsurionLogo />
            <span>
              TechCare<sup>&trade;</sup>
            </span>
          </Link>
        </Typography>
        {hasMenu && (
          <>
            <Tabs
              classes={{
                root: classes.mainNavigationList,
                flexContainer: classes.mainNavigationListContainer,
                indicator: classes.indicator,
              }}
              value={activePath}
            >
              {tabs.map((tab: ArrayElement<TabType>, i: number) => {
                return React.cloneElement(tabsMapping[tab] || <></>, {
                  key: `main-${i}`,
                });
              })}
            </Tabs>

            <div className={classes.profile}>
              {['COUNTRY_MGR', 'SALES_MGR'].includes(identity?.role) && (
                <>
                  <SalesPartnerSelectorMenu />
                  <Divider flexItem={true} orientation="vertical" />
                </>
              )}
              {identity?.role === 'SYSTEM_ADMIN' && (
                <>
                  <CountrySelectorMenu />
                  <Divider flexItem={true} orientation="vertical" />
                </>
              )}
              <PopupState variant="popover">
                {(popupState) => (
                  <>
                    <IconButton
                      aria-label="notification"
                      color="inherit"
                      {...bindTrigger(popupState)}
                    >
                      <NotificationsNoneIcon />
                    </IconButton>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box p={2}>
                        <Typography>
                          Notifications to be displayed here
                        </Typography>
                      </Box>
                    </Popover>
                  </>
                )}
              </PopupState>
              <Divider flexItem={true} orientation="vertical" />
              {identity?.role === 'PARTNER_ADMIN' && (
                <Link
                  key={profileVersion}
                  to={ROUTES.PROFILE_VIEW.path}
                  className={classes.namePlate}
                >
                  <Typography variant="h6" className={classes.name}>
                    {firstName} {lastName}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.title}>
                    {roleLabelMapping[identity?.role as ROLES]}
                  </Typography>
                </Link>
              )}
              {identity?.role !== 'PARTNER_ADMIN' && (
                <div className={classes.namePlate}>
                  <Typography variant="h6" className={classes.name}>
                    {firstName} {lastName}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.title}>
                    {roleLabelMapping[identity?.role as ROLES]}
                  </Typography>
                </div>
              )}
              <SignoutButton />
            </div>
          </>
        )}
      </Toolbar>
    </AppBar>
  ) : null;
};

export default DefaultAppBar;

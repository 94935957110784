import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  ReferenceArrayField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useTranslate,
} from 'react-admin';
import EntityShowActions from '../../common/EntityShowActions';
import ShowSection from '../../common/Scaffolding/ShowSection';
import AsurionAddress from '../../overrides/tw/common/Collection/AsurionAddress';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
});

export const NoBankDetailsShow = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Show
      {...props}
      actions={
        <EntityShowActions>
          <TextField label="Company Name" source="partnerName" fullWidth />
        </EntityShowActions>
      }
    >
      <SimpleShowLayout>
        <ShowSection
          title={translate(
            'resources.sales-partners.fieldsets.partnerAccountDetails'
          )}
        >
          <TextField
            label="resources.sales-partners.fields.partnerName"
            source="partnerName"
            fullWidth
          />
          <TextField
            label="resources.sales-partners.fields.partnerCode"
            source="partnerCode"
            fullWidth
          />

          <ReferenceArrayField
            label="resources.sales-partners.fields.salesManagers"
            reference="sales-managers"
            source="salesManagers"
            sortable={false}
            filter={{
              activeInd: 'active',
            }}
            className={classes.arrayReferenceList}
          >
            <SingleFieldList linkType={false}>
              <TextField source="firstName" />
            </SingleFieldList>
          </ReferenceArrayField>
        </ShowSection>

        <ShowSection
          title={translate('resources.sales-partners.fieldsets.address')}
        >
          <AsurionAddress />
        </ShowSection>

        <ShowSection
          title={translate('resources.sales-partners.fieldsets.contactDetails')}
        >
          <TextField
            label="asurion.common.form.collection.contact_details.directorName"
            source="contactDetails.directorName"
            fullWidth
            emptyText="N/A"
          />
          <TextField
            label="asurion.common.form.collection.contact_details.contactPerson1"
            source="contactDetails.contactPerson1"
            fullWidth
            emptyText="N/A"
          />
          <TextField
            label="asurion.common.form.collection.contact_details.email1"
            source="contactDetails.email1"
            fullWidth
            emptyText="N/A"
          />
          <TextField
            label="asurion.common.form.collection.contact_details.contactNumber1"
            source="contactDetails.contactNumber1"
            fullWidth
            emptyText="N/A"
          />
        </ShowSection>
      </SimpleShowLayout>
    </Show>
  );
};

export default NoBankDetailsShow;

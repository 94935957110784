import * as jwt from 'jsonwebtoken';

export default class JwtUtil {
  public static generate(
    payload: string,
    expiredIn: string,
    deconstruct: boolean = false,
    usePld: boolean = false
  ) {
    const jwtSecret = process.env.REACT_APP_JWT_SECRET as any;
    const jwtOptions = {
      algorithm: 'HS256',
      expiresIn: expiredIn,
    } as any;

    const token = jwt.sign(
      usePld ? { pld: JSON.parse(payload) } : { data: payload },
      jwtSecret.toString('utf-8'),
      jwtOptions
    );

    return deconstruct ? this.deconstructToken(token) : token;
  }

  public static verify(token: string) {
    const jwtSecret = process.env.REACT_APP_JWT_SECRET as any;

    let valid = false;
    jwt.verify(token, jwtSecret, (err: any, dcded: any) => {
      if (err) {
        throw new Error(err);
      } else {
        valid = true;
      }
    });

    return valid;
  }

  public static dcd(token: string, reconstruct: boolean = false) {
    const jwtSecret = process.env.REACT_APP_JWT_SECRET as any;
    return jwt.verify(
      reconstruct ? this.reconstructToken(token) : token,
      jwtSecret
    );
  }

  private static deconstructToken(token: string) {
    const tokenPart = token.split('.');

    return (
      this.reverse(tokenPart[0].replace('eyJ', '')) +
      ';' +
      this.reverse(tokenPart[1].replace('eyJ', '')) +
      ';' +
      tokenPart[2]
    );
  }

  public static reconstructToken(token: string) {
    const tokenPart = token.split(';');
    return (
      'eyJ' +
      this.reverse(tokenPart[0]) +
      '.' +
      'eyJ' +
      this.reverse(tokenPart[1]) +
      '.' +
      tokenPart[2]
    );
  }

  private static reverse(data: string) {
    let dataSplit = data.split('');
    let dataReverse = dataSplit.reverse();
    let dataJoined: string = dataReverse.join('');

    return dataJoined;
  }
}

import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Activation } from './components/pages/Activation';
import { ExternalLoginPage } from './components/authentication/ExternalLoginPage';
import { CountrySelect } from './components/pages/CountrySelect';
import { UserManagement } from './components/pages/UserManagement';
import { SalesPerformance } from './components/pages/SalesPerformance';
import { Bulletin } from './components/pages/Bulletin';
import { ETrainingResources } from './components/pages/ETrainingResources';

import { SalesPartnerSelect } from './components/pages/SalesPartnerSelect';
import PartnerSales from './components/pages/sales-performance/PartnerSales';
import Programs from './components/pages/sales-performance/Programs';
import PhoneMake from './components/pages/sales-performance/PhoneMake';
import PhoneMakeByProgram from './components/pages/sales-performance/PhoneMakeByProgram';
import PhoneMakeByPartner from './components/pages/sales-performance/PhoneMakeByPartner';
import GrossAddsByPartner from './components/pages/sales-performance/GrossAddsByPartner';

import PartnerSalesPerformance from './components/pages/sales-performance/performance/PartnerSalesPerformance';
import ProgramsPerformance from './components/pages/sales-performance/performance/ProgramsPerformance';
import PhoneMakePerformance from './components/pages/sales-performance/performance/PhoneMakePerformance';
import Report from './components/pages/Report';
import ProfileEdit from './components/user/ProfileEdit';
import ProfileView from './components/user/ProfileView';
import ChangePassword from './components/user/ChangePassword';
import ResetPassword from './components/user/ResetPassword';

import AppTroubleshooting from './components/pages/app-troubleshooting/AppTroubleshooting';
import WeeklyEnrollmentReport from './components/pages/reports/tw/WeeklyEnrollmentReport';

const treeUtil = require('tree-util');
export interface IRoute {
  id: string;
  path: string;
  default?: boolean;
  parent?: IRoute['id'];
}

const ROOT: IRoute = {
  id: 'root',
  path: '/',
};

const USER_MANAGEMENT: IRoute = {
  id: 'user-management',
  path: '/user-management',
  parent: 'root',
};

const MANAGEMENT: IRoute = {
  id: 'user-management',
  path: '/user-management',
  parent: 'root',
};

const ACCOUNT_MANAGEMENT: IRoute = {
  id: 'account-management',
  path: '/account-management',
  parent: 'root',
};

const SALES_PERFORMANCE: IRoute = {
  id: 'sales-performance',
  path: '/sales-performance',
  parent: 'root',
};

const BULLETIN: IRoute = {
  id: 'bulletin',
  path: '/bulletin',
  parent: 'root',
};

const ETRAINING_RESOURCES: IRoute = {
  id: 'etraining-resources',
  path: '/etraining-resources',
  parent: 'root',
};

const ACTIVATION: IRoute = {
  id: 'activation',
  path: '/activation',
  parent: 'root',
};

const SIGN_IN: IRoute = {
  id: 'sign-in',
  path: '/sign-in',
  parent: 'root',
};

const COUNTRY_SELECTOR: IRoute = {
  id: 'country-select',
  path: '/country-select',
  parent: 'root',
};

const SALES_PARTNER_SELECTOR: IRoute = {
  id: 'sales-partner-select',
  path: '/sales-partner-select',
  parent: 'root',
};

const INCENTIVES: IRoute = {
  id: 'incentives',
  path: '/incentives',
  parent: 'root',
};

const INCENTIVES_CALCULATION: IRoute = {
  id: 'incentives-calculation',
  path: '/incentives-calculation',
  parent: 'root',
};

const COUNTRY_MANAGERS: IRoute = {
  id: 'country-managers',
  path: '/country-managers',
  parent: USER_MANAGEMENT.id,
};

const SALES_MANAGERS: IRoute = {
  id: 'sales-managers',
  path: '/sales-managers',
  parent: USER_MANAGEMENT.id,
};

const BUSINESS_PARTNER_ADMINS: IRoute = {
  id: 'business-partner-admins',
  path: '/business-partner-admins',
  parent: USER_MANAGEMENT.id,
};

const PARTNER_ADMINS: IRoute = {
  id: 'partner-admins',
  path: '/partner-admins',
  parent: USER_MANAGEMENT.id,
};

const STORE_MANAGERS: IRoute = {
  id: 'store-managers',
  path: '/store-managers',
  parent: USER_MANAGEMENT.id,
};

const SALES_REPRESENTATIVES: IRoute = {
  id: 'sales-representatives',
  path: '/sales-representatives',
  parent: USER_MANAGEMENT.id,
};

const SALES_PARTNERS: IRoute = {
  id: 'sales-partners',
  path: '/sales-partners',
  parent: ACCOUNT_MANAGEMENT.id,
};

const PARTNER_STORES: IRoute = {
  id: 'partner-stores',
  path: '/partner-stores',
  parent: ACCOUNT_MANAGEMENT.id,
};

const BUSINESS_PARTNERS: IRoute = {
  id: 'business-partners',
  path: '/business-partners',
  parent: ACCOUNT_MANAGEMENT.id,
};

const PARTNER_SALES: IRoute = {
  id: 'partner-sales',
  path: '/partner-sales',
  parent: SALES_PERFORMANCE.id,
};

// MANAGEMENT
const PARTNER_STORES_MANAGEMENT: IRoute = {
  id: 'partner-stores',
  path: '/partner-stores',
  parent: MANAGEMENT.id,
};

const STORE_MANAGERS_MANAGEMENT: IRoute = {
  id: 'store-managers',
  path: '/store-managers',
  parent: MANAGEMENT.id,
};

const SALES_REPRESENTATIVES_MANAGEMENT: IRoute = {
  id: 'sales-representatives',
  path: '/sales-representatives',
  parent: MANAGEMENT.id,
};

const BUSINESS_PARTNERS_MANAGEMENT: IRoute = {
  id: 'business-partners',
  path: '/business-partners',
  parent: MANAGEMENT.id,
};

const BUSINESS_PARTNER_ADMINS_MANAGEMENT: IRoute = {
  id: 'business-partner-admins',
  path: '/business-partner-admins',
  parent: MANAGEMENT.id,
};

const SALES_PARTNERS_MANAGEMENT: IRoute = {
  id: 'sales-partners',
  path: '/sales-partners',
  parent: MANAGEMENT.id,
};

// END MANAGEMENT

const PROGRAMS: IRoute = {
  id: 'programs',
  path: '/programs',
  parent: SALES_PERFORMANCE.id,
};

const PHONE_MAKE: IRoute = {
  id: 'phone-make',
  path: '/phone-make',
  parent: SALES_PERFORMANCE.id,
};

const PARTNER_SALES_PERFORMANCE: IRoute = {
  id: 'partner-sales',
  path: '/partner-sales',
  parent: SALES_PERFORMANCE.id,
};

const PROGRAMS_PERFORMANCE: IRoute = {
  id: 'programs-performance',
  path: '/programs-performance',
  parent: SALES_PERFORMANCE.id,
};

const PHONE_MAKE_PERFORMANCE: IRoute = {
  id: 'phone-make-performance',
  path: '/phone-make-performance',
  parent: SALES_PERFORMANCE.id,
};

const PHONE_MAKE_BY_PROGRAMS: IRoute = {
  id: 'phone-make-by-programs',
  path: '/phone-make-by-programs',
  parent: SALES_PERFORMANCE.id,
};

const PHONE_MAKE_BY_PARTNER: IRoute = {
  id: 'phone-make-by-partner',
  path: '/phone-make-by-partner',
  parent: SALES_PERFORMANCE.id,
};

const GROSS_ADDS_BY_PARTNERS: IRoute = {
  id: 'gross-adds-by-partners',
  path: '/gross-adds-by-partners',
  parent: SALES_PERFORMANCE.id,
};

const REPORT: IRoute = {
  id: 'report',
  path: '/report',
};

const PROFILE_VIEW: IRoute = {
  id: 'profile-view',
  path: '/profile',
};

const PROFILE_EDIT: IRoute = {
  id: 'profile-edit',
  path: '/profile/edit',
};

const CHANGE_PASSWORD: IRoute = {
  id: 'change-password',
  path: '/profile/change-password',
};

const RESET_PASSWORD: IRoute = {
  id: 'reset-password',
  path: '/reset-password',
};

const APP_TROUBLESHOOTING: IRoute = {
  id: 'app-troubleshooting',
  path: '/app-troubleshooting',
};

const ENROLLMENT_REPORT: IRoute = {
  id: 'enrollment-report',
  path: '/enrollment-report',
  parent: SALES_PERFORMANCE.id,
};

export const ROUTES: { [k: string]: IRoute } = {
  ROOT,
  USER_MANAGEMENT,
  MANAGEMENT,
  ACCOUNT_MANAGEMENT,
  SALES_PERFORMANCE,
  BULLETIN,
  ETRAINING_RESOURCES,
  ACTIVATION,
  SIGN_IN,
  COUNTRY_SELECTOR,
  SALES_PARTNER_SELECTOR,
  INCENTIVES,
  INCENTIVES_CALCULATION,
  COUNTRY_MANAGERS,
  SALES_MANAGERS,
  BUSINESS_PARTNERS,
  BUSINESS_PARTNER_ADMINS,
  PARTNER_ADMINS,
  STORE_MANAGERS,
  SALES_REPRESENTATIVES,
  SALES_PARTNERS,
  PARTNER_STORES,
  // MANAGEMENT
  PARTNER_STORES_MANAGEMENT,
  STORE_MANAGERS_MANAGEMENT,
  SALES_REPRESENTATIVES_MANAGEMENT,
  // END MANAGEMENT
  PARTNER_SALES,
  PROGRAMS,
  PHONE_MAKE,
  PARTNER_SALES_PERFORMANCE,
  PROGRAMS_PERFORMANCE,
  PHONE_MAKE_PERFORMANCE,
  PHONE_MAKE_BY_PROGRAMS,
  PHONE_MAKE_BY_PARTNER,
  GROSS_ADDS_BY_PARTNERS,

  ENROLLMENT_REPORT,

  REPORT,
  PROFILE_VIEW,
  PROFILE_EDIT,
  CHANGE_PASSWORD,
  RESET_PASSWORD,

  APP_TROUBLESHOOTING,
};

export const ANONYMOUS_NAV_TREE = treeUtil.buildTrees(Object.values({ ROOT }), {
  id: 'id',
  parentid: 'parent',
});

export const DEFAULT_NAV_TREE = treeUtil.buildTrees(
  Object.values({
    // ROOT
    ROOT,

    // L1
    USER_MANAGEMENT,
    ACCOUNT_MANAGEMENT,
    SALES_PERFORMANCE,
    BULLETIN,
    ETRAINING_RESOURCES,

    // L2
    COUNTRY_MANAGERS,
    SALES_MANAGERS,
    BUSINESS_PARTNERS,
    BUSINESS_PARTNER_ADMINS,
    PARTNER_ADMINS,
    STORE_MANAGERS,
    SALES_REPRESENTATIVES,
    SALES_PARTNERS,
    PARTNER_STORES,

    PARTNER_SALES,
    PROGRAMS,
    PHONE_MAKE,
    PHONE_MAKE_BY_PROGRAMS,
    GROSS_ADDS_BY_PARTNERS,

    ENROLLMENT_REPORT,
  }),
  {
    id: 'id',
    parentid: 'parent',
  }
);

export const SALES_MGR_NAV_TREE = treeUtil.buildTrees(
  Object.values({
    // ROOT
    ROOT,

    // L1
    MANAGEMENT,
    SALES_PERFORMANCE,
    BULLETIN,

    // L2
    PARTNER_STORES_MANAGEMENT,
    STORE_MANAGERS_MANAGEMENT,
    SALES_REPRESENTATIVES_MANAGEMENT,
    BUSINESS_PARTNERS_MANAGEMENT,
    BUSINESS_PARTNER_ADMINS_MANAGEMENT,

    PARTNER_SALES_PERFORMANCE,
    PROGRAMS_PERFORMANCE,
    PHONE_MAKE_PERFORMANCE,
    PHONE_MAKE_BY_PROGRAMS,
    PHONE_MAKE_BY_PARTNER,

    ENROLLMENT_REPORT,
  }),
  {
    id: 'id',
    parentid: 'parent',
  }
);

export const COUNTRY_MGR_NAV_TREE = treeUtil.buildTrees(
  Object.values({
    // ROOT
    ROOT,

    // L1
    SALES_PERFORMANCE,
    INCENTIVES,

    // L2
    PARTNER_SALES,
    PROGRAMS,
    PHONE_MAKE,
    PHONE_MAKE_BY_PROGRAMS,
    GROSS_ADDS_BY_PARTNERS,
  }),
  {
    id: 'id',
    parentid: 'parent',
  }
);

export const FINANCE_MGR_NAV_TREE = treeUtil.buildTrees(
  Object.values({
    // ROOT
    ROOT,

    // L1
    SALES_PERFORMANCE,
    INCENTIVES,
    INCENTIVES_CALCULATION,

    // L2
    PARTNER_SALES,
    PROGRAMS,
    PHONE_MAKE,
    PHONE_MAKE_BY_PROGRAMS,
    GROSS_ADDS_BY_PARTNERS,
  }),
  {
    id: 'id',
    parentid: 'parent',
  }
);

export const PARTNER_ADMIN_NAV_TREE = treeUtil.buildTrees(
  Object.values({
    // ROOT
    ROOT,

    // L1
    MANAGEMENT,
    SALES_PERFORMANCE,
    BULLETIN,
    INCENTIVES,

    // L2
    PARTNER_ADMINS,
    PARTNER_STORES_MANAGEMENT,
    STORE_MANAGERS,
    SALES_REPRESENTATIVES,

    PARTNER_SALES_PERFORMANCE,
    PROGRAMS_PERFORMANCE,
    PHONE_MAKE_PERFORMANCE,
    PHONE_MAKE_BY_PROGRAMS,
    PHONE_MAKE_BY_PARTNER,

    ENROLLMENT_REPORT,
  }),
  {
    id: 'id',
    parentid: 'parent',
  }
);

export const BUSINESS_PARTNER_ADMIN_NAV_TREE = treeUtil.buildTrees(
  Object.values({
    // ROOT
    ROOT,

    // L1
    MANAGEMENT,
    SALES_PERFORMANCE,

    // L2
    PARTNER_ADMINS,
    SALES_PARTNERS_MANAGEMENT,

    PARTNER_SALES_PERFORMANCE,
    PROGRAMS_PERFORMANCE,
    PHONE_MAKE_PERFORMANCE,
    PHONE_MAKE_BY_PROGRAMS,
    PHONE_MAKE_BY_PARTNER,

    ENROLLMENT_REPORT,
  }),
  {
    id: 'id',
    parentid: 'parent',
  }
);

export const navigationTree = treeUtil.buildTrees(Object.values(ROUTES), {
  id: 'id',
  parentid: 'parent',
});

export const routeList = [
  <Route exact path={ROUTES.USER_MANAGEMENT.path} component={UserManagement} />,
  <Route
    exact
    path={ROUTES.ACCOUNT_MANAGEMENT.path}
    component={() => <Redirect to={ROUTES.SALES_PARTNERS.path} />}
  />,
  <Route exact path={ROUTES.SIGN_IN.path} component={ExternalLoginPage} />,
  <Route
    exact
    path={ROUTES.SALES_PERFORMANCE.path}
    component={SalesPerformance}
  />,
  <Route exact path={ROUTES.BULLETIN.path} component={Bulletin} />,
  <Route
    exact
    path={ROUTES.ETRAINING_RESOURCES.path}
    component={ETrainingResources}
  />,
  <Route exact path={ROUTES.ACTIVATION.path} component={Activation} />,
  <Route exact path={ROUTES.COUNTRY_SELECTOR.path} component={CountrySelect} />,
  <Route
    exact
    path={ROUTES.SALES_PARTNER_SELECTOR.path}
    component={SalesPartnerSelect}
  />,
  <Route exact path={ROUTES.PARTNER_SALES.path} component={PartnerSales} />,
  <Route exact path={ROUTES.PROGRAMS.path} component={Programs} />,
  <Route exact path={ROUTES.PHONE_MAKE.path} component={PhoneMake} />,
  <Route
    exact
    path={ROUTES.PHONE_MAKE_BY_PROGRAMS.path}
    component={PhoneMakeByProgram}
  />,
  <Route
    exact
    path={ROUTES.PHONE_MAKE_BY_PARTNER.path}
    component={PhoneMakeByPartner}
  />,
  <Route
    exact
    path={ROUTES.GROSS_ADDS_BY_PARTNERS.path}
    component={GrossAddsByPartner}
  />,

  <Route
    exact
    path={ROUTES.PARTNER_SALES_PERFORMANCE.path}
    component={PartnerSalesPerformance}
  />,
  <Route
    exact
    path={ROUTES.PROGRAMS_PERFORMANCE.path}
    component={ProgramsPerformance}
  />,
  <Route
    exact
    path={ROUTES.PHONE_MAKE_PERFORMANCE.path}
    component={PhoneMakePerformance}
  />,
  <Route exact path={ROUTES.REPORT.path} component={Report} />,
  <Route exact path={ROUTES.PROFILE_EDIT.path} component={ProfileEdit} />,
  <Route exact path={ROUTES.PROFILE_VIEW.path} component={ProfileView} />,
  <Route exact path={ROUTES.CHANGE_PASSWORD.path} component={ChangePassword} />,
  <Route exact path={ROUTES.RESET_PASSWORD.path} component={ResetPassword} />,

  <Route
    exact
    path={ROUTES.APP_TROUBLESHOOTING.path}
    component={AppTroubleshooting}
  />,

  <Route
    exact
    path={ROUTES.ENROLLMENT_REPORT.path}
    component={WeeklyEnrollmentReport}
  />,
];

export const resolveActivePath = (
  pathname: IRoute['id'],
  depth: number = 1,
  [tree]: Array<typeof navigationTree> = navigationTree
) => {
  const node = tree.getNodeById(pathname.substring(1))?.getAncestors()[
    Math.max(depth - 1, 0)
  ];
  return node?.dataObj.path || '/';
};

export default routeList;

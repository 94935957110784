import * as React from 'react';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { Typography } from '@material-ui/core';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateInput,
  Edit,
  email,
  minValue,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetOne,
} from 'react-admin';
import AsurionForm from '../common/Form/AsurionForm';
import AsurionAddress from '../common/Form/Collection/AsurionAddress';
import CountrySelector from '../common/Form/Collection/CountrySelector';
import { maxArrayInput } from '../common/Validator/maxArrayInput';
import ContactNumber from '../common/Field/ContactNumber';
import SwiftCode from '../common/Field/SwiftCode';
import { useCountryId } from '../../contexts/CountryContext';
import { Field } from 'react-final-form';
import { useEffect, useState } from 'react';
import { toDecimal } from '../common/Form/parsers';
import { requiredIfChecked } from '../common/Validator/requiredIfChecked';
import { maxNumericValue } from '../common/Validator/maxNumericValue';

const useStyles = makeStyles({
  requiredContainer: {},
});

export const SalesPartnersEdit = (props: any) => {
  const classes = useStyles();
  const { data, loading, loaded } = useGetOne(props.resource, props.id);
  const { countryId } = useCountryId();
  const [noIncentives, setNoIncentives] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && loaded) {
      const { incentivePercentage = 0, partnerIncentivePercentage = 0 } =
        (data || {}) as any;
      setNoIncentives(
        incentivePercentage <= 0 && partnerIncentivePercentage <= 0
      );
    }
  }, [data, loading, loaded]);

  return (
    <Edit {...props} actions={false} mutationMode="pessimistic">
      <AsurionForm title="Edit Partner Account">
        <Typography variant="h2">Partner Account Details</Typography>

        <TextInput
          label="Company Name"
          placeholder="e.g. Public Company Limited"
          source="partnerName"
          fullWidth
          validate={required()}
          className="required"
        />

        <TextInput
          label="Partner Code"
          placeholder="Partner Code"
          source="partnerCode"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <TextInput
          label="Tax Registration Number"
          placeholder="1234567890987"
          source="tin"
          fullWidth
          validate={required()}
          className="required"
        />
        <DateInput
          label="Date of Partnership"
          placeholder="Select Date"
          source="startDate"
          fullWidth
        />
        <Collapse in={!noIncentives}>
          {!noIncentives && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <NumberInput
                  label="Revenue Share Percentage"
                  placeholder="e.g  10%"
                  source="revenueSharePct"
                  fullWidth
                  format={(v: number) => (v * 100).toFixed(0)}
                  parse={(v: string) => parseFloat(v) / 100}
                  min={0}
                  validate={required()}
                  className="required"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  label="Revenue Target"
                  placeholder="e.g  10%"
                  source="revenueTarget"
                  fullWidth
                  min={0}
                  validate={required()}
                  className="required"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </Collapse>

        <FormControlLabel
          control={
            <Field name="noIncentives" type="checkbox" initialValue={false}>
              {(props) => (
                <Checkbox
                  color="primary"
                  checked={noIncentives}
                  onChange={(e) => {
                    setNoIncentives(!noIncentives);
                    props.input.onChange(e);
                  }}
                  disabled={loading}
                />
              )}
            </Field>
          }
          label="No incentives"
        />
        <Collapse in={!noIncentives}>
          {!noIncentives && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <NumberInput
                  label="Sales Rep Incentive Percentage"
                  placeholder="e.g  10%"
                  source="incentivePercentage"
                  fullWidth
                  format={(v: number) => (v * 100).toFixed(0)}
                  parse={toDecimal}
                  validate={[
                    requiredIfChecked('noIncentives'),
                    maxNumericValue(0.3, (value) => `${value * 100}%`),
                    minValue(0),
                    noIncentives ? () => undefined : required(),
                  ]}
                  className="required"
                  variant="outlined"
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  label="Partner Incentive Percentage"
                  placeholder="e.g  10%"
                  source="partnerIncentivePercentage"
                  fullWidth
                  format={(v: number) => (v * 100).toFixed(0)}
                  parse={toDecimal}
                  validate={[
                    requiredIfChecked('noIncentives'),
                    minValue(0),
                    noIncentives ? () => undefined : required(),
                    ]}
                  className="required"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </Collapse>

        <CountrySelector source="countryTlId" label="Country" disabled />
        <ReferenceArrayInput
          label="Asurion Sales Manager"
          source="salesManagers"
          reference="sales-managers"
          fullWidth
          validate={[required(), maxArrayInput<string>(3)]}
          variant="outlined"
          filter={{
            activeIndStatus: 'active',
            countryId,
          }}
        >
          <AutocompleteArrayInput
            allowDuplicates={false}
            classes={{
              container: classes.requiredContainer,
            }}
            optionText={({
              firstName,
              lastName,
            }: {
              firstName: string;
              lastName: string;
            }) => `${firstName} ${lastName}`}
          />
        </ReferenceArrayInput>

        <Typography variant="h2">Address</Typography>
        <AsurionAddress readonlyFields={['address.countryCode']} />

        <Typography variant="h2">Bank Details</Typography>
        <TextInput
          label="Company Name (As appears in bank account)"
          placeholder="ABC Company"
          source="bankDetails.accountName"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <ReferenceInput
          label="Bank Name"
          source="bankDetails.bankNameId"
          reference="banks"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
          filter={{
            countryId,
          }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput
          label="Bank Branch"
          placeholder="Bank Branch"
          source="bankDetails.bankBranchId"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <TextInput
          label="Bank Account Number"
          placeholder="012-3-45678-9"
          source="bankDetails.accountNumber"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <SwiftCode label="Swift Code" />
        <ReferenceInput
          label="Account Type"
          source="bankDetails.accountTypeId"
          reference="account-types"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <Typography variant="h2">Contact Details</Typography>
        <TextInput
          label="Director Name"
          placeholder="e.g  John Smith"
          source="contactDetails.directorName"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <TextInput
          label="Primary Contact Person Name"
          placeholder="e.g John Smith"
          source="contactDetails.contactPerson1"
          fullWidth
          validate={required()}
          className="required"
          variant="outlined"
        />
        <TextInput
          label="Primary Contact Person Email Address"
          placeholder="e.g johnsmith@gmail.com"
          source="contactDetails.email1"
          type="email"
          fullWidth
          validate={[required(), email()]}
          className="required"
          variant="outlined"
        />
        <ContactNumber
          label="Primary Contact Number"
          placeholder="e.g 0912345678"
          source="contactDetails.contactNumber1"
          className="required"
        />
        <TextInput
          label="Secondary Contact Person Name (Optional)"
          placeholder="e.g John Smith"
          source="contactDetails.contactPerson2"
          fullWidth
          variant="outlined"
        />
        <TextInput
          label="Secondary Contact Person Email Address (Optional)"
          placeholder="e.g johnsmith@gmail.com"
          source="contactDetails.email2"
          type="email"
          fullWidth
          validate={[email()]}
          variant="outlined"
        />
        <ContactNumber
          label="Primary Contact Number (Optional)"
          placeholder="e.g 0912345678"
          source="contactDetails.contactNumber2"
          validate={undefined}
        />
      </AsurionForm>
    </Edit>
  );
};

export default SalesPartnersEdit;

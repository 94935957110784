import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { colors } from '../../../../themes/default';
import { useGetList } from 'react-admin';
import { useSalesPartnerId } from '../../../../contexts/SalesPartnerContext';
import { useCountryId } from '../../../../contexts/CountryContext';

export interface SalesPartnerSelectorMenuProps {
  selected?: string;
}

export interface SalesPartnerItem {
  salesPartnerId: string;
  partnerName: string;
}

const useStyles = makeStyles({
  menuRoot: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 24px)',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 10,
      marginTop: 10,
    },
    '&::-webkit-scrollbar-track': {
      background: colors.neutral.brighter,
      borderRadius: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.neutral.deep,
      borderRadius: 6,
    },
  },

  buttonRoot: {
    minWidth: 100,
    padding: '0 10px',
    background: colors.brand.black,
    color: colors.brand.white,
    '&:hover': {
      background: colors.brand.black,
    },
  },
});

export const SalesPartnerSelectorMenu = (
  props: SalesPartnerSelectorMenuProps
) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const { salesPartnerId, setSalesPartnerId } = useSalesPartnerId();
  const [, setSelectedPartner] = useState<SalesPartnerItem>({
    salesPartnerId: '',
    partnerName: '',
  });
  const [salesPartnerList, setSalesPartnerList] = useState<
    Array<SalesPartnerItem>
  >([]);
  const { countryId } = useCountryId();

  const { data } = useGetList(
    'sales-partners',
    { page: 1, perPage: 100 },
    undefined,
    {
      countryId,
      activeInd: 'active',
    }
  );

  useEffect(() => {
    if (data) {
      const salesPartners = Object.values(data).map(
        ({ salesPartnerId, partnerName }) => ({
          salesPartnerId,
          partnerName,
        })
      );
      setSalesPartnerList(salesPartners);
      setSelectedPartner(
        salesPartners.find(
          (salesPartner) => salesPartner.salesPartnerId === salesPartnerId
        ) as SalesPartnerItem
      );
    }
  }, [data, salesPartnerId]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = (salesPartnerId: any | null = null) => {
    if (salesPartnerId) {
      setSalesPartnerId(salesPartnerId);
    }
    setOpen(false);
  };

  if (salesPartnerList.length < 1) {
    return null;
  }

  return (
    <>
      <Button
        classes={{
          root: classes.buttonRoot,
        }}
        ref={anchorRef}
        disableRipple
        onClick={handleToggle}
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {
          salesPartnerList.find(
            (salesPartnerItem) =>
              salesPartnerItem.salesPartnerId === salesPartnerId
          )?.partnerName
        }
      </Button>
      <Popper
        style={{ zIndex: 9999 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper classes={{ root: classes.menuRoot }}>
              <ClickAwayListener onClickAway={() => handleClose(null)}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {salesPartnerList.map((salesPartner) => (
                    <MenuItem
                      onClick={(e) => handleClose(salesPartner.salesPartnerId)}
                    >
                      {salesPartner.partnerName}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SalesPartnerSelectorMenu;

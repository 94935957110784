import * as React from 'react';
import { FormControl, InputLabel, makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  controlRoot: {
    display: 'flex',
  },
  content: {
    marginTop: 21,
  },
});

interface FieldProps {
  label: string;
  children?: React.ReactNode;
}

export const Field = ({ label, children }: FieldProps) => {
  const classes = useStyle();
  return (
    <FormControl classes={{ root: classes.controlRoot }}>
      <InputLabel>{label}</InputLabel>
      <div className={classes.content}>{children}</div>
    </FormControl>
  );
};

export default Field;

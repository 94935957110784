import React from 'react';
import {
  Edit,
  EditProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { decorateWithRole } from '../../common/functions';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import EmailInput from '../common/Form/Input/EmailInput';
import { PartnerStores } from '../partner-stores/types';

export const PartnerAdminsEdit: React.FC<EditProps> = (props) => {
  const translate = useTranslate();
  const { identity, loaded } = useGetIdentity();
  const transform = (data: any) => {
    return {
      ...data,
      ...decorateWithRole<PartnerStores>(
        identity,
        'PARTNER_ADMIN',
        'salesPartnerId'
      ),
    };
  };

  return loaded ? (
    <Edit {...props} mutationMode="pessimistic" transform={transform}>
      <AsurionForm title={translate('resources.partner-admins.edit_title')}>
        <TextInput
          placeholder="e.g. John"
          source="firstName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          placeholder="e.g. Smith"
          source="lastName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput placeholder="e.g. John" source="nickname" fullWidth />
        <EmailInput source="userEmail" className="required" />
        <ContactNumber source="contactNumber" className="required" />

        {identity?.role !== 'PARTNER_ADMIN' && (
          <ReferenceInput
            label="resources.partner-admins.fields.partnerAccount"
            source="salesPartnerId"
            reference="sales-partners"
            fullWidth
            validate={required()}
            className="required"
            disabled
          >
            <SelectInput optionText="partnerName" />
          </ReferenceInput>
        )}
      </AsurionForm>
    </Edit>
  ) : null;
};

export default PartnerAdminsEdit;

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { DEFAULT_COUNTRY_LIST } from '../../../../common/constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { colors } from '../../../../themes/default';
import { useCountry } from '../../../../contexts/CountryContext';
// import { useSetLocale } from 'react-admin';

export interface CountrySelectorMenuProps {
  selected?: string;
}

const useStyles = makeStyles({
  buttonRoot: {
    minWidth: 100,
    padding: '0 10px',
    background: colors.brand.black,
    color: colors.brand.white,
    '&:hover': {
      background: colors.brand.black,
    },
  },
});

export const CountrySelectorMenu = (props: CountrySelectorMenuProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [countries] = useState(DEFAULT_COUNTRY_LIST);
  const classes = useStyles();
  const { country, setCountry } = useCountry();
  // const setLocale = useSetLocale();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // useEffect(() => {
  //   setLocale(country?.defaultLanguage || 'en');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [country]);

  const handleClose = (countryName: any | null = null) => {
    if (countryName) {
      setCountry(countryName);
    }
    setOpen(false);
  };
  return (
    <>
      <Button
        classes={{
          root: classes.buttonRoot,
        }}
        ref={anchorRef}
        disableRipple
        onClick={handleToggle}
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {country?.name}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => handleClose(null)}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {countries.map((country) => (
                    <MenuItem onClick={(e) => handleClose(country)}>
                      {country.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default CountrySelectorMenu;

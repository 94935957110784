import React from 'react';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  ListActionsProps,
  useTranslate,
} from 'react-admin';

interface AdditionalProps {
  verboseName?: string;
}

export const ListActions: React.FC<AdditionalProps & ListActionsProps> = ({
  className,
  exporter,
  filters,
  verboseName,
  ...rest
}) => {
  const { basePath, resource } = useListContext();
  const translate = useTranslate();
  const humanReadable = verboseName || resource.replace(/-/g, ' ').slice(0, -1);

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <CreateButton
        basePath={basePath}
        label={translate('asurion.common.action.add_resource', {
          resource: translate(`resources.${resource}.name`, {
            smart_count: 1,
            _: humanReadable,
          }),
        })}
      />
    </TopToolbar>
  );
};

export default ListActions;

import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useGetIdentity, useGetList } from 'react-admin';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { ROLES } from '../../common/types';
import { colors } from '../../themes/default';
import { useSetTheme } from '../../contexts/ThemeContext';
import { useEffect } from 'react';
import { useCountry, useCountryId } from '../../contexts/CountryContext';
import { useSalesPartnerId } from '../../contexts/SalesPartnerContext';
import { DEFAULT_COUNTRY_LIST } from './Form/Collection/CountrySelector';
import { useSetUpSalesPartner } from '../../common/hooks';

const useStyles = makeStyles({
  '@global': {
    html: {
      background: (props: any) =>
        props.grey ? colors.extra.page.background : 'inherit',
    },
    body: {
      background: (props: any) =>
        props.grey ? colors.extra.page.background : 'inherit',
    },
  },
});

export const Dashboard = () => {
  const { identity } = useGetIdentity();
  const role: ROLES = identity?.role || 'ANONYMOUS';
  const { country = {}, setCountry } = useCountry();
  const { countryId } = useCountryId();
  const { salesPartnerId = false } = useSalesPartnerId();
  const { neutralBackground } = useSetTheme();
  const [useGreys, setUseGreys] = React.useState<boolean>(neutralBackground);
  const { setUpSalesPartner } = useSetUpSalesPartner();

  useStyles({ grey: useGreys });

  if (
    ['PARTNER_ADMIN', 'BUSINESS_PARTNER_ADMIN', 'SALES_MGR'].includes(role) &&
    identity?.extra.countryId
  ) {
    const defaultCountry = DEFAULT_COUNTRY_LIST.find(
      (country) => country.id === identity?.extra.countryId
    );
    setCountry(defaultCountry);
  }

  let { loaded: salesPartnerLoaded, data } = useGetList(
    'sales-partners',
    { page: 1, perPage: 100 },
    undefined,
    {
      countryId,
      activeInd: 'active',
    }
  );

  useEffect(() => {
    setUseGreys(neutralBackground);
  }, [neutralBackground]);

  useEffect(() => {
    if (role === 'SALES_MGR' && salesPartnerLoaded && data) {
      const salesPartners = Object.values(data).map(
        ({ salesPartnerId, partnerName }) => ({
          salesPartnerId,
          partnerName,
        })
      );
      if (salesPartners.length > 0) {
        console.log(salesPartners[0].salesPartnerId);
        setUpSalesPartner(salesPartners[0].salesPartnerId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesPartnerLoaded, data, role, country, countryId]);

  return (
    <>
      {role === 'SYSTEM_ADMIN' && country && (
        <Redirect to={ROUTES.COUNTRY_MANAGERS.path} />
      )}

      {role === 'SYSTEM_ADMIN' &&
        Object.keys(country).length === 0 &&
        country.constructor === Object && (
          <Redirect
            to={{
              pathname: ROUTES.COUNTRY_SELECTOR.path,
              state: {
                hideMenu: true,
              },
            }}
          />
        )}

      {role === 'COUNTRY_MGR' && salesPartnerId && (
        <Redirect to={ROUTES.PARTNER_SALES.path} />
      )}

      {role === 'COUNTRY_MGR' && salesPartnerId === '' && (
        <Redirect
          to={{
            pathname: ROUTES.SALES_PARTNER_SELECTOR.path,
          }}
        />
      )}

      {role === 'SALES_MGR' && salesPartnerId && (
        <Redirect to={ROUTES.MANAGEMENT.path} />
      )}

      {/* {role === 'SALES_MGR' && salesPartnerId === '' && (
        <Redirect
          to={{
            pathname: ROUTES.SALES_PARTNER_SELECTOR.path,
          }}
        />
      )} */}

      {['PARTNER_ADMIN'].includes(role) && (
        <Redirect to={ROUTES.MANAGEMENT.path} />
      )}

      {['BUSINESS_PARTNER_ADMIN'].includes(role) && (
        <Redirect to={ROUTES.PARTNER_ADMINS.path} />
      )}

      {role === 'FINANCE_MGR' && <Redirect to={ROUTES.PARTNER_SALES.path} />}
    </>
  );
};

export default Dashboard;

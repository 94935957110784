import React from 'react';
import { Theme, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Switch from '@material-ui/core/Switch';
import { colors, SIZES } from '../../../../themes/default';
import { stopPropagation } from '../../../../common/functions';
export interface AsurionSwitchProps {
  label: string;
  checked: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChange: () => void;
}

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: 80,
    height: 24,
    padding: 0,
  },
  switchBase: {
    padding: 0,
    height: 24,
    left: -2,
    margin: '0 4px',
    '&$checked': {
      right: -16,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 12,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    width: 80,
  },
  checked: {},
  focusVisible: {},

  containerRoot: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  containerLabel: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: SIZES.font.small.size,
    lineHeight: SIZES.font.small.lineHeight,
    position: 'absolute',
    color: colors.brand.white,

    '$startLabel > &': {
      paddingRight: 0,
      paddingLeft: 18,
    },

    // '$label > &': {
    //   paddingRight: 18,
    //   paddingLeft: 0,
    // },
  },

  startLabel: {},
}));

const AsurionSwitch = (props: AsurionSwitchProps) => {
  const { label, ...restProps } = props;
  const classes = useStyle();
  return (
    <FormControlLabel
      classes={{
        root: classes.containerRoot,
        label: classes.containerLabel,
        labelPlacementStart: classes.startLabel,
      }}
      onClick={stopPropagation}
      control={
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...restProps}
        />
      }
      label={label}
      labelPlacement={restProps.checked ? 'end' : 'start'}
    />
  );
};

export default AsurionSwitch;

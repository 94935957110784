import React from 'react';
import {
  Create,
  CreateProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { decorateWithRole } from '../../common/functions';
import { useBusinessPartnerId } from '../../common/hooks';
import { useCountryId } from '../../contexts/CountryContext';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import EmailInput from '../common/Form/Input/EmailInput';
import { PartnerStores } from '../partner-stores/types';

export const PartnerAdminsCreate: React.FC<CreateProps> = (props) => {
  const translate = useTranslate();
  const { identity, loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  const { businessPartnerId = false } = useBusinessPartnerId();

  const transform = (data: any) => {
    return {
      ...data,
      ...decorateWithRole<PartnerStores>(
        identity,
        'PARTNER_ADMIN',
        'salesPartnerId'
      ),
      countryId,
      created_at: Date.now(),
      status: 'pending',
    };
  };

  return loaded ? (
    <Create {...props} transform={transform}>
      <AsurionForm
        title={translate('resources.partner-admins.new_title')}
        redirect="list"
      >
        <TextInput
          placeholder="e.g. John"
          source="firstName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          placeholder="e.g. Smith"
          source="lastName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput placeholder="e.g. John" source="nickname" fullWidth />
        <EmailInput source="userEmail" className="required" />
        <ContactNumber source="contactNumber" className="required" />

        {identity?.role !== 'PARTNER_ADMIN' && (
          <ReferenceInput
            label="resources.partner-admins.fields.partnerAccount"
            source="salesPartnerId"
            reference="sales-partners"
            fullWidth
            validate={required()}
            className="required"
            filter={{
              activeIndStatus: 'active',
              countryId,
              ...(businessPartnerId ? { businessPartnerId } : undefined),
            }}
          >
            <SelectInput optionText="partnerName" />
          </ReferenceInput>
        )}
      </AsurionForm>
    </Create>
  ) : null;
};

export default PartnerAdminsCreate;

import {
  makeStyles,
  CardContent,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { useRecordContext } from 'react-admin';

import { uuidUncanonical } from '../../../common/util';

export interface ActionableInfoFieldProps {
  source: string;
  label?: string;
  copy?: boolean;
  // navFilters?: object;
  // redirect?: string;
}

const useStyles = makeStyles({
  infoRoot: {
    marginBottom: '10px',
    '&:last-of-type': {
      marginBottom: '0',
    },
  },
  infoTitle: {
    fontSize: 14,
  },
  actionButton: {
    margin: '0 0 2px 2px',
    padding: '5px',
    '&:first-of-type': {
      margin: '0 0 2px 15px',
      padding: '5px',
    },
  },
});

// TODO: Create Context for reusable components
export const ActionableInfoField = (props: ActionableInfoFieldProps) => {
  const classes = useStyles();
  const { source, label, copy = false /* navFilters, redirect = '' */ } = props;

  const record = useRecordContext(props);

  let data = record[source];
  let transformedData = uuidUncanonical(data);

  return data ? (
    <CardContent className={classes.infoRoot}>
      <Typography
        className={classes.infoTitle}
        color="textSecondary"
        gutterBottom
      >
        {label}
      </Typography>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <Typography variant="body2">{transformedData}</Typography>
        {/* {redirect && (
          <RedirectActionButton
            data={record}
            navFilters={navFilters}
            to={redirect}
          />
        )} */}
        {copy && <CopyActionButton data={transformedData} />}
      </Box>
    </CardContent>
  ) : null;
};

const CopyActionButton = (props: any) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <IconButton
      className={classes.actionButton}
      onClick={() => {
        navigator.clipboard.writeText(data);
      }}
      aria-label="Copy to clipboard"
    >
      <FileCopyOutlined style={{ fontSize: 16 }} />
    </IconButton>
  );
};

// DEPRECATED. Unless needs to be used again, fix doRedirect functionality for when needing filtering
// const RedirectActionButton = (props: any) => {
//   const classes = useStyles();
//   const history = useHistory();
//   const { data, navFilters, to = '' } = props;

//   // This causes issues now due to complex filtering
//   const doRedirect = () => {
//     if (to) {
//       let filter: any = {};
//       for (const sourceKey in navFilters) {
//         let filterKey = navFilters[sourceKey];

//         let value = data[sourceKey];
//         filter[filterKey || sourceKey] = Array.isArray(value) ? value.join(',') : value;
//       }

//       const redirect = navFilters && data ? `${to}?filter=${JSON.stringify(filter)}` : to;
//       console.log(redirect);
//       history.push(redirect);
//     }
//   };

//   return (
//     <IconButton
//       className={classes.actionButton}
//       onClick={doRedirect}
//       aria-label="Go to reference"
//     >
//       <LaunchOutlined style={{ fontSize: 16 }} />
//     </IconButton>
//   );
// };

export default ActionableInfoField;

import SalesPartnersList from './SalesPartnersList';
import SalesPartnersCreate from './SalesPartnersCreate';
import SalesPartnersEdit from './SalesPartnersEdit';
import SalesPartnersShow from './SalesPartnersShow';
import NoBankDetailsCreate from './variants/NoBankDetailsCreate';
import NoBankDetailsEdit from './variants/NoBankDetailsEdit';
import NoBankDetailsList from './variants/NoBankDetailsList';
import NoBankDetailsShow from './variants/NoBankDetailsShow';

const resourceMapping = {
  list: SalesPartnersList,
  create: SalesPartnersCreate,
  edit: SalesPartnersEdit,
  show: SalesPartnersShow,
  variants: {
    noBankDetails: {
      create: NoBankDetailsCreate,
      edit: NoBankDetailsEdit,
      list: NoBankDetailsList,
      show: NoBankDetailsShow,
    },
  },
};

export default resourceMapping;

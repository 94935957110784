import { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { THEME_CONFIG_KEY } from '../common/constants';

export interface ThemeConfigContextProps {
  neutralBackground: boolean;
  setNeutralBackground?: any;
  hasMenu?: boolean;
  setHasMenu?: any;
  hasNavigation?: boolean;
  setHasNavigation?: any;
  children?: React.ReactNode;
}

const defaultThemeConfig = JSON.parse(
  localStorage.getItem(THEME_CONFIG_KEY) || '{}'
);

const ThemeConfigContext = createContext<ThemeConfigContextProps>({
  neutralBackground: false,
  hasMenu: defaultThemeConfig.hasMenu || true,
  hasNavigation: defaultThemeConfig.hasMenu || true,
});

export const ThemeConfigProvider = ({
  neutralBackground: _neutralBackground = false,
  hasMenu: _hasMenu = true,
  hasNavigation: _hasNavigation = true,
  children,
}: ThemeConfigContextProps) => {
  const [neutralBackground, setNeutralBackground] =
    useState(_neutralBackground);
  const [hasMenu, setHasMenu] = useState(_hasMenu);
  const [hasNavigation, setHasNavigation] = useState(_hasNavigation);

  useEffect(() => {
    localStorage.setItem(
      THEME_CONFIG_KEY,
      JSON.stringify({
        hasMenu,
        hasNavigation,
      })
    );
  }, [hasMenu, hasNavigation]);

  return (
    <ThemeConfigContext.Provider
      value={{
        neutralBackground,
        setNeutralBackground,
        hasMenu,
        setHasMenu,
        hasNavigation,
        setHasNavigation,
      }}
    >
      {children}
    </ThemeConfigContext.Provider>
  );
};

export const useSetTheme = () => useContext(ThemeConfigContext);

import * as React from 'react';
import { Grid } from '@material-ui/core';
import { TextInput, required, useTranslate } from 'react-admin';
import CountrySelector from './CountrySelector';

export interface AsurionAddressProps {
  readonlyFields?: Array<string>;
}

export const AsurionAddress = ({
  readonlyFields,
  ...restProps
}: AsurionAddressProps) => {
  const translate = useTranslate();
  return (
    <>
      <CountrySelector
        label={translate(
          'asurion.common.form.collection.address.country.label'
        )}
        source="address.countryCode"
        disabled={readonlyFields?.includes('address.countryCode')}
      />
      <TextInput
        label={translate(
          'asurion.common.form.collection.address.businessName.label'
        )}
        placeholder={translate(
          'asurion.common.form.collection.address.businessName.placeholder'
        )}
        source="address.businessName"
        fullWidth
        variant="outlined"
        disabled={readonlyFields?.includes('address.businessName')}
      />
      <TextInput
        label={translate(
          'asurion.common.form.collection.address.building.label'
        )}
        placeholder={translate(
          'asurion.common.form.collection.address.building.placeholder'
        )}
        source="address.building"
        fullWidth
        variant="outlined"
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            label={translate(
              'asurion.common.form.collection.address.roomNo.label'
            )}
            source="address.roomNo"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label={translate(
              'asurion.common.form.collection.address.houseNo.label'
            )}
            source="address.houseNo"
            fullWidth
            variant="outlined"
            validate={required()}
            className="required"
          />
        </Grid>
      </Grid>
      <TextInput
        label={translate(
          'asurion.common.form.collection.address.village.label'
        )}
        placeholder={translate(
          'asurion.common.form.collection.address.village.placeholder'
        )}
        source="address.village"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label={translate('asurion.common.form.collection.address.alley.label')}
        placeholder={translate(
          'asurion.common.form.collection.address.alley.placeholder'
        )}
        source="address.alley"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label={translate('asurion.common.form.collection.address.street.label')}
        source="address.street"
        fullWidth
        variant="outlined"
        validate={required()}
        className="required"
      />
      <TextInput
        label={translate(
          'asurion.common.form.collection.address.subDistrict.label'
        )}
        placeholder={translate(
          'asurion.common.form.collection.address.subDistrict.placeholder'
        )}
        source="address.subDistrict"
        fullWidth
        variant="outlined"
        validate={required()}
        className="required"
      />
      <TextInput
        label={translate(
          'asurion.common.form.collection.address.district.label'
        )}
        placeholder={translate(
          'asurion.common.form.collection.address.district.placeholder'
        )}
        source="address.district"
        fullWidth
        variant="outlined"
        validate={required()}
        className="required"
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            label={translate(
              'asurion.common.form.collection.address.province.label'
            )}
            source="address.province"
            fullWidth
            variant="outlined"
            validate={required()}
            className="required"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label={translate(
              'asurion.common.form.collection.address.postalCode.label'
            )}
            source="address.postalCode"
            fullWidth
            variant="outlined"
            validate={required()}
            className="required"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AsurionAddress;

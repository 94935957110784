import { dataProvider } from '../../providers/dataProvider';

export const calculateIncentives = async (
  financeUserId: any,
  enrollmentIds: any,
  filter: any,
  onSuccess: (data?: any) => void,
  onFail: (err: any) => void
) => {
  const body = {
    financeUserId,
    enrollmentIds,
    filter,
  };

  console.log('Payload for Calculation of Incentives: ', body);

  try {
    const { data } = await dataProvider.create('incentives', { data: body });

    if (data) {
      onSuccess(data);
    } else {
      console.error('Some unknown error happened.');
    }
  } catch (err: any) {
    onFail(err);
  }
};

export const cancelIncentivesCalculation = async (
  financeUserId: any,
  payoutBatchId: any,
  onSuccess: (data?: any) => void,
  onFail: (err: any) => void
) => {
  const body = {
    financeUserId: financeUserId,
    payoutBatchId: payoutBatchId,
  };

  console.log('Payload for Cancelling Incentive Calculation: ', body);

  try {
    const { data } = await dataProvider.create('incentives-calculation', {
      data: body,
    });

    if (data) {
      onSuccess();
    } else {
      console.error('Some unknown error happened.');
    }
  } catch (err: any) {
    onFail(err);
  }
};

export const payoutIncentives = async (
  financeUserId: any,
  payoutBatchId: any,
  onSuccess: (data?: any) => void,
  onFail: (err: any) => void
) => {
  const body = {
    financeUserId: financeUserId,
    payoutBatchId: payoutBatchId,
  };

  console.log('Payload for Payout of Incentives: ', body);

  try {
    const { data } = await dataProvider.create('incentives-payout', { data: body });

    if (data) {
      onSuccess(data);
    } else {
      console.error('Some unknown error happened.');
    }
  } catch (err: any) {
    onFail(err);
  }
};

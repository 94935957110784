import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import {
  SelectInputProps,
  required,
  SelectInput,
  useDataProvider,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

interface StoreChoice {
  id: string;
  name: string;
}

interface FilteredStoresInputProps {
  salesPartnerId: string;
  onEmptyStore?: (form: ReturnType<typeof useForm>) => void;
  [key: string]: any;
}

export const FilteredStoresInput = ({
  source,
  options = {
    placeholder: 'Select Country',
  },
  validate = [required()],
  onEmptyStore,
  emptyText = 'Select Store',
  ...restProps
}: SelectInputProps) => {
  const form = useForm();
  const { values } = useFormState<FilteredStoresInputProps>();
  const dataProvider = useDataProvider();
  const [choices, setChoices] = useState<Array<StoreChoice>>([]);

  const fetchStores = useCallback(async () => {
    if (values.salesPartnerId) {
      const { data = [] } = await dataProvider.getManyReference(
        'partner-stores',
        {
          target: 'salesPartnerId',
          id: values.salesPartnerId,
          pagination: {
            page: 1,
            perPage: 100,
          },
          filter: {
            activeIndStatus: 'active',
          },
          sort: { field: 'partnerName', order: 'ASC' },
        }
      );
      if (data.length < 1 && onEmptyStore) {
        onEmptyStore(form);
      }
      setChoices(
        data.map(({ id, partnerStoreName: name }) => {
          return {
            id: id as string,
            name,
          };
        })
      );
    }
  }, [values.salesPartnerId, dataProvider, form, onEmptyStore]);

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);
  return (
    <SelectInput
      {...{
        source,
        options,
        choices,
        validate,
        emptyText,
      }}
      choices={choices}
      variant="outlined"
      {...restProps}
      disabled={choices.length < 1}
    />
  );
};

import * as React from 'react';
import { Button } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles({
  actions: {
    justifyContent: 'flex-start',
    padding: '0 0 40px 0',
  },
  dialogTitleRoot: {
    marginBottom: 20,
    fontWeight: 'bold',
  },
  dialogContentRoot: {
    padding: 0,
    '& p': {
      marginBottom: '1em',
    },
  },
  dialogPaper: {
    maxWidth: 360,
  },
});

export interface ConfirmSubmitProps {
  open: boolean;
  onClose: (value?: boolean) => void;
}

export const ConfirmSubmit = (props: ConfirmSubmitProps) => {
  const translate = useTranslate();
  const { open, onClose } = props;

  const classes = useStyles();

  const handleCancel = (e: any) => {
    e.stopPropagation();
    onClose();
  };

  const handleOk = (e: any) => {
    e.stopPropagation();
    onClose(true);
  };

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle
        disableTypography
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        {translate('ra.action.confirm')}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <Typography>
          {translate('asurion.common.dialog.generic_confirm.message')}
        </Typography>

        <Typography>
          {translate('asurion.common.dialog.generic_confirm.confirm')}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="default" onClick={handleCancel}>
          {translate('ra.action.cancel')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {translate('ra.action.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { resolveBrowserLocale, TranslationMessages } from 'react-admin';
import { englishMessages as englishMessagesOverrides } from '../i18n/en';
import { thaiMessages } from '../i18n/th';
import { chineseMessages } from '../i18n/zh';

import en from 'rsuite/lib/IntlProvider/locales/en_US';
import zh from 'rsuite/lib/IntlProvider/locales/zh_TW';
import th from '../i18n/rsuite/th';
import { enUS as enDate, th as thDate, zhTW as zhDate } from 'date-fns/locale';

const messages: {
  [key: string]: TranslationMessages | Promise<TranslationMessages>;
} = {
  en: { ...englishMessages, ...englishMessagesOverrides },
  th: { ...englishMessages, ...thaiMessages },
  zh: { ...englishMessages, ...chineseMessages },
};

export const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === 'en' || !(locale in messages)) {
      return messages['en'];
    }
    return messages[locale];
  },
  resolveBrowserLocale(),
  {
    allowMissing: true,
    onMissingKey: (key: any, _: any, __: any) => key,
  }
);

export default i18nProvider;

const locales = {
  en,
  th,
  zh,
};

const dateLocales = {
  en: enDate,
  th: thDate,
  zh: zhDate,
};

export const rsuiteLocaleResolver = (locale: keyof typeof locales) => {
  return {
    locale: locales[locale in locales ? locale : 'en'],
    date: dateLocales[locale in dateLocales ? locale : 'en'],
  };
};

import * as React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import {
  EmailField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { QuickPreviewDialog } from '../common/Dialog/QuickPreviewDialog';
import { StoreManager } from '../store-managers/types';
import AvatarPlaceholderIcon from '../common/Branding/Icons/AvatarPlaceholderIcon';
interface Props {
  id: StoreManager['salesStoreUserId'];
  open: boolean;
  editPermission?: string | Array<string>;
  onClose?: () => any;
}

const resourceName = 'store-managers';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
  previewAvatar: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
});

const StoreManagersQuickPreviewDialog = ({
  id,
  open,
  editPermission = [],
  onClose = () => {},
}: Props) => {
  const classes = useStyles();

  const title = (data: StoreManager) => {
    return (
      <div className={classes.previewAvatar}>
        {data?.profileImg ? (
          <Avatar
            alt={`${data.firstName} ${data.lastName}`}
            src={data.profileImg}
            variant="circular"
          />
        ) : (
          <AvatarPlaceholderIcon />
        )}
        {data.firstName} {data.lastName}
      </div>
    );
  };

  return (
    <QuickPreviewDialog
      id={id as string}
      open={open}
      editPermission={editPermission}
      onClose={onClose}
      resourceName={resourceName}
      title={title}
    >
      <TextField source="title" fullWidth />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="nickname" emptyText="N/A" />
      <EmailField source="userEmail" />
      <TextField source="contactNumber" />
      <ReferenceField
        label="Partner Account"
        source="salesPartnerId"
        reference="sales-partners"
        link={false}
      >
        <TextField source="partnerName" />
      </ReferenceField>
      <ReferenceArrayField
        reference="partner-stores"
        source="partnerStores"
        filter={{
          activeInd: 'active',
        }}
        className={classes.arrayReferenceList}
      >
        <SingleFieldList linkType={false} className="test">
          <TextField source="partnerStoreName" />
        </SingleFieldList>
      </ReferenceArrayField>
    </QuickPreviewDialog>
  );
};

export default StoreManagersQuickPreviewDialog;

import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SetPassword from '../common/Form/SetPassword';
import { useSetTheme } from '../../contexts/ThemeContext';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
  containerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
    width: 430,
    marginTop: 120,
  },
});

const { useMemo, useCallback, useEffect, useState } = React;

export const Activation = () => {
  const { search } = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(search.slice(1)),
    [search]
  );
  const token = searchParams.get('token');
  const [error, setError] = useState<string>('');
  const [activated, setActivated] = useState<boolean>(false);
  const [mustSetupPassword, setMustSetupPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { setHasMenu } = useSetTheme();
  const translate = useTranslate();

  const classes = useStyles();

  const getPayloadData = useCallback(
    (key: string) => {
      return searchParams.get(key) || '';
    },
    [searchParams]
  );

  const activate = async (token: string, setPassword = false) => {
    setLoading(true);
    setError('');
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ADMIN_API_URL}/activation`,
        {
          params: {
            token: token,
            t: new Date().getTime(),
          },
        }
      );
      if (result.status === 200) {
        setMustSetupPassword(setPassword);
        setActivated(!setPassword);
        setError('');
      }
    } catch (e) {
      setError(e as string);
    } finally {
      setLoading(false);
    }
  };

  const setUp = useCallback(() => {
    setHasMenu(false);
  }, [setHasMenu]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  useEffect(() => {
    const process = async () => {
      try {
        activate(token || '', !!getPayloadData('withPasswordSetup'));
      } catch (e) {
        setError(e as string);
      }
    };
    process();
  }, [token, getPayloadData]);

  const handleOnSuccess = () => {
    setMustSetupPassword(false);
    setActivated(true);
  };

  return (
    <Container maxWidth="sm" classes={{ root: classes.containerRoot }}>
      {activated && (
        <Alert severity="success">
          {translate('asurion.profile.activation.success.0')}{' '}
          <Link to="/sign-in">
            {translate('asurion.profile.activation.success.1')}
          </Link>{' '}
          {translate('asurion.profile.activation.success.2')}
        </Alert>
      )}
      {!loading && error && (
        <Alert severity="error">
          {translate('asurion.profile.activation.error', {
            link: '<Link to="/sign-in">here</Link>',
          })}
        </Alert>
      )}

      {mustSetupPassword && (
        <SetPassword
          id={getPayloadData('id')}
          userEmail={getPayloadData('userEmail')}
          onSuccess={handleOnSuccess}
        />
      )}
    </Container>
  );
};

export default Activation;

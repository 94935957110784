import * as React from 'react';
import { useState } from 'react';
import {
  Button,
  IconButton,
  InputAdornment,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Form } from 'react-final-form';
import { required, TextInput, useTranslate } from 'react-admin';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { mustBeSimilarTo } from '../Validator/mustBeSimilarTo';
import axios from 'axios';
import { noop } from '../../../common/functions';
import { createHash } from 'crypto';

export interface SetPasswordProps {
  id: string;
  userEmail: string;
  onSuccess?: Function;
}

const useStyles = makeStyles({
  header: {
    fontSize: 32,
  },
  alertBox: {
    marginBottom: 30,
    width: '-webkit-fill-available',
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 430,
  },
  inputAdornmentRoot: {
    background: 'transparent',
    margin: 0,
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  buttonRoot: {
    width: 120,
    marginTop: 30,
  },
});

export const SetPassword = ({ id, userEmail, onSuccess }: SetPasswordProps) => {
  const translate = useTranslate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const classes = useStyles();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = async (values: any) => {
    setLoading(true);
    setError('');
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_SECURITY_API_URL}/security/password`,
        {
          u: createHash('md5').update(userEmail).digest('hex'),
          sp: createHash('sha256').update(values.password).digest('hex'),
          id: id.replaceAll('-', '').toUpperCase(),
        }
      );
      if (result.status === 200) {
        return (onSuccess || noop)();
      }
      throw Error('There was an error with your request.');
    } catch (e) {
      console.log('Error', e);
      setError('There was an error creating your password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography
        className={classes.header}
        variant="h3"
        component="h1"
        align="center"
      >
        {translate('asurion.profile.set_password.title')}
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            className={classes.formRoot}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Alert severity="info" className={classes.alertBox}>
              {translate('asurion.profile.set_password.set_password_helper')}
            </Alert>
            {error && (
              <Alert severity="error" className={classes.alertBox}>
                {error}
              </Alert>
            )}
            <TextInput
              label="asurion.profile.set_password.form.fields.password"
              source="password"
              placeholder={translate(
                'asurion.profile.set_password.form.placeholder.password'
              )}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              validate={[required()]}
              fullWidth
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      classes={{
                        root: classes.inputAdornmentRoot,
                      }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      disableRipple
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextInput
              label="asurion.profile.set_password.form.fields.confirm_password"
              source="confirmPassword"
              placeholder={translate(
                'asurion.profile.set_password.form.placeholder.confirm_password'
              )}
              type={showConfirmPassword ? 'text' : 'password'}
              variant="outlined"
              validate={[
                required(),
                mustBeSimilarTo('password', { targetField: 'Password' }),
              ]}
              fullWidth
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      classes={{
                        root: classes.inputAdornmentRoot,
                      }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      disableRipple
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              classes={{ root: classes.buttonRoot }}
              type="submit"
              color="primary"
              disabled={loading}
            >
              {translate(
                'asurion.profile.set_password.form.fields.set_password'
              )}
            </Button>
          </form>
        )}
      />
    </>
  );
};

export default SetPassword;

import React from 'react';
import {
  Create,
  CreateProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { decorateWithRole } from '../../common/functions';
import { useCountryId } from '../../contexts/CountryContext';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import EmailInput from '../common/Form/Input/EmailInput';
import { PartnerStores } from '../partner-stores/types';

export const BusinessPartnerAdminsCreate: React.FC<CreateProps> = (props) => {
  const translate = useTranslate();
  const { identity, loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  const transform = (data: any) => {
    return {
      ...data,
      ...decorateWithRole<PartnerStores>(
        identity,
        'BUSINESS_PARTNER_ADMIN',
        'salesPartnerId'
      ),
      countryId,
      created_at: Date.now(),
      status: 'Pending',
    };
  };

  return loaded ? (
    <Create {...props} transform={transform}>
      <AsurionForm
        title={translate('resources.business-partners.new_title')}
        redirect="list"
      >
        <TextInput
          placeholder="e.g. John"
          source="firstName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          placeholder="e.g. Smith"
          source="lastName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput placeholder="e.g. John" source="nickname" fullWidth />
        <EmailInput source="userEmail" className="required" />
        <ContactNumber source="contactNumber" className="required" />

        {identity?.role !== 'BUSINESS_PARTNER_ADMIN' && (
          <ReferenceInput
            label="Business Partner"
            source="businessPartnerId"
            reference="business-partners"
            fullWidth
            validate={required()}
            className="required"
            filter={{
              activeIndStatus: 'active',
              countryId,
            }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
      </AsurionForm>
    </Create>
  ) : null;
};

export default BusinessPartnerAdminsCreate;

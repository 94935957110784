import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSetTheme } from '../../../contexts/ThemeContext';
import AsurionTechCareSalesLogo from '../../common/Branding/AsurionLogo/AsurionTechCareSalesLogo';
import { TroubleshootingCategory } from './TroubleshootingCategory';
import { Category } from './types';
import _ from 'lodash';
import axios from 'axios';

const CONTENTFUL_URL =
  'https://cdn.contentful.com/spaces/rcqun2fh2hqb/environments/master/entries';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'none',
    height: 46,
    justifyContent: 'center',
  },
  container: {
    padding: '0 8px',
  },
  logo: {
    marginBottom: 15,
  },
  link: {
    display: 'flex',
  },
  description: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 15,
    color: theme.palette.common.black,
  },
}));

export const AppTroubleshooting = () => {
  const { setHasNavigation } = useSetTheme();
  const classes = useStyles();

  const fetchContent = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(CONTENTFUL_URL, {
        params: {
          access_token: process.env.REACT_APP_CONTENTFUL_KEY,
          content_type: 'thFaq',
          'metadata.tags.sys.id[in]': 'faqTypeTroubleshooting',
        },
      });
      if (response.status === 200) {
        const categories = _.groupBy(response.data.items, 'fields.category');

        setEntries(
          Object.keys(categories).map((label) => ({
            category: label,
            items: categories[label].map(
              ({ fields: { question, answer } }) => ({
                question,
                answer,
              })
            ),
          }))
        );
      }
    } catch (e) {
      console.log('Error contacting CONTENTFUL.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setHasNavigation(false);
    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [entries, setEntries] = useState<Array<Category>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <Container className={classes.container}>
      <Typography variant="h6" className={classes.logo}>
        <Link to="/">
          <AsurionTechCareSalesLogo />
        </Link>
      </Typography>
      <div>
        <Typography variant="body1" className={classes.description}>
          Welcome to TechCare Sales&trade; Troubleshooting
        </Typography>
        {loading && <Skeleton animation="wave" height={118} />}
        {!loading &&
          entries.map((category) => (
            <TroubleshootingCategory category={category} />
          ))}
      </div>
    </Container>
  );
};

export default AppTroubleshooting;

import * as React from 'react';
import { usePermissions } from 'react-admin';
import { intersect, intersects } from '../../common/functions';

export interface CanProps {
  [key: string]:
    | any
    | {
        children: React.ReactNode;
        do: string | Array<string>;
        all?: boolean;
        except?: string;
        label?: string;
      };
}

export const Can = ({
  do: perform,
  all = false,
  except,
  children,
  ...restProps
}: CanProps) => {
  const { permissions = [] } = usePermissions();
  let allowed = false;
  if (typeof perform === 'string') {
    allowed = permissions.includes(perform);
  } else {
    if (all) {
      allowed = intersect(permissions, perform).length === perform.length;
    } else {
      allowed = intersects(permissions, perform);
    }
  }

  if (except) {
    if (permissions.includes(except)) {
      allowed = !allowed;
    }
  }
  return (
    <>{allowed && <>{React.cloneElement(children, { ...restProps })}</>}</>
  );
};

export default Can;

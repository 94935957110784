import * as React from 'react';
import { Dialog, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { SimpleShowLayout, useGetOne } from 'react-admin';
import { EditIconButton } from '../Form/Buttons/EditIconButton';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '../../../themes/default';
import Can from '../../authentication/Can';

interface Props {
  id: string;
  open: boolean;
  resourceName: string;
  editPermission?: string | Array<string>;
  title?: (data: any) => string | React.ReactNode;
  onClose?: () => any;
}

const useStyles = makeStyles({
  title: {
    '& > *': {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  editIconButton: {
    padding: 12,
    transform: 'scale(0.72)',
  },
  closeIconButton: {
    padding: 12,
    position: 'absolute',
    right: 0,
    top: 10,
  },
  quickPreviewDialog: {
    '@global': {
      '.ra-field .MuiFormLabel-root': {
        color: colors.neutral.deeper,
      },
      '.ra-field [class^="RaLabeled-value"] > *': {
        fontSize: 16,
        lineHeight: '150%',
      },
    },
  },
});

export const QuickPreviewDialog: React.FC<Props> = ({
  id,
  open,
  resourceName,
  editPermission = [],
  title = (data: any) => 'Dialog Title',
  onClose = () => {},
  children,
}) => {
  const { data } = useGetOne<any>(resourceName, id);
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>
        {title(data)}
        <Can do={editPermission}>
          <EditIconButton
            size="small"
            className={classes.editIconButton}
            basePath={`/${resourceName}`}
            record={data}
          />
        </Can>
        <IconButton
          aria-label="delete"
          classes={{
            root: classes.closeIconButton,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <SimpleShowLayout
        record={data}
        basePath="/"
        resource={resourceName}
        className={classes.quickPreviewDialog}
      >
        {children}
      </SimpleShowLayout>
    </Dialog>
  );
};

export default QuickPreviewDialog;

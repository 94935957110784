import * as React from 'react';
import { Avatar, Link, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  TextField,
  TextInput,
  useGetIdentity,
  usePermissions,
  useTranslate,
} from 'react-admin';
import ListActions from '../overrides/ListActions';
import StoreManagersQuickPreviewDialog from './StoreManagersQuickPreviewDialog';
import { ActiveIndStatusField } from '../common/Field/ActiveIndStatusField';
import { EditIconButton } from '../common/Form/Buttons/EditIconButton';
import AsurionSearchForm from '../common/Form/AsurionSearchForm';
import ActiveStatusSelectInput from '../common/Form/Collection/ActiveIndStatusSelectInput';
import Can from '../authentication/Can';
import AvatarPlaceholderIcon from '../common/Branding/Icons/AvatarPlaceholderIcon';
import { useCountryId } from '../../contexts/CountryContext';
import { useSalesPartnerId } from '../../contexts/SalesPartnerContext';

const useStyles = makeStyles({
  arrayReferenceList: {
    display: 'flex',
    gap: 4,
    '& > *:not(:last-child)::after': {
      content: '", "',
    },
  },
  avatarIcon: {
    width: 32,
    height: 32,
  },
  avatarCell: {
    padding: '7px 0 7px 20px',
    justifyContent: 'center',
  },
  avatarCellElement: {
    display: 'flex',
  },
});

const StoreManagerFilter = () => {
  const translate = useTranslate();
  const { identity, loaded } = useGetIdentity();
  return loaded ? (
    <AsurionSearchForm>
      <TextInput
        label={translate('asurion.common.form.full_name.label')}
        placeholder={translate(
          'asurion.common.form.full_name.search_placeholder'
        )}
        source="name"
        fullWidth
        variant="outlined"
      />
      <TextInput
        label={translate('asurion.common.form.email.label')}
        placeholder={translate('asurion.common.form.email.search_placeholder')}
        source="userEmail"
        fullWidth
        variant="outlined"
      />
      {!['PARTNER_ADMIN', 'SALES_MGR'].includes(identity?.role) && (
        <TextInput
          label={translate('asurion.common.form.store.label')}
          placeholder={translate(
            'asurion.common.form.store.search_placeholder'
          )}
          source="storeName"
          fullWidth
          variant="outlined"
        />
      )}
      <ActiveStatusSelectInput />
    </AsurionSearchForm>
  ) : null;
};

export const StoreManagersList = (props: ListProps) => {
  const { permissions = [] } = usePermissions();
  const { identity, loaded } = useGetIdentity();
  const { countryId } = useCountryId();
  const { salesPartnerId } = useSalesPartnerId();
  const [openQuickPreviewDialog, setOpenQuickPreviewDialog] =
    useState<boolean>(false);
  const [selectedStoreManagerId, setSelectedStoreManagerId] = useState<
    string | null
  >(null);
  const classes = useStyles();

  let filter: any = { countryId };
  if (!permissions.includes('is_super_admin')) {
    if (identity?.role === 'SALES_MGR') {
      filter = {
        ...filter,
        salesManagerId: identity?.id,
      };
    }

    if (identity?.extra.salesPartnerId) {
      filter = {
        ...filter,
        salesPartnerId: identity?.extra.salesPartnerId,
      };
    }
  }

  if (salesPartnerId) {
    filter = {
      ...filter,
      salesPartnerId,
    };
  }

  const handleQuickPreviewDialogClose = () => {
    setOpenQuickPreviewDialog(false);
  };

  return loaded ? (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        actions={
          <Can do="create_user_store_manager">
            <ListActions className={classes.avatarIcon} />
          </Can>
        }
        filter={filter}
        filters={<StoreManagerFilter />}
        pagination={false}
        hasCreate={permissions.includes('create_user_store_manager')}
      >
        <Datagrid>
          {!['PARTNER_ADMIN', 'SALES_MGR'].includes(identity?.role) && (
            <FunctionField
              className={classes.avatarCellElement}
              cellClassName={classes.avatarCell}
              render={(record: any) => {
                if (!record.profileImg) return <AvatarPlaceholderIcon />;
                return (
                  <Avatar
                    alt={`${record.firstName} ${record.lastName}`}
                    src={record.profileImg}
                    variant="circular"
                  />
                );
              }}
            />
          )}
          <FunctionField
            label="resources.partner-admins.fields.name"
            sortBy="firstName"
            render={(record: any) => {
              return (
                <Link
                  onClick={() => {
                    setSelectedStoreManagerId(record.id);
                    setOpenQuickPreviewDialog(true);
                  }}
                >
                  {record.firstName} {record.lastName}
                </Link>
              );
            }}
          />
          <EmailField source="userEmail" sortable={false} />
          {!['PARTNER_ADMIN', 'SALES_MGR'].includes(identity?.role) && (
            <ReferenceField
              label="asurion.common.navigation.menus.partner_account"
              source="salesPartnerId"
              reference="sales-partners"
              link={false}
              sortable={false}
            >
              <TextField source="partnerName" />
            </ReferenceField>
          )}
          {/* Hack to use a locale with yyyy-mm-dd since Datefield only uses locales to format days */}
          <DateField source="createdDate" locales="sv-se" />
          {permissions.includes('update_user_store_manager') && (
            <EditIconButton label="asurion.common.table.fields.action" />
          )}
          {permissions.includes('update_user_store_manager') && (
            <ActiveIndStatusField
              label="asurion.common.table.fields.status"
              textAlign="center"
            />
          )}
        </Datagrid>
      </List>
      {selectedStoreManagerId && (
        <StoreManagersQuickPreviewDialog
          id={selectedStoreManagerId}
          open={openQuickPreviewDialog}
          onClose={handleQuickPreviewDialogClose}
          editPermission="update_user_store_manager"
          {...props}
        />
      )}
    </>
  ) : null;
};

export default StoreManagersList;

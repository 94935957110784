import * as React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';

export interface PageContainerProps {
  title?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  header: {
    fontSize: 32,
  },
  containerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
    marginTop: 120,
    marginBottom: 40,
  },
});

export const PageContainer = ({
  title,
  children,
  ...restProps
}: PageContainerProps) => {
  const classes = useStyles();
  return (
    <Container classes={{ root: classes.containerRoot }} {...restProps}>
      {title && (
        <Typography
          className={classes.header}
          variant="h3"
          component="h1"
          align="center"
        >
          {title}
        </Typography>
      )}
      {children}
    </Container>
  );
};

export default PageContainer;
